import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import createStyles from "@mui/styles/createStyles";
import { DataLoaderSystemComponentContent } from "./DataLoaderSystem";
import {
  DataLoaderListOption,
  getAvailableListOptions,
  getDataSystemLists,
  updateDataLoaderSystemLists,
} from "../../api/datasystems";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { FormControl, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { isEmpty } from "lodash";

/**
 * List Config Props
 */
export interface DataLoaderListConfigProps {
  dataLoaderSystems: DataLoaderSystemComponentContent[];
  dataLoaderSystemIds?: number;
  existing?: boolean;
  onDataLoaderListSelectionChange: (idx: number, list_ids: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 5px 5px 5px",
      marginBottom: "20px",
    },
  })
);

/**
 * A list selector
 * Establish a connection to the CRM and pull all available lists
 */
export const ListSelection = (
  props: Readonly<{
    onAdd?(index: number): void;
    onRemove?(index: number): void;
    onChange(list_id: string, index: number): void;
    availableLists: DataLoaderListOption[];
    selectedList: string;
    index: number;
    existing: boolean;
  }>
) => {
  const {
    availableLists,
    onRemove,
    onAdd,
    onChange,
    selectedList,
    index,
    existing,
  } = props;
  return (
    <FormControl
      disabled={existing}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <Select
        variant={"standard"}
        data-testid={"select"}
        value={selectedList || ""}
        onChange={(event) => {
          onChange(event.target.value, index);
        }}
        style={{ flex: 1, maxWidth: "80%" }}
      >
        {availableLists
          .toSorted((a, b) => a.list_name.localeCompare(b.list_name))
          .map((availableList) => {
            return (
              <MenuItem
                key={availableList.list_id}
                value={availableList.list_id}
              >
                {availableList.list_name + " [" + availableList.list_id + "]"}
              </MenuItem>
            );
          })}
      </Select>
      {!existing ? (
        <div style={{ display: "flex" }}>
          <IconButton
            data-testid={"remove_condition_btn"}
            aria-label="remove_condition"
            color="primary"
            disabled={!onRemove && !existing}
            onClick={(_event) => onRemove(index)}
            size="small"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          <IconButton
            data-testid={"add_condition_btn"}
            aria-label="add_condition"
            color="primary"
            size="small"
            disabled={!onAdd && !existing}
            onClick={(_event) => onAdd(index)}
          >
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </div>
      ) : null}
    </FormControl>
  );
};

/**
 * Renders the list selector and handles all save/ update operations
 *
 */
export function DataLoaderListConfig(
  props: Readonly<DataLoaderListConfigProps>
) {
  const {
    dataLoaderSystems,
    dataLoaderSystemIds,
    existing,
    onDataLoaderListSelectionChange,
  } = props;
  const classes = useStyles();

  const [selectedLists, setSelectedLists] = useState<string[]>([""]);
  const [submittedList, setSubmittedList] = useState<string[]>([]);

  const [dataLoaderSystem, setDataLoaderSystem] =
    useState<DataLoaderSystemComponentContent>({
      integrations: undefined,
      list_ids: [],
      name: "",
      options: undefined,
      type: "",
    });
  const [dataLoaderListOptions, setDataLoaderListOptions] = useState<
    DataLoaderListOption[]
  >([]);

  useEffect(() => {
    if (dataLoaderSystems && dataLoaderSystems.length > 0) {
      setDataLoaderSystem(dataLoaderSystems[0]);
    }
  }, [dataLoaderSystems]);

  useEffect(() => {
    if (dataLoaderSystem.type && !isEmpty(dataLoaderSystem.options)) {
      getAvailableListOptions(dataLoaderSystem.type, dataLoaderSystem.options)
        .then((response) => setDataLoaderListOptions(response))
        .catch((err) => console.log(err));
    }
  }, [dataLoaderSystem.type, dataLoaderSystem.options]);

  useEffect(() => {
    let completedList = selectedLists.filter((list_id) => list_id.length > 0);
    if (
      completedList.length > 0 &&
      (completedList.length !== submittedList.length ||
        !completedList.every((v, i) => v === submittedList[i]))
    ) {
      onDataLoaderListSelectionChange(0, completedList);
      setSubmittedList(completedList);
    }
  }, [submittedList, selectedLists]);

  useEffect(() => {
    if (dataLoaderSystem.type) {
      if (existing && dataLoaderSystemIds) {
        getDataSystemLists(dataLoaderSystemIds)
          .then((response) => {
            if (response.list_ids.length) {
              setSelectedLists(response.list_ids);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, [dataLoaderSystem.type, dataLoaderSystemIds, existing]);

  const onAddList = (index: number) => {
    setSelectedLists([
      ...selectedLists.slice(0, index + 1),
      "",
      ...selectedLists.slice(index + 1),
    ]);
  };

  const onRemoveList = (index: number) => {
    if (selectedLists.length === 1) {
      setSelectedLists([""]);
    } else {
      setSelectedLists([
        ...selectedLists.slice(0, index),
        ...selectedLists.slice(index + 1),
      ]);
    }
  };

  const onChangeList = (list_id: string, index: number) => {
    setSelectedLists([
      ...selectedLists.slice(0, index),
      list_id,
      ...selectedLists.slice(index + 1),
    ]);
  };
  return (
    <>
      <Container className={classes.container}>
        {dataLoaderListOptions.length > 0 && selectedLists ? (
          selectedLists.map((selectedList: string, index) => {
            return (
              <ListSelection
                key={selectedList}
                onAdd={onAddList}
                onRemove={onRemoveList}
                onChange={onChangeList}
                availableLists={dataLoaderListOptions}
                selectedList={selectedList}
                index={index}
                existing={false}
              />
            );
          })
        ) : (
          <Typography variant={"body2"}>
            No List Configuration available for this CRM.
          </Typography>
        )}
      </Container>
      {existing && dataLoaderListOptions.length > 0 && (
        <Button
          onClick={() => {
            updateDataLoaderSystemLists({
              data_loader_system_id: dataLoaderSystemIds,
              list_ids: selectedLists,
            })
              .then(() => {})
              .catch((err) => {});
          }}
        >
          Apply
        </Button>
      )}
    </>
  );
}
