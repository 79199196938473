import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { Formik } from "formik";
import { getAuthToken, getMicrosoftLoginUrl } from "../api/api";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

interface LoginProps {
  setLoggedIn(boolean): void;
  setUserProfile(string): void;
}

/**
 * A Log in page
 * Supports standard username/password + microsoft SSO
 *
 * @param props
 * @constructor
 */
export function Login(props: Readonly<LoginProps>) {
  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoggedIn, setUserProfile } = props;

  return (
    <>
      <Helmet>
        <title>Login | CIRROM</title>
      </Helmet>
      <Box
        style={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "25px",
          minHeight: (window.outerHeight * 0.6).toString() + "px",
        }}
      >
        <Container maxWidth="sm">
          <Collapse in={isBannerOpen}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsBannerOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Unable to log in: {errorDetails}
            </Alert>
          </Collapse>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .max(255)
                .required("User name is required."),
              password: Yup.string().max(255).required("Password is required."),
            })}
            onSubmit={(values, formikBag) => {
              getAuthToken(values.username, values.password)
                .then(
                  (response) => {
                    setLoggedIn(response);
                    setUserProfile(response.User_Profile);
                    // @ts-ignore
                    if (location.state?.hasRedirect ?? false) {
                      navigate(-1);
                    } else {
                      navigate("/app/dashboard");
                    }
                  },
                  (error) => {
                    formikBag.setSubmitting(false);
                    setIsBannerOpen(true);
                    setErrorDetails(error.response.data.detail);
                  }
                )
                .catch((err) => console.log(err));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box style={{ marginBottom: "1em" }}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => {
                      getMicrosoftLoginUrl()
                        .then((response) => {
                          window.location.replace(response);
                        })
                        .catch((err) =>
                          console.error("Microsoft SSO App not configured")
                        );
                    }}
                  >
                    <img
                      alt="msftLogo"
                      src="/msft_icon.ico"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "1em",
                      }}
                    />
                    Login with Microsoft
                  </Button>
                </Box>
                <Box style={{ margin: "1em 0em" }}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Or login with your CIRROM account
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="User Name"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  type="password"
                  variant="outlined"
                />
                <Box style={{ padding: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}
