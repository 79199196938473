import { useEffect, useRef } from "react";

/**
 * toReadableText Utility Function
 *
 * Converts a given snake_case or camelCase text to a human-readable format.
 *
 * @param {string} text - The input text to be converted.
 * @returns {string} - The human-readable version of the input text.
 */
export const toReadableText = (text: string) => {
  // Check if the input text is not empty
  if (text && text.length > 0) {
    // Convert the input text to lowercase
    const allLowercase = text.toLowerCase();

    // Split the text into its components using '_' as the separator
    let textComponents = allLowercase.split("_");

    // Check if there are multiple components (snake_case)
    if (textComponents && textComponents.length > 1) {
      // Capitalize the first letter of each component and join them with a space
      for (let itemIndex = 0; itemIndex < textComponents.length; itemIndex++) {
        textComponents[itemIndex] =
          textComponents[itemIndex][0].toUpperCase() +
          textComponents[itemIndex].substring(1);
      }
      return textComponents.join(" ");
    }

    // If there's only one component (camelCase), capitalize its first letter and return
    return allLowercase[0].toUpperCase() + allLowercase.substring(1);
  }

  // If the input text is empty or undefined, return an empty string
  return text;
};

/**
 * useTraceUpdate Hook
 *
 * A custom React hook to trace and log updates to a component's props. This hook helps in debugging
 * by logging the changed properties and their previous and current values whenever the component re-renders.
 *
 * @param {Object} props - The component's props that need to be traced.
 * @returns {void} - This hook does not return anything. It rather prints the changes in the console.
 *
 */
export const useTraceUpdate = (props) => {
  // useRef to hold the previous props
  const prev = useRef(props);

  // useEffect to log changed props when the component re-renders
  useEffect(() => {
    // Compare the previous props with the current props and identify changed properties
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});

    // If there are any changed props, log them to the console
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }

    // Update the previous props with the current props for the next comparison
    prev.current = props;
  });
};

/**
 * agDateColumnFilterComparator Function
 *
 * A comparator function used in Ag-Grid date column filtering to compare a date value in the grid cell
 * with a filter-local date. The function returns a numerical value (1, -1, or 0) based on the comparison
 * result between the two dates.
 *
 * @param {Date} filterLocalDate - The local date used for filtering in the Ag-Grid filter.
 * @param {string} cellValue - The value of the date in the Ag-Grid cell (in string format).
 * @returns {number} - A numerical value indicating the comparison result:
 *                    - 1 if the cellValue date is greater than filterLocalDate.
 *                    - -1 if the cellValue date is less than filterLocalDate.
 *                    - 0 if the cellValue date is equal to filterLocalDate.
 */
export const agDateColumnFilterComparator = (
  filterLocalDate: Date,
  cellValue: string
) => {
  // Convert the cellValue (date string) to a Date object
  let cellValueDate = new Date(cellValue);
  // Set the time of the cellValueDate to 00:00:00.000 (midnight) to ignore the time part for comparison
  cellValueDate.setHours(0, 0, 0, 0);

  // Perform the comparison and return a numerical value based on the result
  if (cellValueDate > filterLocalDate) {
    return 1;
  } else if (cellValueDate < filterLocalDate) {
    return -1;
  } else {
    return 0;
  }
};

/**
 * Number formatter to format numbers US style e.g 123,456
 */
export const NUMBER_FORMATTER = new Intl.NumberFormat("en-US");

/**
 * getCrmScoreStyles Function
 * Generate a background color CSS prop depending on the score value to indicate traffic light like progression
 * @param {number} score - The score to generate a background color for
 */
export const getCrmScoreStyles = (score) => {
  if (score < 35) {
    return { background: "#e50144" };
  }
  if (score < 45) {
    return { background: "#e65100" };
  }
  if (score < 55) {
    return { background: "#f57c00" };
  }
  if (score < 65) {
    return { background: "#ffa000" };
  }
  if (score < 76) {
    return { background: "#fbc02d" };
  }
  if (score < 86) {
    return { background: "#b5c332" };
  }
  if (score < 92) {
    return { background: "#8bc34a" };
  }
  if (score >= 92) {
    return { background: "#34be5d" };
  }
  return { background: "#e50144" };
};

export const encodeValue = (item) => {
  return encodeURIComponent(item || "");
};
