import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Not Found | CIRROM</title>
      </Helmet>
      <Box
        style={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container
          maxWidth="sm"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            color="textPrimary"
            variant="h1"
            style={{
              display: "inline-block",
              borderRight: "1px solid rgba(0, 0, 0,.3)",
              margin: "0px 20px",
              padding: "10px 23px 10px 0",
              verticalAlign: "middle",
            }}
          >
            404
          </Typography>
          <Typography
            color="textPrimary"
            variant="h4"
            style={{ display: "inline-block", verticalAlign: "middle" }}
          >
            Page Not Found.
          </Typography>
        </Container>
        <Container
          maxWidth="sm"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <img
            alt="NotFoundImg"
            src="/undraw_empty_xct9.svg"
            style={{ height: "256px" }}
          />
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => navigate("/app/dashboard")}
          >
            Back Home
          </Button>
        </Container>
      </Box>
    </>
  );
}
