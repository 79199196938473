import React, {
  useEffect,
  useState,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { CirromStyles } from "./components/CirromStyles";
import { getLoggedInUser } from "./api/api";
import { Routes } from "./components/Routes";
import { ScrollToTop } from "./components/utils/ScrollToTop";
import { SnackbarProvider } from "notistack";
import { theme } from "./theme/theme";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { useRoutes } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  getIntegratedSystemsForUser,
  IntegratedSystem,
} from "./api/integratedsystems";
import { DataProject, getDataProjectsForUser } from "./api/dataprojects";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../src/App.css";
import { CirromLoadingLogo } from "./components/utils/CirromLoadingLogo";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

/**
 * @typedef {Object} ViewPortContext
 * @property {string} value - The current value.
 * @property {Function} minimizeView - Function to minimize the view.
 * @property {Function} maximizeView - Function to maximize the view.
 */
export const ViewPortContext = createContext({
  value: "",
  minimizeView: () => {},
  maximizeView: () => {},
});

/**
 * Context for managing the width state of a sidebar.
 * @typedef {Object} SideBarWidthContext
 * @property {string} value - The current width value. Possible values: "full", "minimized", "maximized".
 * @property {function} toggleValue - Toggles the width value between "minimized" and "maximized".
 * @property {function} minimize - Sets the width value to "minimized".
 * @property {function} maximize - Sets the width value to "maximized".
 */
export const SideBarWidthContext = createContext({
  value: "full",
  toggleValue: () => {},
  minimize: () => {},
  maximize: () => {},
});

/**
 * Creates a context for the user profile.
 * @typedef {object} UserProfileContext
 * @property {React.Context} userProfile - The user profile context.
 * @property {boolean} canSupplyFeedback - Indicates whether the feedback can be supplied or not.
 */
export const UserProfileContext = createContext({
  userProfile: "USER",
  canSupplyFeedback: false,
});

/**
 * App entry point
 *
 */

function App() {
  const [currentUser, setCurrentUser] = useState<string>(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<string>("");
  const [userSystems, setUserSystems] = useState<IntegratedSystem[]>([]);
  const [dataProjects, setDataProjects] = useState<DataProject[]>([]);
  const [originalAccessPath, setOriginalAccessPath] =
    useState<string>(undefined);
  const [canSupplyFeedback, setCanSupplyFeedback] = useState<boolean>(false);

  const [selectedSystemId, setSelectedSystemId] = useState<number>(undefined);
  const onLogout = () => {
    setIsLoggedIn(false);
    setCurrentUser("");
  };

  const fetchDataProjects = () => {
    getDataProjectsForUser()
      .then((projects: DataProject[]) => {
        setDataProjects(projects);
      })
      .catch((err) => console.log(err));
  };

  const routing = useRoutes(
    Routes(
      isLoggedIn,
      setIsLoggedIn,
      onLogout,
      originalAccessPath,
      userProfile,
      userSystems,
      dataProjects,
      fetchDataProjects,
      selectedSystemId,
      setSelectedSystemId,
      setUserProfile,
      currentUser
    )
  );

  useEffect(() => {
    if (isLoggedIn) {
      getIntegratedSystemsForUser()
        .then((systems) => {
          setUserSystems(systems);
        })
        .catch((err) => console.log(err));
      fetchDataProjects();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (window.location.pathname.endsWith("/disabled")) {
      setCurrentUser("");
    } else if (!currentUser) {
      const originalPath = window.location.search
        ? (window.location.pathname + window.location.search).substring(1)
        : window.location.pathname;

      setOriginalAccessPath(originalPath);
      if (window.location.pathname.search("active-directory") === -1) {
        getLoggedInUser().then(
          (response) => {
            setCurrentUser(response.User);
            setIsLoggedIn(true);
            setUserProfile(response.User_Profile);
            setCanSupplyFeedback(response.Can_Report_Feedback);
          },
          (_error) => {
            setCurrentUser("");
          }
        );
      } else {
        setCurrentUser(null);
        setIsLoggedIn(false);
      }
    }
  }, [currentUser, isLoggedIn]);

  const [viewPortWidth, setViewPortWidth] = useState("");
  const [sideBarWidth, setSideBarWidth] = useState("full");

  const toggleSidebarMemo = useCallback(() => {
    setSideBarWidth(sideBarWidth === "full" ? "small" : "full");
  }, [sideBarWidth]);

  const minimizeSidebarMemo = useCallback(() => {
    setSideBarWidth("small");
  }, []);

  const maximizeSidebarMemo = useCallback(() => {
    setSideBarWidth("full");
  }, []);

  const userProfileParams = useMemo(() => {
    return {
      userProfile,
      canSupplyFeedback,
    };
  }, [userProfile, canSupplyFeedback]);

  const sideBarWidthParams = useMemo(() => {
    return {
      value: sideBarWidth,
      toggleValue: toggleSidebarMemo,
      minimize: minimizeSidebarMemo,
      maximize: maximizeSidebarMemo,
    };
  }, [sideBarWidth]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <UserProfileContext.Provider value={userProfileParams}>
            <SideBarWidthContext.Provider value={sideBarWidthParams}>
              <ViewPortContext.Provider
                value={{
                  value: viewPortWidth,
                  minimizeView: () => {
                    setViewPortWidth("60%");
                  },
                  maximizeView: () => {
                    setViewPortWidth("");
                  },
                }}
              >
                <CirromStyles />
                {currentUser === undefined ? (
                  <Box
                    style={{
                      backgroundColor: "background.default",
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Container maxWidth="sm" style={{ padding: 0 }}>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          margin: "10px",
                        }}
                      >
                        <CirromLoadingLogo iconHeight={"100px"} />
                      </div>
                    </Container>
                  </Box>
                ) : (
                  <ScrollToTop>{routing}</ScrollToTop>
                )}
              </ViewPortContext.Provider>
            </SideBarWidthContext.Provider>
          </UserProfileContext.Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
