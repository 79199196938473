import { MainNavbar } from "../components/MainNavbar";
import { Outlet } from "react-router-dom";
import React from "react";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      paddingTop: 40,
    },
  })
);

/**
 * The layout for when no user is logged in
 *
 */

export function BaseLayout() {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div
        style={{
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
        }}
        data-testid={"main-container"}
      >
        <div style={{ display: "flex", flex: "1 1 auto", overflow: "hidden" }}>
          <div style={{ flex: "1 1 auto", overflow: "auto" }}>
            <MainNavbar />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
