import axios from "axios";

export const CIRROM_URL = `${process.env.REACT_APP_CIRROM_URL}`;

/**
 * An Axios API instance of all the Cirrom endpoints
 *
 */
export const API = axios.create({
  baseURL: `${CIRROM_URL}`,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrftoken",
});

if (API) {
  API.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data &&
        error.response.data.detail === "Not authenticated"
      ) {
        window.location.reload();
      }

      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data &&
        error.response.data === "cirrom_disabled"
      ) {
        window.location.href = "/disabled";
      }

      return Promise.reject(error);
    }
  );
}

export interface UserInfo {
  User: string;
  User_Profile: string;
  System_Access: number[];
  Can_Report_Feedback: boolean;
}

/**
 * Authenticate  the user
 * @param username
 * @param password
 */
export const getAuthToken = async (username: string, password: string) => {
  // authenticate a user
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  const response = await API.post("/token", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
};

/**
 * Retrieve stats about the logged in user
 */
export const getLoggedInUser = async (): Promise<UserInfo> => {
  const response = await API.get("/user-info");
  return response.data;
};

/**
 * Log out the current user
 */
export const logout = async (): Promise<any> => {
  const response = await API.post("/logout");
  return response.data;
};

/**
 * Retrieve the Microsoft SSO url
 */
export const getMicrosoftLoginUrl = async (): Promise<string> => {
  const response = await API.get("/get-microsoft-login-url");
  return response.data;
};

/**
 * Pass the Microsoft SSO params back to Cirrom to authenticate the user
 * @param params
 */
export const authenticateViaAAD = async (params: any): Promise<string> => {
  const response = await API.get("authenticate-via-aad", { params });
  return response.data;
};
