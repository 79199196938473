import React, { useEffect, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";

import { toReadableText } from "../utils/utils";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import { LocaleDateTimeRenderer } from "../suggested-changes/CellRenderers";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { deleteFile } from "../../api/useractions";

/**
 * UploadedFilesTable Component
 *
 * A component to display a table of uploaded files with various columns like File Name,
 * Date Uploaded, File Owner, and Processing Status. The table supports checkbox selection
 * if enabled and provides options to share and delete selected files.
 *
 * @param {Array} props.uploadedFiles - An array of uploaded files data to display in the table.
 * @param {Function} props.setShareFileModal - Optional. A function to open the ShareFileModal when sharing files.
 * @param {boolean} props.showGridToolbar - Optional. A boolean value to display the grid toolbar with action buttons.
 * @param {boolean} props.enableCheckBoxSelection - Optional. A boolean value to enable checkbox selection in the table.
 * @returns {JSX.Element} - The rendered UploadedFilesTable component.
 */
export function UploadedFilesTable(
  props: Readonly<{
    uploadedFiles: any;
    setShareFileModal?(boolean): void;
    showGridToolbar?: boolean;
    enableCheckBoxSelection?: boolean;
  }>
) {
  // Destructure props
  const {
    uploadedFiles,
    setShareFileModal,
    showGridToolbar,
    enableCheckBoxSelection,
  } = props;

  // Columns definition for the ag-Grid table
  const columns = [
    {
      field: "file_name",
      headerName: "File Name",
      resizable: true,
      lockPosition: true,
      checkboxSelection: enableCheckBoxSelection || false,
    },
    {
      field: "uploaded_datetime",
      headerName: "Date Uploaded",
      resizable: true,
      cellRenderer: "localedatetimerenderer",
    },
    {
      field: "author_name",
      headerName: "File Owner",
      resizable: true,
    },
    { field: "status", headerName: "Processing Status", resizable: true },
    { field: "file_id", headerName: "File ID", hide: true },
    { field: "is_your_file", headerName: "Is My File", hide: true },
  ];

  // Get the 'id' parameter from the URL using React Router's useParams hook
  const { id } = useParams();

  // State to manage the rows data in the ag-Grid table
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  // React Router's navigate function to handle navigation
  const navigate = useNavigate();

  // Update the rows data when the 'uploadedFiles' prop changes
  useEffect(() => {
    let gridRows = [];
    uploadedFiles.forEach((uploadedFile) => {
      gridRows.push({
        file_name: uploadedFile.file.file_name,
        uploaded_datetime: uploadedFile.file.uploaded_datetime,
        author_name: uploadedFile.file.author_name,
        status: toReadableText(uploadedFile.batch.status),
        file_id: uploadedFile.file.id,
        is_your_file: uploadedFile.file.is_your_file,
      });
    });
    setRows(gridRows);
  }, [uploadedFiles]);

  return (
    // Render the ag-Grid table
    <div
      className="ag-theme-material"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "250px",
        marginBottom: "10px",
      }}
    >
      {/* Display the grid toolbar with action buttons if 'showGridToolbar' prop is true */}
      {showGridToolbar && (
        <div style={{ display: "inline-flex" }}>
          {/* Share File button */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<ShareIcon />}
            size="small"
            style={{ margin: "2px", padding: "0em 0.5em" }}
            onClick={() => {
              setShareFileModal(true);
            }}
            disabled={
              selectedRows.length === 0 ||
              selectedRows[0].data.is_your_file !== true
            }
          >
            Share File
          </Button>
          {/* Delete File button */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
            size="small"
            style={{ margin: "2px", padding: "0em 0.5em" }}
            onClick={() => {
              deleteFile(selectedRows[0].data.file_id)
                .then(() => {})
                .catch((err) => console.log(err));
              setSelectedRows([]);
            }}
            disabled={
              selectedRows.length === 0 ||
              selectedRows[0].data.is_your_file !== true
            }
          >
            Delete File
          </Button>
        </div>
      )}
      {/* Render the ag-Grid table */}
      <AgGridReact
        columnDefs={columns}
        rowData={rows}
        components={{
          localedatetimerenderer: LocaleDateTimeRenderer,
        }}
        onRowDataUpdated={(params) => {
          params.api.sizeColumnsToFit();
        }}
        onRowClicked={(event) => navigate(`/app/my-data/${event.data.file_id}`)}
        rowStyle={{ background: "transparent" }}
        onSelectionChanged={(event) => {
          let currentSelection = event.api.getSelectedNodes();
          setSelectedRows(currentSelection);
          if (
            currentSelection.length > 0 &&
            currentSelection[0].data.file_id !== id
          ) {
            navigate(`/app/my-data/${currentSelection[0].data.file_id}`);
          }
        }}
      />
    </div>
  );
}
