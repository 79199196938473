import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  getAllExistingUsers,
  updateUserTypes,
  updateUserFileUploadAccess,
  UserDetail,
  updateUserSystemAccess,
  updateUserDisabledLogin,
} from "../api/useractions";
import { PageTitle } from "../components/utils/PageTitle";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import { IntegratedSystem } from "../api/integratedsystems";

export const Users = (props: { userSystems: IntegratedSystem[] }) => {
  const [users, setUsers] = useState<UserDetail[]>([]);
  const [userType, setUserType] = useState<any>("");
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const columns = [
    {
      field: "user_name",
      headerName: "User Name",
      resizable: true,
      lockPosition: true,
      checkboxSelection: true,
    },
    {
      field: "full_name",
      headerName: "Full name",
      resizable: true,
    },
    {
      field: "email",
      headerName: "Email",
      resizable: true,
    },
    {
      field: "user_profile",
      headerName: "User Type",
      resizable: true,
    },
    {
      field: "can_upload_files",
      headerName: "Allowed To Upload Files",
      resizable: true,
    },
    {
      field: "last_log_in",
      headerName: "Last Log In",
      resizable: true,
    },
    {
      field: "disabled",
      headerName: "Disabled Log In",
      resizable: true,
    },
    { field: "id", headerName: "User ID", hide: true },
  ];
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getAllExistingUsers()
      .then((response) => {
        setUsers(response);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <PageTitle pageTitle="Users | CIRROM" pageHeader="Manage Users">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Card style={{ height: "100%", width: "100%", marginTop: "10px" }}>
            <CardHeader
              title="Users"
              titleTypographyProps={{ color: "textSecondary" }}
            />
            <Divider />
            <CardContent style={{ display: "flex" }}>
              <div
                className="ag-theme-material"
                style={{
                  width: "100%",
                  minHeight: "500px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ display: "inline-flex", alignItems: "baseline" }}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{
                      margin: "2px",
                      padding: "0em 0.5em",
                      minWidth: 120,
                    }}
                  >
                    <InputLabel />
                    <Select
                      variant={"standard"}
                      label="User Profile"
                      onChange={(event) => setUserType(event.target.value)}
                      value={userType}
                      style={{ height: "20px" }}
                    >
                      <MenuItem value={""} />
                      <MenuItem value="SUPER_ADMIN">Super Admin</MenuItem>
                      <MenuItem value="TECH_ADMIN">Tech Admin</MenuItem>
                      <MenuItem value="USER">User</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!(userType && selectedUsers.length > 0)}
                    style={{
                      margin: "2px",
                      padding: "0em 0.5em",
                      height: "20px",
                    }}
                    onClick={() => {
                      updateUserTypes(selectedUsers, userType)
                        .then(() => {
                          setSelectedUsers([]);
                          enqueueSnackbar("User Type Updated!", {
                            variant: "success",
                          });
                        })
                        .catch((err) => {
                          if (err?.response?.status === 406) {
                            enqueueSnackbar(
                              "Cannot upgrade new users. Please contact Cirrom Support.",
                              {
                                variant: "error",
                              }
                            );
                          }
                        });
                    }}
                  >
                    Update User Type
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={selectedUsers.length === 0}
                    style={{
                      margin: "2px",
                      padding: "0em 0.5em",
                      height: "20px",
                    }}
                    onClick={() => {
                      updateUserFileUploadAccess(selectedUsers, true)
                        .then(() => {
                          setSelectedUsers([]);
                          enqueueSnackbar("File Upload Enabled!", {
                            variant: "success",
                          });
                        })
                        .catch((err) => console.log(err));
                    }}
                  >
                    Enable File Upload
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={selectedUsers.length === 0}
                    style={{
                      margin: "2px",
                      padding: "0em 0.5em",
                      height: "20px",
                    }}
                    onClick={() => {
                      updateUserFileUploadAccess(selectedUsers, false)
                        .then(() => {
                          setSelectedUsers([]);
                          enqueueSnackbar("File Upload Disabled!", {
                            variant: "success",
                          });
                        })
                        .catch((err) => console.log(err));
                    }}
                  >
                    Disable File Upload
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={selectedUsers.length === 0}
                    style={{
                      margin: "2px",
                      padding: "0em 0.5em",
                      height: "20px",
                    }}
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    Enable System Access
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={selectedUsers.length === 0}
                  style={{
                    margin: "2px",
                    padding: "0em 0.5em",
                    height: "20px",
                  }}
                  onClick={() => {
                    updateUserDisabledLogin(selectedUsers, false)
                      .then(() => {
                        setSelectedUsers([]);
                        enqueueSnackbar("User Login Enabled!", {
                          variant: "success",
                        });
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Enable Login
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={selectedUsers.length === 0}
                  style={{
                    margin: "2px",
                    padding: "0em 0.5em",
                    height: "20px",
                  }}
                  onClick={() => {
                    updateUserDisabledLogin(selectedUsers, true)
                      .then(() => {
                        setSelectedUsers([]);
                        enqueueSnackbar("User Login Disabled!", {
                          variant: "success",
                        });
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  Disable Login
                </Button>
                <AgGridReact
                  columnDefs={columns}
                  rowData={users}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  onSelectionChanged={(event) => {
                    let userIds = [];
                    event.api.getSelectedNodes().forEach((row) => {
                      userIds.push(row.data.id);
                    });
                    setSelectedUsers(userIds);
                  }}
                />
              </div>
              <AddSystemAccessModal
                userSystems={props.userSystems}
                selectedUserIds={selectedUsers}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
              />
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </PageTitle>
  );
};

export const AddSystemAccessModal = (
  props: Readonly<{
    userSystems: IntegratedSystem[];
    selectedUserIds: number[];
    modalOpen: boolean;
    setModalOpen(value): void;
  }>
) => {
  const [systemId, setSystemId] = useState<number>(undefined);
  return (
    <Modal
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="sm" style={{ padding: 0 }}>
        <Card style={{ padding: "16px" }}>
          <CardHeader
            title={"Enable System Access"}
            titleTypographyProps={{ variant: "h3" }}
            action={
              <IconButton onClick={() => props.setModalOpen(false)}>
                <CloseOutlinedIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <TextField
              select
              required
              fullWidth
              variant="outlined"
              size="small"
              margin="dense"
              label={"Selected System"}
              value={systemId || ""}
              onChange={(event) => {
                setSystemId(parseInt(event.target.value));
              }}
            >
              {props.userSystems.map((userSystem) => (
                <MenuItem
                  key={userSystem.id}
                  value={userSystem.id}
                  style={{ paddingBottom: "0px" }}
                >
                  {userSystem.name}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </CardContent>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ margin: "2px", padding: "0em 0.5em" }}
              disabled={!systemId}
              onClick={() => {
                updateUserSystemAccess(props.selectedUserIds, systemId)
                  .then((response) => props.setModalOpen(false))
                  .catch((err) => console.log(err));
              }}
            >
              Enable
            </Button>
          </div>
        </Card>
      </Container>
    </Modal>
  );
};
