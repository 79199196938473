import { API } from "./api";

export interface IntegratedSystem {
  name: string;
  type: string[];
  id: number;
  system_type: string;
}

export interface PendingChangesSummary {
  first_name: number;
  last_name: number;
  email: number;
  city: number;
  country: number;
  stateorprovince: number;
  job_title: number;
  company: number;
  address_linkage: number;
  telephone: number;
}
export interface CountryBreakdownItem {
  company: string;
  primary_email: string;
  zipcode: string;
  salutation: string;
  secondary_email: string;
  contact_type: string;
  job_title: string;
  first_name: string;
  country: string;
  id: number;
  last_name: string;
  city: string;
}

export interface CRMEvolutionPoint {
  key: string;
  total_contacts: number;
  contacts_with_issues: number;
}

export interface TrendItem {
  trend: number;
  old: number;
  new: number;
}

export interface IntegratedSystemDetail {
  name?: string;
  crm_stats: {
    orphaned_contacts: number;
    total_contacts: number;
    total_contacts_with_issues: number;
    total_contacts_with_issues_gt_25: number;
    total_email_issues_gt_25: number;
    total_location_issues_gt_25: number;
    total_naming_issues_gt_25: number;
    email_success: number;
    email_bounces: number;
    companies_count: {
      crm: number;
      cirrom: number;
      cirrom_with_issues: number;
    };
    contact_locations: any;
    score_latest: number;
    score_start: number;
  };
  email_stats?: {
    sent_emails: number;
    opened_emails: number;
  };
  crm_evolution: CRMEvolutionPoint[];

  needing_consent: {
    total: number;
    breakdown: {
      missing: number;
      elapsed: number;
      expiring_next_90_days: number;
      expiring_next_60_days: number;
      expiring_next_30_days: number;
      missing_location_data: number;
    };
  };
  trend: {
    name: TrendItem;
    employment: TrendItem;
    location: TrendItem;
    duplicates: TrendItem;
    email: TrendItem;
  };
  merged_contacts: {
    total: number;
    changed: number;
    tickets: number;
  };
}

export interface ContactsTimeline {
  total_contacts: number;
  contacts_with_issues: number;
  date: Date;
}

export interface TicketsTimeline {
  total_issues: number;
  date: Date;
}

export const getIntegratedSystemsForUser = async (): Promise<
  IntegratedSystem[]
> => {
  const response = await API.get("get-active-data-loader-systems");
  return response.data;
};

export const getIntegratedSystemDetail = async (
  systemId: number
): Promise<IntegratedSystemDetail> => {
  const response = await API.get("/system_summary", {
    params: { system_id: systemId },
  });
  return response.data;
};

export const getDataProjectSystemDetail = async (
  dataProjectId: number
): Promise<IntegratedSystemDetail> => {
  const response = await API.get("/data-project-summary", {
    params: { data_project_id: dataProjectId },
  });
  return response.data;
};

export const getPendingChangesSummary = async (
  dataProjectId?: number,
  integratedSystemId?: number,
  showDistinctContacts?: boolean
): Promise<PendingChangesSummary> => {
  const response = await API.get("/pending-changes-for-system", {
    params: {
      data_project_id: dataProjectId,
      system_id: integratedSystemId,
      show_distinct_contacts: showDistinctContacts,
    },
  });
  return response.data;
};

export const getCountryBreakdown = async (
  countryId: number
): Promise<CountryBreakdownItem[]> => {
  const response = await API.get("/map_crm_data", {
    params: { map_id: countryId },
  });
  return response.data;
};

export const getContactStatsOverTime = async (
  systemId: number,
  dataProjectId: number
): Promise<ContactsTimeline[]> => {
  const response = await API.get("/get-contact-stats-over-time", {
    params: { system_id: systemId, data_project_id: dataProjectId },
  });
  return response.data;
};

export const getChangeStatsOverTime = async (
  changeType: string,
  systemId: number,
  dataProjectId: number
): Promise<TicketsTimeline[]> => {
  const response = await API.get("get-change-stats-over-time", {
    params: {
      change_type: changeType,
      system_id: systemId,
      data_project_id: dataProjectId,
    },
  });
  return response.data;
};

export const getScoreStats = async (
  systemId: number,
  dataProjectId: number
): Promise<ContactsTimeline[]> => {
  const response = await API.get("/get-score-stats", {
    params: { system_id: systemId, data_project_id: dataProjectId },
  });
  return response.data;
};

export const getMonthlyStats = async (): Promise<any> => {
  const response = await API.get("/get-monthly-stats");
  return response.data;
};
