import React from "react";
import Alert from "@mui/material/Alert";
import { Helmet } from "react-helmet";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export function Disabled() {
  return (
    <>
      <Helmet>
        <title>Disabled | CIRROM</title>
      </Helmet>
      <Box
        style={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "25px",
          minHeight: (window.outerHeight * 0.6).toString() + "px",
        }}
      >
        <Container maxWidth="sm">
          <Alert severity="error">
            Cirrom is unavailable, please contact the Cirrom team.
            <br />
            info@cirrom.com
          </Alert>
        </Container>
      </Box>
    </>
  );
}
