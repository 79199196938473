import { MainNavbar } from "../components/MainNavbar";
import { Outlet } from "react-router-dom";
import React, { useContext, useState } from "react";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { IntegratedSystem } from "../api/integratedsystems";
import { SideBarWidthContext } from "../App";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      height: "100%",
      overflow: "hidden",
      width: "100%",
      paddingTop: 59,
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 240,
      },
    },
    minimized: {
      display: "flex",
      height: "100%",
      overflow: "hidden",
      width: "100%",
      paddingTop: 59,
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 40,
      },
    },
  })
);

/**
 * Represents the properties of the AppLayout component.
 *
 * @typedef {object} AppLayoutProps
 * @property {function} [onLogout] - The callback function to be called when the user logs out.
 * @property {React.Component} [Sidebar] - The component to be rendered as the sidebar.
 * @property {string} [userProfile] - The user's profile.
 * @property {number} selectedSystemId - The ID of the selected system.
 * @property {(number) => void} setSelectedSystemId - The callback function to set the selected system ID.
 * @property {IntegratedSystem[]} userSystems - The array of integrated systems for the user.
 * @property {string} currentUser - The name of the current user.
 */
export interface AppLayoutProps {
  onLogout?: any;
  Sidebar?: any;
  userProfile?: string;
  selectedSystemId: number;
  setSelectedSystemId: (number) => void;
  userSystems: IntegratedSystem[];
  currentUser: string;
}

/**
 * Renders the main layout of the application.
 *
 * @param {Readonly<AppLayoutProps>} props - The properties for the AppLayout component.
 * @param {Function} props.onLogout - The function to be called when the user logs out.
 * @param {React.ComponentType} props.Sidebar - The sidebar component.
 * @param {object} props.userProfile - The user profile.
 * @param {string} props.selectedSystemId - The id of the currently selected system.
 * @param {Function} props.setSelectedSystemId - The function to be called when the system id is set.
 * @param {Array} props.userSystems - The list of user systems.
 * @param {object} props.currentUser - The current user.
 *
 * @return {React.Element} The rendered AppLayout component.
 */
export function AppLayout(props: Readonly<AppLayoutProps>) {
  const {
    onLogout,
    Sidebar,
    userProfile,
    selectedSystemId,
    setSelectedSystemId,
    userSystems,
    currentUser,
  } = props;
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const sideBarWidth = useContext(SideBarWidthContext);

  const classes = useStyles();
  return (
    <div
      className={
        sideBarWidth.value === "small" ? classes.minimized : classes.main
      }
    >
      <Sidebar
        onMobileClose={() => setIsMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        userProfile={userProfile}
        selectedSystemId={selectedSystemId}
        setSelectedSystemId={setSelectedSystemId}
        userSystems={userSystems}
      />
      <div
        style={{
          display: "flex",
          flex: "1 1 auto",
          overflowY: "hidden",
        }}
        id="scroll-container"
      >
        <div style={{ display: "flex", flex: "1 1 auto" }}>
          <div
            style={{
              flex: "1 1 auto",
              height: "100%",
            }}
          >
            <MainNavbar
              onMobileNavOpen={() => setIsMobileNavOpen(true)}
              onLogout={onLogout}
              userProfile={userProfile}
              currentUser={currentUser}
            />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
