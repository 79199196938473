import {
  DataLoaderSystemComponent,
  DataLoaderSystemComponentContent,
} from "./DataLoaderSystem";
import React, { useState, useEffect } from "react";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { DataLoaderOption } from "../../api/datasystems";

export interface DataLoaderConfigComponentContent {
  name: string;
  dataLoaderSystems: DataLoaderSystemComponentContent[];
  readEnabled: boolean;
  writeEnabled: boolean;
}

export interface DataLoaderConfigComponentProps {
  name: string;
  dataLoaderSystemIds?: number[];
  configRevisionId: number;
  existing?: boolean;
  onDataLoaderConfigChange: (content: DataLoaderConfigComponentContent) => void;
  dataLoaderOptions: DataLoaderOption[];
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 5px 5px 5px",
      borderLeft: "1px solid #c4c4c4",
    },
  })
);

/**
 * A data loader config view - allows users to specify the data loader type - IA, Dynamics, Concep etc
 *
 */

export function DataLoaderConfigComponent(
  props: Readonly<DataLoaderConfigComponentProps>
) {
  const {
    onDataLoaderConfigChange,
    name,
    existing,
    dataLoaderSystemIds,
    configRevisionId,
  } = props;
  const [dataLoaderSystems, setDataLoaderSystems] = useState<
    DataLoaderSystemComponentContent[]
  >([]);
  const [userDefinedReadEnabled, setUserDefinedReadEnabled] =
    useState<boolean>(true);
  const [userDefinedWriteEnabled, setUserDefinedWriteEnabled] =
    useState<boolean>(false);

  const onDataLoaderSystemChange = (
    content: DataLoaderSystemComponentContent
  ) => {
    setDataLoaderSystems([content]);
  };

  useEffect(() => {
    onDataLoaderConfigChange({
      name: name,
      dataLoaderSystems: dataLoaderSystems,
      readEnabled: userDefinedReadEnabled,
      writeEnabled: userDefinedWriteEnabled,
    });
  }, [
    name,
    dataLoaderSystems,
    userDefinedReadEnabled,
    userDefinedWriteEnabled,
  ]);

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Read"
          disabled={existing === true}
          onChange={(event) =>
            setUserDefinedReadEnabled(
              (event as React.ChangeEvent<HTMLInputElement>).target.checked
            )
          }
        />
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Write"
          disabled={existing === true}
          onChange={(event) =>
            setUserDefinedWriteEnabled(
              (event as React.ChangeEvent<HTMLInputElement>).target.checked
            )
          }
        />
      </FormGroup>
      {dataLoaderSystemIds.length > 0 ? (
        <React.Fragment>
          {dataLoaderSystemIds.map((dataSystemId) => {
            return (
              <DataLoaderSystemComponent
                name="name"
                type=""
                id={dataSystemId}
                configRevisionId={configRevisionId}
                existing={existing}
                key={`DataSystem-${dataSystemId}`}
                onDataLoaderSystemChange={onDataLoaderSystemChange}
                dataLoaderOptions={props.dataLoaderOptions}
              />
            );
          })}
        </React.Fragment>
      ) : (
        <DataLoaderSystemComponent
          name="name"
          type=""
          existing={existing}
          onDataLoaderSystemChange={onDataLoaderSystemChange}
          dataLoaderOptions={props.dataLoaderOptions}
        />
      )}
    </Container>
  );
}
