import {
  amber,
  blueGrey,
  deepOrange,
  deepPurple,
  indigo,
  lightGreen,
  teal,
} from "@mui/material/colors";

/**
 * Array of colors used for charts.
 *
 * @type {Array<string>}
 */
export const ChartColors = [
  teal[500],
  deepOrange[500],
  indigo[500],
  lightGreen[500],
  deepPurple[500],
  amber[500],
  blueGrey[500],
];
