import { API } from "./api";
export interface Rule {
  id?: number;
  rule_group_id?: number;
  active: boolean;
  description: string;
  verb: "ANY" | "ALL";
  pre_conditions?: Condition[];
  conditions: Condition[];
  actions: Action[];
  icon_code?: any;
  rule_group_type?: string;
}

export interface RuleGroup {
  id?: number;
  rule_type: string;
  rules: Rule[];
  name: string;
  batch_group_id?: number;
}

export interface Condition {
  subject: string;
  statement: string;
  expectation: any;
  id?: number;
}

export interface Action {
  action_type: string;
  action_value: string;
  subjects?: any[];
  requires_input?: boolean;
  id?: number;
}

export interface DataRuleMeta {
  preconditions?: any[];
  conditions: any[];
  actions: Action[];
}

export enum RuleType {
  FILE_UPLOAD,
  COMPLIANCE,
  CHANGES,
  CRM,
  DUPLICATES = 4,
}

export interface RuleGroupDescription {
  rule_group_id: number;
  rule_group_type: string;
}

export const getDataRulesMeta = async (
  ruleGroupId: number,
  ruleType: RuleType
): Promise<DataRuleMeta> => {
  if (window.localStorage.getItem(`dataRulesMeta-${ruleType}`)) {
    const localStorageValue = JSON.parse(
      window.localStorage.getItem(`dataRulesMeta-${ruleType}`)
    );
    if (localStorageValue.timestamp > Date.now() - 1000 * 60 * 60 * 24) {
      return localStorageValue.value;
    }
  }
  const response = await API.get("/get-data-rules-meta", {
    params: { rule_group_id: ruleGroupId, rule_type: RuleType[ruleType] },
  });
  if (response.data) {
    window.localStorage.setItem(
      `dataRulesMeta-${ruleType}`,
      JSON.stringify({ value: response.data, timestamp: Date.now() })
    );
  }

  return response.data;
};

export const saveRule = async (rule: Rule): Promise<Rule> => {
  const response = await API.put("/save-rule", {
    description: rule.description,
    verb: rule.verb,
    rule_group_id: rule.rule_group_id || null,
    conditions: rule.conditions,
    pre_conditions: [],
    actions: rule.actions,
    id: rule.id || null,
  });
  return response.data;
};

export const getRule = async (ruleId): Promise<Rule> => {
  const response = await API.get("/get-rule", { params: { rule_id: ruleId } });
  return {
    ...response.data.rule,
    rule_group_type: response.data.rule_group_type,
  };
};

export const getGenericFileUploadRuleGroup = async (): Promise<RuleGroup> => {
  const response = await API.get("/get-generic-file-upload-rule-group");
  return response.data;
};

export const getComplianceRuleGroup = async (): Promise<RuleGroup> => {
  const response = await API.get("/get-compliance-rule-groups");
  return response.data;
};

export const getGlobalComplianceRuleGroup = async (): Promise<RuleGroup> => {
  const response = await API.get("/get-global-compliance-rule-group");
  return response.data;
};

export const addRuleGroup = async (
  ruleGroup: RuleGroup
): Promise<RuleGroup> => {
  const response = await API.put("/add-rule-group", {
    name: ruleGroup.name,
    rule_type: ruleGroup.rule_type,
    batch_group_id: ruleGroup.batch_group_id,
  });
  return response.data;
};

export const getRuleGroup = async (ruleGroupId: number): Promise<RuleGroup> => {
  const response = await API.get("get-rule-group", {
    params: { rule_group_id: ruleGroupId },
  });
  return response.data;
};

export const deleteRule = async (ruleId: number) => {
  const response = await API.delete("/delete-rule", {
    params: { rule_id: ruleId },
  });
  return response.data;
};

export const toggleRule = async (
  ruleId: number,
  active: boolean
): Promise<Rule> => {
  const response = await API.put("/toggle-rule", {
    rule_id: ruleId,
    active: active,
  });
  return response.data;
};

export const getComplianceRuleExamples = async (): Promise<RuleGroup> => {
  const response = await API.get("get-compliance-rule-examples");
  return response.data;
};

export const getGlobalRuleGroups = async (): Promise<RuleGroup[]> => {
  const response = await API.get("get-global-rule-groups");
  return response.data;
};

export const updateRuleGroupOrdering = async (
  ruleGroupId: number,
  ruleIds: number[]
): Promise<RuleGroup> => {
  const response = await API.put("/update-rule-ordering", {
    rule_group_id: ruleGroupId,
    rule_ids: ruleIds,
  });
  return response.data;
};

export const getRuleGroupsForBatchGroup = async (
  batchGroupId: number
): Promise<RuleGroupDescription[]> => {
  const response = await API.get("/get-rule-groups-for-batch-group", {
    params: { batch_group_id: batchGroupId },
  });
  return response.data;
};
