import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import React, { useState, useEffect } from "react";
import {
  DataLoaderOption,
  DataOption,
  getDataLoaderSystem,
  getDataLoaderOptions,
  IntegrationOptions,
  getIntegratedDataLoader,
  updateDataLoaderAdaptorProperties,
  getDataSystemEventTypes,
} from "../../api/datasystems";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

export interface DataLoaderSystemComponentContent {
  name: string;
  type: string;
  options: object;
  integrations: IntegrationOptions;
  list_ids?: string[];
}

export interface DataLoaderSystemComponentProps {
  name: string;
  type: string;
  configRevisionId?: number;
  id?: number;
  existing?: boolean;
  onDataLoaderSystemChange: (
    dataLoaderSystem: DataLoaderSystemComponentContent
  ) => void;
  dataLoaderOptions: DataLoaderOption[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export function DataLoaderSystemComponent(
  props: Readonly<DataLoaderSystemComponentProps>
) {
  const {
    name,
    type,
    onDataLoaderSystemChange,
    id,
    configRevisionId,
    existing,
  } = props;
  const classes = useStyles();
  const [userDefinedName, setUserDefinedName] = useState<string>(name || "");
  const [userDefinedType, setUserDefinedType] = useState<string>(type || "");

  const [userDefinedOptions, setUserDefinedOptions] = useState<{}>({});
  const [integratedSystemOptions, setIntegratedSystemOptions] =
    useState<IntegrationOptions>();

  useEffect(() => {
    let isMounted = true;

    if (id !== undefined && configRevisionId !== undefined) {
      getDataLoaderSystem(id)
        .then((systemResponse) => {
          if (isMounted) {
            getDataLoaderOptions(id, configRevisionId)
              .then((dataOptionResponse) => {
                let selectedOptions = {};
                dataOptionResponse.forEach((option) => {
                  selectedOptions[option.path] = option.value;
                });
                setUserDefinedOptions(selectedOptions);
              })
              .catch((err) => console.log(err));
            getIntegratedDataLoader(id, configRevisionId)
              .then((integratedDataLoader) => {
                setIntegratedSystemOptions(integratedDataLoader);
              })
              .catch((err) => console.log(err));
            setUserDefinedName(systemResponse.name);
            setUserDefinedType(systemResponse.system_type);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => {
      isMounted = false;
    };
  }, [id, configRevisionId]);

  useEffect(() => {
    onDataLoaderSystemChange({
      name: userDefinedName,
      type: userDefinedType,
      options: userDefinedOptions,
      integrations: integratedSystemOptions,
    });
  }, [
    userDefinedName,
    userDefinedType,
    userDefinedOptions,
    integratedSystemOptions,
  ]);

  const handleSystemTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setUserDefinedType(event.target.value as string);
    setUserDefinedOptions([]);
    setIntegratedSystemOptions({
      ...integratedSystemOptions,
      EmailMarketing: "",
      TimeAndBilling: "",
    });
  };

  const updateUserDefinedOptions = (value: string, option: DataOption) => {
    setUserDefinedOptions({ ...userDefinedOptions, [option.path]: value });
  };

  return (
    <FormControl className={classes.formControl}>
      <TextField
        name={"data_loader_system_name"}
        required
        fullWidth
        label="Name"
        value={userDefinedName}
        variant="outlined"
        size="small"
        margin="dense"
        disabled={existing === true}
        onChange={(event) => {
          setUserDefinedName(event.target.value);
        }}
      />
      <Container style={{ padding: 0 }}>
        <Typography>Modes</Typography>
      </Container>

      {props.dataLoaderOptions ? (
        <TextField
          select
          required
          fullWidth
          label="Type"
          variant="outlined"
          onChange={handleSystemTypeChange}
          value={userDefinedType}
          size="small"
          margin="dense"
        >
          {props.dataLoaderOptions.map((dataLoaderOption) => {
            return (
              <MenuItem
                key={dataLoaderOption.type.toLowerCase()}
                value={dataLoaderOption.type}
              >
                {dataLoaderOption.type}
              </MenuItem>
            );
          })}
        </TextField>
      ) : null}

      {userDefinedType && props.dataLoaderOptions
        ? props.dataLoaderOptions
            .filter((item) => {
              return item.type === userDefinedType;
            })
            .map((dataLoaderOption) => {
              return dataLoaderOption.options.map((option) => {
                return (
                  <Tooltip title={option.description} key={option.description}>
                    <div>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        type={
                          option.type === "password" ||
                          option.name === "App client secret"
                            ? "password"
                            : null
                        }
                        multiline={option.type === "json"}
                        minRows={5}
                        key={option.path}
                        label={option.name}
                        value={userDefinedOptions[option.path] || ""}
                        onChange={(event) => {
                          updateUserDefinedOptions(event.target.value, option);
                        }}
                      />
                      {option.name.includes("Marketing Activity IDs") && (
                        <IconButton
                          size={"small"}
                          color={"primary"}
                          onClick={() => {
                            getDataSystemEventTypes(id, configRevisionId).then(
                              (response) => console.log(response)
                            );
                          }}
                        >
                          <InfoIcon fontSize={"small"} />
                        </IconButton>
                      )}
                    </div>
                  </Tooltip>
                );
              });
            })
        : null}
      <Button
        onClick={() => {
          let properties = [];
          for (let key in userDefinedOptions) {
            properties.push({
              name: key,
              value: userDefinedOptions[key],
            });
          }
          updateDataLoaderAdaptorProperties(
            id,
            configRevisionId,
            properties,
            userDefinedType
          )
            .then((response) => console.log("data loader props updated"))
            .catch((error) => console.error(error));
        }}
      >
        Apply
      </Button>
    </FormControl>
  );
}
