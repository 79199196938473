import React from "react";

import { ChartColors } from "../../theme/chartColors";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
interface ChartData {
  labels: string[];
  data: number[];
}

/**
 * ChartGridItem Component
 *
 * A component that displays a chart inside a grid item with a title. It is used to create a consistent
 * layout for rendering charts or graphs in a grid-based dashboard or similar interface.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.title - The title of the chart displayed in the grid item.
 * @param {JSX.Element} props.children - The chart or graph component to be rendered inside the grid item.
 * @returns {JSX.Element} - The rendered ChartGridItem component.
 */
function ChartGridItem(props: Readonly<{ title: string; children: any }>) {
  // Destructure props to get title and children
  const { title, children } = props;

  // Render the ChartGridItem component with the provided title and chart component inside a grid item
  return (
    <Grid item lg={4} sm={12} xl={4} xs={12}>
      <Card style={{ height: "100%" }}>
        <CardHeader
          title={title}
          titleTypographyProps={{ color: "textSecondary" }}
        />
        <Divider />
        <CardContent>
          {/* Render the chart component inside a div with fixed height */}
          <div style={{ height: "160px" }}>{children}</div>
        </CardContent>
      </Card>
    </Grid>
  );
}

/**
 * UploadedFileChartSection Component
 *
 * A component that displays multiple charts related to uploaded file data. It shows three charts:
 * 1. "Empty Fields Populated" chart - Displays the number of empty fields that have been populated in the file.
 * 2. "Issues Identified" chart - Displays the number of identified issues in the uploaded file data.
 * 3. "Corrections Breakdown" chart - Shows a breakdown of suggested corrections for the identified issues.
 *
 * @param {Object} props - The component's props.
 * @param {ChartData} props.blanksChartData - The data for the "Empty Fields Populated" chart.
 * @param {ChartData} props.changesChartData - The data for the "Issues Identified" chart.
 * @param {ChartData} props.suggestedFixesChartData - The data for the "Corrections Breakdown" chart.
 * @returns {JSX.Element} - The rendered UploadedFileChartSection component.
 */
export function UploadedFileChartSection(
  props: Readonly<{
    blanksChartData: ChartData;
    changesChartData: ChartData;
    suggestedFixesChartData: ChartData;
  }>
) {
  // Destructure props
  const { blanksChartData, changesChartData, suggestedFixesChartData } = props;

  return (
    <>
      {/* "Empty Fields Populated" chart */}
      {blanksChartData && (
        <ChartGridItem title="Empty Fields Populated">
          <ResponsiveBar
            data={blanksChartData.labels.map((label, idx) => {
              return {
                id: label,
                label: label,
                value: blanksChartData.data[idx],
              };
            })}
            colorBy={"indexValue"}
            colors={ChartColors}
            valueFormat={" >-#,.2d"}
            margin={{ top: 10, right: 10, bottom: 40, left: 30 }}
            innerPadding={5}
            axisBottom={{ tickRotation: -15 }}
          />
        </ChartGridItem>
      )}
      {/* "Issues Identified" chart */}
      {changesChartData && (
        <ChartGridItem title="Issues Identified">
          <ResponsivePie
            valueFormat={" >-#,.2d"}
            data={changesChartData.labels.map((label, idx) => {
              return {
                id: label,
                label: label,
                value: changesChartData.data[idx],
              };
            })}
            margin={{ top: 0, right: 40, bottom: 0, left: 0 }}
            innerRadius={0.5}
            padAngle={5}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            enableArcLinkLabels={false}
            colors={ChartColors}
            legends={[
              {
                anchor: "bottom",
                direction: "column",
                justify: false,
                translateX: 110,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: 50,
                itemHeight: 14,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: "circle",
              },
            ]}
          />
        </ChartGridItem>
      )}
      {/* "Corrections Breakdown" chart */}
      {suggestedFixesChartData && (
        <ChartGridItem title="Corrections Breakdown">
          <ResponsiveBar
            data={suggestedFixesChartData.labels.map((label, idx) => {
              return {
                id: label,
                label: label,
                value: suggestedFixesChartData.data[idx],
              };
            })}
            valueFormat={" >-#,.2d"}
            margin={{ top: 10, right: 10, bottom: 40, left: 30 }}
            innerPadding={5}
            colorBy={"indexValue"}
            colors={ChartColors}
            axisBottom={{ tickRotation: -15 }}
          />
        </ChartGridItem>
      )}
    </>
  );
}
