import React, { useEffect, useState } from "react";
import { CRMPanel } from "../components/home/NewCRMPanel";
import { PageTitle } from "../components/utils/PageTitle";
import { IntegratedSystem } from "../api/integratedsystems";
import { SkeletonCRMPanel } from "../components/home/SkeletonCRMPanel";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";

export const Home = (props: {
  userSystems: IntegratedSystem[];
  setSelectedSystemId: (number) => void;
}) => {
  const navigate = useNavigate();
  const [selectedSystem, setSelectedSystem] = useState<IntegratedSystem>();

  const { integratedSystemId } = useParams();

  useEffect(() => {
    if (integratedSystemId) {
      props.setSelectedSystemId(parseInt(integratedSystemId));
      props.userSystems.forEach((system) => {
        if (system.id === parseInt(integratedSystemId)) {
          setSelectedSystem(system);
        }
      });
    }
  }, [integratedSystemId, props.userSystems]);

  useEffect(() => {
    if (
      !integratedSystemId &&
      props.userSystems &&
      props.userSystems.length > 0
    ) {
      navigate(`/app/dashboard/${props.userSystems[0].id}`);
    }
  }, [integratedSystemId, props.userSystems]);

  return (
    <PageTitle pageTitle="Home | CIRROM" pageHeader="">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          {selectedSystem && selectedSystem.type.indexOf("CRM") > -1 && (
            <React.Fragment key={`gridItem-${selectedSystem.id}`}>
              <Grid
                key={`gridItem-${selectedSystem.id}`}
                item
                lg={12}
                md={12}
                sm={12}
                xl={12}
                xs={12}
              >
                <CRMPanel system={selectedSystem} />
              </Grid>
            </React.Fragment>
          )}
          {!selectedSystem && (
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <SkeletonCRMPanel />
            </Grid>
          )}
        </Grid>
      </Container>
    </PageTitle>
  );
};
