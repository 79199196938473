import { Condition, RuleType } from "../../api/ruleengine";
import React, { useEffect, useState } from "react";

import { toReadableText } from "../utils/utils";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";

export function RuleCondition(
  props: Readonly<{
    onAdd?(condition: Condition): void;
    onRemove?(condition: Condition): void;
    onConditionChange(condition: Condition): void;
    selectedSubject?: string;
    conditionStatementsBySubject?: any;
    selectedStatement?: string;
    userInput?: any;
    disableRuleEdits?: boolean;
    ruleType: RuleType;
  }>
) {
  const {
    onAdd,
    onRemove,
    conditionStatementsBySubject,
    onConditionChange,
    disableRuleEdits,
  } = props;

  const [selectedSubject, setSelectedSubject] = useState<string>(
    props.selectedSubject
  );

  const [selectedStatement, setSelectedStatement] = useState<string>(
    props.selectedStatement
  );

  const [statementsByCategory, setStatementsByCategory] = useState<any[]>([]);
  const [selectedStatementObj, setSelectedStatementObj] = useState<any>({});

  const [statementsForSubject, setStatementsForSubject] =
    useState<any>(undefined);

  const [userInput, setUserInput] = useState<any>(props.userInput || "");

  const [selectedEntity, setSelectedEntity] = useState<string>("ALL");
  const handleConditionSubjectChange = (event) => {
    setSelectedSubject(event.target.value);
    setSelectedStatement(
      conditionStatementsBySubject[event.target.value].statements[0].name
    );
  };

  useEffect(() => {
    onConditionChange({
      subject: selectedSubject,
      statement: selectedStatement,
      expectation: userInput,
    });
  }, [userInput, selectedSubject, selectedStatement]);

  useEffect(() => {
    if (!selectedSubject) {
      if (
        conditionStatementsBySubject &&
        Object.entries(conditionStatementsBySubject).length > 0
      ) {
        setSelectedSubject(Object.entries(conditionStatementsBySubject)[0][0]);
        if (!selectedStatement) {
          setSelectedStatement(
            (Object.entries(conditionStatementsBySubject)[0][1] as any)
              .statements[0].name
          );
        }
      }
    }
  }, [conditionStatementsBySubject, selectedSubject, selectedStatement]);

  useEffect(() => {
    if (selectedSubject) {
      setStatementsForSubject(conditionStatementsBySubject[selectedSubject]);
    }
  }, [selectedSubject, conditionStatementsBySubject]);

  useEffect(() => {
    const byCategory = {};
    Object.entries(conditionStatementsBySubject)
      .filter((statement) => {
        return (
          // @ts-ignore
          !statement[1].deprecated || statement[1].name === selectedSubject
        );
      })
      .forEach((statement) => {
        // @ts-ignore
        const category = statement[1].category;
        if (!byCategory[category]) {
          byCategory[category] = [];
        }
        byCategory[category].push(statement[0]);
      });
    // @ts-ignore
    setStatementsByCategory(byCategory);
  }, [conditionStatementsBySubject]);

  useEffect(() => {
    if (
      conditionStatementsBySubject &&
      selectedSubject &&
      selectedStatement &&
      conditionStatementsBySubject[selectedSubject]
    ) {
      const filtered = conditionStatementsBySubject[
        selectedSubject
      ].statements.filter((value) => value.name === selectedStatement);

      if (filtered.length > 0) {
        setSelectedStatementObj(filtered[0]);
      }
    }
  }, [conditionStatementsBySubject, selectedSubject, selectedStatement]);

  useEffect(() => {
    if (selectedSubject !== props.selectedSubject) {
      setUserInput("");
    }
  }, [props.selectedSubject, selectedSubject]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
      data-testid={"rule-condition"}
    >
      {props.ruleType === RuleType.DUPLICATES && (
        <Select
          style={{ marginRight: "0.5em", flex: "1" }}
          variant={"standard"}
          value={selectedEntity}
          onChange={(event) => setSelectedEntity(event.target.value)}
        >
          <MenuItem value={"ALL"}>All Members</MenuItem>
          <MenuItem value={"PRIMARY"}>Primary</MenuItem>
          <MenuItem value={"SECONDARY"}>Secondaries</MenuItem>
        </Select>
      )}

      <Select
        variant={"standard"}
        value={selectedSubject || ""}
        onChange={handleConditionSubjectChange}
        style={{ marginRight: "0.5em", flex: "1" }}
        disabled={disableRuleEdits}
      >
        {Object.entries(statementsByCategory).map((categoryAndStatements) => {
          return [
            <ListSubheader key={categoryAndStatements[0]}>
              {categoryAndStatements[0]}
            </ListSubheader>,
            ...categoryAndStatements[1].map((item) => (
              <MenuItem key={`${item}.${item}`} value={item}>
                {toReadableText(item)}
              </MenuItem>
            )),
          ];
        })}
      </Select>
      {statementsForSubject ? (
        <Select
          variant={"standard"}
          disabled={disableRuleEdits}
          id="conditions-statement-select"
          value={selectedStatement || ""}
          style={{ marginRight: "0.5em", flex: 1 }}
          onChange={(event) => {
            setSelectedStatement(event.target.value);
          }}
        >
          {statementsForSubject.statements.map((statement) => {
            return (
              <MenuItem key={statement.name} value={statement.name}>
                {toReadableText(statement.name)}
              </MenuItem>
            );
          })}
        </Select>
      ) : null}

      {statementsForSubject?.data_options?.length > 0 &&
        selectedStatementObj?.field_type === "multiple_choice" &&
        selectedStatementObj.requires_input && (
          <Autocomplete
            disabled={disableRuleEdits}
            multiple
            limitTags={2}
            style={{ flex: 1 }}
            //@ts-ignore
            getOptionLabel={(value) => toReadableText(value.title)}
            options={statementsForSubject.data_options.map((option) => {
              return typeof option === "string"
                ? { title: option, id: option }
                : option;
            })}
            onChange={(_event, value) => {
              // @ts-ignore
              setUserInput(value.map((object) => object.id).join("~"));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                margin={"dense"}
                size={"small"}
                disabled={disableRuleEdits}
              />
            )}
            defaultValue={
              statementsForSubject.data_options.filter((value) => {
                return userInput
                  ?.split("~")
                  .includes(typeof value === "string" ? value : value.id);
              }).length > 0
                ? statementsForSubject.data_options
                    .filter((value) =>
                      userInput
                        ?.split("~")
                        .includes(typeof value === "string" ? value : value.id)
                    )
                    .map((value) => {
                      return typeof value === "string"
                        ? { title: value, id: value }
                        : value;
                    })
                : []
            }
          />
        )}
      {statementsForSubject?.data_options?.length > 0
        ? selectedStatementObj?.field_type === "multiple_choice"
          ? null
          : selectedStatementObj?.requires_input && (
              <Autocomplete
                style={{ flex: 1 }}
                disabled={disableRuleEdits}
                options={statementsForSubject.data_options}
                getOptionLabel={(value) => toReadableText(value as string)}
                onChange={(_event, value) => setUserInput(value as string)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    margin={"dense"}
                    size={"small"}
                  />
                )}
                defaultValue={
                  statementsForSubject.data_options.indexOf(userInput) > -1
                    ? userInput
                    : ""
                }
              />
            )
        : selectedStatementObj?.requires_input && (
            <TextField
              variant={"standard"}
              margin={"dense"}
              size={"small"}
              disabled={disableRuleEdits}
              onChange={(event) => {
                let val: any = event.target.value;
                if (statementsForSubject.statements[0].field_type === "num") {
                  val = parseFloat(event.target.value);
                }
                setUserInput(val);
              }}
              value={userInput}
              style={{ flex: 1, margin: 0 }}
              type={
                statementsForSubject?.statements[0]?.field_type === "num"
                  ? "number"
                  : "text"
              }
            />
          )}

      <div style={{ display: "flex" }}>
        <IconButton
          data-testid={"remove_condition_btn"}
          aria-label="remove_condition"
          color="primary"
          size="small"
          disabled={!onRemove || disableRuleEdits}
          onClick={() => {
            onRemove({
              subject: selectedSubject,
              statement: selectedStatement,
              expectation: userInput,
            });
          }}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
        <IconButton
          data-testid={"add_condition_btn"}
          color="primary"
          disabled={!onAdd || disableRuleEdits}
          size="small"
          onClick={() => {
            onAdd({
              subject: selectedSubject,
              statement: selectedStatement,
              expectation: userInput,
            });
          }}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
}
