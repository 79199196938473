import { API } from "./api";

export const saveAADSecrets = async (
  applicationId: string,
  clientSecret: string,
  aadAuthURL: string
): Promise<string> => {
  const response = await API.post("/save-encrypted-aad-config", {
    application_id: applicationId,
    client_secret: clientSecret,
    authorization_url: aadAuthURL,
  });
  return response.data;
};

export const getPreferences = async (): Promise<any> => {
  const response = await API.get("/get-preference-config");
  return response.data;
};

export const saveStatePreferenceConfig = async (
  preference: string
): Promise<any> => {
  const response = await API.post("/save-state-preference-config", {
    preference,
  });
  return response.data;
};

export const saveAdaptorWritePreference = async (
  preference: boolean
): Promise<any> => {
  const response = await API.post("/save-write-preference-config", {
    preference,
  });
  return response.data;
};

export const saveContactSourceFolderId = async (
  preference: string
): Promise<any> => {
  const response = await API.post("/save-ia-contact-source-folder-config", {
    preference,
  });
  return response.data;
};

export const saveCompanySourceFolderId = async (
  preference: string
): Promise<any> => {
  const response = await API.post("/save-ia-company-source-folder-config", {
    preference,
  });
  return response.data;
};

export const saveIaWorkingListName = async (
  preference: string
): Promise<any> => {
  const response = await API.post("/save-ia-working-list-name", {
    preference,
  });
  return response.data;
};

export const saveFeatureFlagPreference = async (
  feature_name: string,
  preference: boolean
): Promise<any> => {
  const response = await API.post("/save-feature-flag-preference-config", {
    feature_name: feature_name,
    preference: preference,
  });
  return response.data;
};

export const saveEncryptedDbSecret = async (dbSecret: string): Promise<any> => {
  const response = await API.post("/save-encrypted-db-secret", {
    secret: dbSecret,
  });
  return response.data;
};
