import React, { useEffect, useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import {
  BatchWindow,
  updateBatchWindow,
  BatchWindowConfig,
} from "../../api/batch";

import { toReadableText } from "../utils/utils";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";

export interface ExecutionComponentContent {
  windowType: string;
  startTime: string;
  endTime: string;
  timeZone: string;
  days: string;
  runStrategy: string;
}

export interface ExecutionComponentProps {
  existingBatchWindows: BatchWindow[];
  onExecutionComponentChange: (
    executionContent: ExecutionComponentContent[]
  ) => void;
  existing?: boolean;
  batchWindowConfig: BatchWindowConfig;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",

      flexDirection: "column",
      padding: "10px 5px 5px 5px",
      borderLeft: "1px solid #c4c4c4",
    },
  })
);

export const ExecutionComponent = (
  props: Readonly<ExecutionComponentProps>
) => {
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(2345);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>("");

  const [selectedWindowTypes, setSelectedWindowTypes] = useState<string[]>([]);
  const [selectedWindowTime, setSelectedWindowTime] = useState<string>("");
  const [selectedRunStrategy, setSelectedRunStrategy] = useState<string>("");
  const [sliderMarks, setSliderMarks] = useState<any[]>([]);
  const {
    onExecutionComponentChange,
    existingBatchWindows,
    existing,
    batchWindowConfig,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    setSliderMarks(getSliderMarks());
  }, []);

  useEffect(() => {
    let executionContent = [];

    selectedWindowTypes.forEach((item) => {
      executionContent.push({
        windowType: item,
        startTime: startTime,
        endTime: endTime,
        timeZone: selectedTimeZone,
        days: selectedWindowTime,
        runStrategy: selectedRunStrategy,
      });
    });
    onExecutionComponentChange(executionContent);
  }, [
    selectedWindowTypes,
    selectedWindowTime,
    startTime,
    endTime,
    selectedTimeZone,
    selectedRunStrategy,
  ]);

  useEffect(() => {
    if (existingBatchWindows) {
      let newSelectedWindowTypes = [];
      existingBatchWindows.forEach((item) => {
        newSelectedWindowTypes.push(item.window_type);
        setSelectedWindowTypes(newSelectedWindowTypes);
        setSelectedTimeZone(item.time_zone);
        setSelectedWindowTime(existingBatchWindows[0].days);
        setStartTime(
          parseFloat(
            existingBatchWindows[0].start_time.slice(0, 5).replace(":", "")
          )
        );
        setEndTime(
          parseFloat(
            existingBatchWindows[0].end_time.slice(0, 5).replace(":", "")
          )
        );
        setSelectedRunStrategy(existingBatchWindows[0].run_strategy);
      });
    }
  }, [existingBatchWindows]);

  const getSliderMarks = () => {
    const x = 60; //minutes interval
    const marks = [];
    let tt = 0; // start time
    let i = 0;

    //loop to increment the time and push results in array
    while (tt < 24 * 60) {
      const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      const mm = tt % 60; // getting minutes of the hour in 0-55 format
      marks[i] = {
        label: mm === 0 ? ("0" + hh).slice(-2) : "",
        value: parseFloat(("0" + hh).slice(-2) + ("0" + mm).slice(-2)),
      };
      tt = tt + x;
      i++;
    }
    return marks;
  };

  const handleWindowTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let changedWindow = event.target.value;
    let newWindowTypes = [...selectedWindowTypes];
    if (event.target.checked === true) {
      newWindowTypes.push(changedWindow);
      setSelectedWindowTypes(newWindowTypes);
    } else {
      setSelectedWindowTypes(
        newWindowTypes.filter((item) => item !== changedWindow)
      );
    }
  };

  const setExecutionParameters = () => {
    let executionContent = [];
    const existingWindowByType = {};
    existingBatchWindows.forEach((item) => {
      existingWindowByType[item.window_type] = item;
    });
    selectedWindowTypes.forEach((item) => {
      const existingWindow = existingWindowByType[item];
      if (existingWindow) {
        executionContent.push({
          ...existingWindow,
          start_time: startTime,
          end_time: endTime,
          time_zone: selectedTimeZone,
          run_strategy: selectedRunStrategy,
          days: selectedWindowTime,
        });
      }
    });
    Promise.all(executionContent.map((content) => updateBatchWindow(content)))
      .then()
      .catch();
  };

  return (
    <>
      <Container className={classes.container}>
        <Container style={{ padding: 0 }}>
          <Typography>Batch Modes</Typography>

          <TextField
            select
            required
            fullWidth
            label="Run Strategy"
            variant="outlined"
            size="small"
            margin="dense"
            value={selectedRunStrategy}
            onChange={(event) => setSelectedRunStrategy(event.target.value)}
          >
            {batchWindowConfig.runStrategies.map((runStrategy) => {
              return (
                <MenuItem key={runStrategy} value={runStrategy}>
                  {runStrategy}
                </MenuItem>
              );
            })}
          </TextField>
        </Container>
        <Container style={{ padding: 0 }}>
          <Typography>Batch Processes</Typography>
        </Container>
        <FormGroup row>
          {batchWindowConfig.windowTypes.map((windowType) => {
            return (
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={toReadableText(windowType)}
                key={windowType}
                value={windowType}
                checked={selectedWindowTypes.indexOf(windowType) > -1}
                onChange={handleWindowTypeChange}
              />
            );
          })}
        </FormGroup>
        <Container style={{ padding: 0 }}>
          <Typography>Batch Running Days</Typography>
        </Container>
        <RadioGroup
          value={selectedWindowTime}
          onChange={(event) => {
            setSelectedWindowTime(event.target.value);
          }}
          row
        >
          {batchWindowConfig.windowTimes.map((windowTimeType) => {
            return (
              <FormControlLabel
                control={<Radio color="primary" />}
                label={toReadableText(windowTimeType)}
                key={windowTimeType}
                value={windowTimeType}
              />
            );
          })}
        </RadioGroup>
        <Container style={{ padding: 0 }}>
          <Typography>Execution Times</Typography>

          <TextField
            select
            required
            fullWidth
            label="Time Zone"
            variant="outlined"
            size="small"
            margin="dense"
            disabled={existing === true}
            value={selectedTimeZone}
            onChange={(event) => setSelectedTimeZone(event.target.value)}
          >
            {batchWindowConfig.timeZones.map((timeZone) => {
              return (
                <MenuItem key={timeZone} value={timeZone}>
                  {timeZone}
                </MenuItem>
              );
            })}
          </TextField>
          <Slider
            marks={sliderMarks}
            step={100}
            max={2345}
            value={[startTime, endTime]}
            onChange={(_event, newValue: number[]) => {
              setStartTime(newValue[0]);
              setEndTime(newValue[1]);
            }}
          />
        </Container>
      </Container>
      {existing && (
        <Button onClick={() => setExecutionParameters()}>Apply</Button>
      )}
    </>
  );
};
