import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  FileUpload,
  getAllExistingUsers,
  shareFileWithUsers,
} from "../../api/useractions";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";

interface ShareFileProps {
  file: FileUpload;
  isOpen: boolean;
  setIsOpen(boolean): void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

/**
 * ShareFileModal Component
 *
 * A component to allow users to share a file with other users. It presents a modal dialog with
 * a list of available users, and allows selecting multiple users to share the file with.
 * Upon confirmation, the file is shared with the selected users, and a success snackbar is shown.
 *
 * @param {ShareFileProps} props - The component's props.
 * @returns {JSX.Element} - The rendered ShareFileModal component.
 */
export function ShareFileModal(props: Readonly<ShareFileProps>) {
  // Destructure props
  const { file, isOpen, setIsOpen } = props;

  // State to manage available and selected users
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Custom styles using material-ui hook
  const classes = useStyles();

  // Snackbar hook to display messages
  const { enqueueSnackbar } = useSnackbar();

  // Fetch all existing users from the server when the component mounts
  useEffect(() => {
    getAllExistingUsers()
      .then((users) => {
        setAvailableUsers(users);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    // Modal dialog to display the content
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Container maxWidth="sm">
        <Card className={classes.paper}>
          <CardHeader title="Share file" />
          <Divider />
          <CardContent>
            {/* Autocomplete component to select users */}
            <Autocomplete
              multiple
              data-testid={"autocomplete"}
              id="users-combo-box"
              options={availableUsers}
              getOptionLabel={(user) => `${user.user_name}(${user.full_name})`}
              renderInput={(params) => (
                <TextField {...params} label="Users" variant="outlined" />
              )}
              onChange={(_event, value) => setSelectedUsers(value)}
            />
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* Cancel button to close the modal */}
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            {/* Confirm button to share the file with selected users */}
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={!selectedUsers || selectedUsers.length === 0}
              onClick={() => {
                shareFileWithUsers(
                  file.id,
                  selectedUsers.map((user) => user.id)
                )
                  .then(() => {
                    setIsOpen(false);
                    enqueueSnackbar("File successfully shared!", {
                      variant: "success",
                    });
                  })
                  .catch((err) => console.log(err));
              }}
            >
              Confirm
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Modal>
  );
}
