import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useRef, useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { Link as RouterLink, NavLink, useNavigate } from "react-router-dom";

import { logout } from "../api/api";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../theme/theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import classes from "./MainNavbar.module.css";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FiberNewIcon from "@mui/icons-material/FiberNew";

export interface MainNavbarProps {
  onMobileNavOpen?: any;
  onLogout?: any;
  userProfile?: string;
  currentUser?: string;
}

/**
 * Nav Bar - the white bar at the top of all Cirrom pages
 * Limited functionality: settings, logout and current user button
 *
 */
export function MainNavbar(props: Readonly<MainNavbarProps>) {
  const { onMobileNavOpen, onLogout, userProfile, currentUser } = props;
  const navigate = useNavigate();

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const userMenuRef = useRef(undefined);
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);

  /**
   * Handle Logout
   */
  const handleLogout = () => {
    logout()
      .then(() => {
        onLogout();
        navigate("/");
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      style={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Container maxWidth={false} style={{ padding: 0 }}>
        <AppBar>
          <Toolbar variant="dense" style={{ height: "59px" }}>
            <RouterLink to="/"></RouterLink>
            <Box style={{ flexGrow: 1 }} />

            {userProfile && ["User", "New"].indexOf(userProfile) === -1 ? (
              !window.location.pathname.startsWith("/app/configure/") ? (
                <IconButton
                  color="inherit"
                  size="small"
                  className={classes.navButton}
                  component={NavLink}
                  to="/app/configure/users"
                  aria-label={"settingsPageButton"}
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  size="small"
                  className={classes.navButton}
                  component={NavLink}
                  to="/app/dashboard"
                >
                  <HomeOutlinedIcon />
                </IconButton>
              )
            ) : null}

            {userProfile && (
              <IconButton
                size="small"
                color="inherit"
                className={classes.navButton}
                onClick={onMobileNavOpen}
                style={{ display: lgUp ? "none" : "inherit" }}
              >
                <MenuIcon />
              </IconButton>
            )}

            {currentUser && (
              <IconButton
                className={classes.navButton}
                onClick={handleLogout}
                size="small"
                color="inherit"
              >
                <ExitToAppOutlinedIcon />
              </IconButton>
            )}

            {userProfile && (
              <Tooltip title={currentUser}>
                <span>
                  <IconButton
                    style={{ padding: 0 }}
                    size="small"
                    onClick={() => setShowUserMenu(!showUserMenu)}
                    ref={userMenuRef}
                  >
                    <Avatar className={classes.avatar}>
                      {currentUser
                        .split("@")[0]
                        ?.split(".")
                        .map((e) => e[0]?.toUpperCase())
                        .join("")}
                    </Avatar>
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
      </Container>
      {process.env.REACT_APP_WORKSPACE_ENABLED === "1" && userMenuRef?.current && (
        <Popper open={showUserMenu} anchorEl={userMenuRef.current}>
          <div
            style={{
              width: "200px",
              height: "75px",
              background: "white",
              marginTop: "14px",
              padding: "10px 5px",
              border: "2px solid #eeeeee",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div>
                <FiberNewIcon style={{ color: "#ffbf23" }} />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant={"h6"}
                  style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                >
                  Cirrom Workspace
                </Typography>
                <IconButton
                  size={"small"}
                  onClick={() =>
                    window.open("/workspace/cirrom-workspace.zip", "_blank")
                  }
                >
                  <FileDownloadIcon fontSize={"small"} />
                </IconButton>
              </div>
            </div>
          </div>
        </Popper>
      )}
    </Box>
  );
}
