import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./fonts/SpaceGrotesk-VariableFont_wght.ttf";
import "./fonts/Montserrat-VariableFont_wght.ttf";
import "./fonts/Proxima Nova Font.otf";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
