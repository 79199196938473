import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ViewPortContext } from "../../App";

/**
 * ScrollToTop Component
 *
 * Whenever we click on a subcomponent item within the single page application, the current scroll is maintained
 * So you can end up switching between Name Changes and Email changes and being taken directly to the grid
 * This component detects when a change in render paths has occurred ( via pathname ) and scrolls
 * back to the top of the page
 */
export const ScrollToTop = ({ children }) => {
  const viewPortProps = useContext(ViewPortContext);

  let pathname = useLocation();
  useEffect(() => {
    if (document?.getElementById("scroll-container")?.scroll) {
      document.getElementById("scroll-container").scroll(0, 0);
    }
  }, [pathname]);

  return <div style={{ width: viewPortProps.value }}>{children}</div>;
};
