import { API } from "./api";
import { Batch } from "./batch";
import { RuleGroup } from "./ruleengine";

export interface CirromExcelColumnInfo {
  name: string;
  exampleData: any[];
}

export interface CirromSheet {
  name: string;
  columnInfo: CirromExcelColumnInfo[];
}

export interface CirromExcelFile {
  FileID: number;
  SheetInfo: CirromSheet[];
}

export interface FileUpload {
  file_name: string;
  uploaded_datetime: Date;
  id: number;
  batch_group_id?: number;
}

export interface UploadedFileConfirmation {
  file: FileUpload;
  batch: Batch;
  integrated_system_id?: number;
}

export interface UserDetail {
  id: number;
  user_name: string;
  full_name: string;
  email: string;
  user_profile: string;
  can_change: boolean;
  can_upload_files: boolean;
}

export const uploadFile = async (file): Promise<CirromExcelFile> => {
  // Take a user input file and upload it to wherever cirrom is running
  let formData = new FormData();
  formData.append("file", file);
  const response = await API.post("/upload-file", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

export const getFilesUploadedByCurrentUser = async (): Promise<
  UploadedFileConfirmation[]
> => {
  const response = await API.get("/get-files-uploaded-for-user");
  return response.data;
};

export const getFileForSystemId = async (
  systemId: number
): Promise<FileUpload> => {
  const response = await API.get("/get-files-for-system-id", {
    params: { system_id: systemId },
  });
  return response.data;
};

export const completeFileSetup = async (
  fileId: number,
  sheetName: string,
  columnOverrides: object,
  ruleGroup?: RuleGroup
): Promise<string> => {
  const response = await API.post("/complete-file-setup", {
    file_id: fileId,
    sheet_name: sheetName,
    column_overrides: columnOverrides,
    rule_group: ruleGroup,
  });
  return response.data;
};

export const getProcessedFile = async (fileId: number): Promise<any> => {
  const response = API.get("/get-processed-file", {
    params: { file_id: fileId },
    responseType: "blob",
  });

  response
    .then((data) => {
      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(new Blob([data.data]));
      link.download = "file.xlsx";
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => console.log(err));
  return response;
};

export const getAllExistingUsers = async (): Promise<UserDetail[]> => {
  const response = API.get("/get-all-existing-users");
  return (await response).data;
};

export const getUsersFileUploadAccess = async (): Promise<boolean> => {
  const response = API.get("/get-users-access-to-upload");
  return (await response).data;
};

export const updateUserTypes = async (
  userIds: number[],
  userType: string
): Promise<any> => {
  const response = await API.post("/update-users-types", {
    user_type: userType,
    user_ids: userIds,
  });
  return response.data;
};

export const updateUserFileUploadAccess = async (
  userIds: number[],
  enable: boolean
): Promise<any> => {
  const response = await API.post("/update-users-upload-file-access", {
    enable: enable,
    user_ids: userIds,
  });
  return response.data;
};

export const updateUserDisabledLogin = async (
  userIds: number[],
  disabled: boolean
): Promise<any> => {
  const response = await API.post("/update-users-disabled-login", {
    disabled: disabled,
    user_ids: userIds,
  });
  return response.data;
};

/*
Update access to a system for a list of user ids
* */
export const updateUserSystemAccess = async (
  userIds: number[],
  systemId: number
): Promise<any> => {
  const response = await API.post("/update-users-system-access", {
    user_ids: userIds,
    system_id: systemId,
  });
  return response.data;
};

export const shareFileWithUsers = async (
  fileId: number,
  userIds: number[]
): Promise<any> => {
  const response = await API.post("/share-file-with-users", {
    file_id: fileId,
    user_ids: userIds,
  });
  return response.data;
};

export const deleteFile = async (fileId: number): Promise<any> => {
  const response = await API.delete("/delete-file", {
    params: {
      file_id: fileId,
    },
  });
  return response.data;
};

export const addContactIdsToList = async (
  systemId: number,
  contactIds: number[],
  listName: string
): Promise<any> => {
  const response = await API.post("/add-records-to-list", {
    integrated_system_id: systemId.toString(),
    list_name: listName,
    contact_ids: contactIds,
  });
  return response.data;
};

export const submitFeedback = async (feedback: string): Promise<any> => {
  const response = await API.post("/submit-cirrom-feedback", { feedback });
  return response.data;
};
