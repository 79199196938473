import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import React, { useContext, useEffect } from "react";
import { SidebarItem } from "./SidebarItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { IntegratedSystem } from "../../api/integratedsystems";
import Box from "@mui/material/Box";
import ListSubheader from "@mui/material/ListSubheader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../theme/theme";
import { CirromLogo, CirromLogoMin } from "../Logo";
import { useSystemOrProject } from "../integrated-systems/hooks";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import classes from "./Sidebar.module.css";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import TextField from "@mui/material/TextField";
import { SideBarWidthContext } from "../../App";

export interface SidebarProps {
  onMobileClose?: any;
  openMobile?: any;
  selectedSystemId: number;
  setSelectedSystemId: (number) => void;
  userSystems: IntegratedSystem[];
}

export function Sidebar(props: Readonly<SidebarProps>) {
  const sideBarWidth = useContext(SideBarWidthContext);
  const location = useLocation();
  const { onMobileClose, openMobile } = props;
  const navigate = useNavigate();

  const selectedUserSystem = useSystemOrProject(
    props.userSystems,
    props.selectedSystemId ? props.selectedSystemId.toString() : undefined
  );

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const drawerContent = (
    <>
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ marginLeft: "1px", marginTop: "10px" }}>
          <CirromLogo />
        </div>
      </Box>
      <Box
        style={{
          padding: "3px 10px 10px 10px",
          height: "100%",
        }}
      >
        <List style={{ paddingTop: "0px" }}>
          <SidebarItem isGroup={false} />
          <Divider style={{ backgroundColor: "white" }} />
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <ListSubheader className={classes.subHeader} disableSticky>
              CRM:
            </ListSubheader>
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                width: "100%",
                margin: "0px",
                padding: "0px 10px 0px 10px",
                background: "#FCE1AC",
                borderRadius: "8px",
              }}
              size="small"
              variant="standard"
            >
              <TextField
                value={selectedUserSystem?.id ?? ""}
                select
                onChange={(event) => {
                  props.setSelectedSystemId(event.target.value);
                  navigate(`/app/dashboard/${event.target.value}`);
                }}
                variant={"standard"}
                style={{ border: "none" }}
                InputProps={{ disableUnderline: true }}
              >
                {props.userSystems.map((userSystem) => (
                  <MenuItem
                    key={userSystem.id}
                    value={userSystem.id}
                    style={{ paddingBottom: "0px" }}
                  >
                    {userSystem.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <Divider className={classes.divider} />
          <ListItem className={classes.subItem}>
            <Link
              to={`/app/dashboard/${selectedUserSystem?.id ?? ""}`}
              className={classes.item}
            >
              <AssignmentOutlinedIcon fontSize={"small"} />
              <Typography variant={"h6"}>All Data</Typography>
            </Link>
          </ListItem>

          <ListItem className={classes.subItem}>
            <Link
              className={classes.item}
              to={`/app/data-projects/${selectedUserSystem?.id ?? ""}`}
            >
              <BallotOutlinedIcon fontSize={"small"} />
              <Typography variant={"h6"}>Data Projects</Typography>
            </Link>
          </ListItem>
          <Divider className={classes.divider} />

          {selectedUserSystem && (
            <SidebarItem userSystem={selectedUserSystem} isGroup={true} />
          )}

          <Divider className={classes.divider} />
          <ListSubheader className={classes.subHeader} disableSticky>
            Uploaded Lists
          </ListSubheader>
          <ListItem className={classes.subItem}>
            <Link
              to={{ pathname: "/app/my-data" }}
              className={
                sideBarWidth.value === "small" ? classes.itemMin : classes.item
              }
              data-testid={"name_btn"}
            >
              <PersonOutlinedIcon fontSize={"small"} />
              {sideBarWidth.value === "full" && (
                <Typography variant={"h6"}>My Data</Typography>
              )}
              {sideBarWidth.value === "small" && (
                <Typography variant={"caption"}>My Data</Typography>
              )}
            </Link>
          </ListItem>
        </List>
      </Box>

      <div
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column-reverse",
          alignItems: "end",
        }}
      >
        <IconButton
          onClick={() => sideBarWidth.toggleValue()}
          style={{ color: "#333333" }}
          disableRipple
        >
          <KeyboardDoubleArrowLeftIcon fontSize={"small"} />
        </IconButton>
      </div>
    </>
  );
  return (
    <>
      <Drawer
        anchor="left"
        variant="temporary"
        open={openMobile}
        onClose={onMobileClose}
        PaperProps={{
          style: { width: 240, height: "100%" },
        }}
        style={{ display: !lgUp ? "inherit" : "none" }}
      >
        {drawerContent}
      </Drawer>

      <Drawer
        anchor="left"
        variant="persistent"
        open={sideBarWidth.value === "full"}
        PaperProps={{
          style: { width: 240, height: "100%" },
        }}
        style={{ display: lgUp ? "inherit" : "none" }}
      >
        {drawerContent}
      </Drawer>

      <Drawer
        anchor="left"
        variant="persistent"
        open={sideBarWidth.value === "small"}
        PaperProps={{
          style: { width: 52, height: "100%" },
        }}
        style={{ display: lgUp ? "inherit" : "none" }}
      >
        <>
          <Box
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CirromLogoMin />
          </Box>
          <Box
            style={{
              padding: "0.25rem",
              height: "100%",
            }}
          >
            <Divider style={{ backgroundColor: "white" }} />
            <List style={{ marginTop: "20px" }}>
              <SidebarItem isGroup={false} />
              <Divider style={{ backgroundColor: "white" }} />

              <Divider className={classes.divider} />
              <ListItem className={classes.subItem}>
                <Link
                  to={`/app/dashboard/${selectedUserSystem?.id ?? ""}`}
                  className={classes.itemMin}
                >
                  <AssignmentOutlinedIcon fontSize={"small"} />
                  <Typography variant={"caption"}>CRM</Typography>
                </Link>
              </ListItem>

              <ListItem className={classes.subItem}>
                <Link
                  className={classes.itemMin}
                  to={`/app/data-projects/${selectedUserSystem?.id ?? ""}`}
                >
                  <BallotOutlinedIcon fontSize={"small"} />
                  <Typography variant={"caption"}>Projects</Typography>
                </Link>
              </ListItem>
              <Divider className={classes.divider} />

              {selectedUserSystem && (
                <SidebarItem userSystem={selectedUserSystem} isGroup={true} />
              )}
            </List>
          </Box>

          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column-reverse",
            }}
          >
            <IconButton
              onClick={() => sideBarWidth.toggleValue()}
              style={{ color: "black" }}
              disableRipple
            >
              <KeyboardDoubleArrowRightIcon fontSize={"small"} />
            </IconButton>
          </div>
        </>
      </Drawer>
    </>
  );
}
