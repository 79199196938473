export const WEBSOCKETS_URL = `${process.env.REACT_APP_CIRROM_WS}`;

class WebSocketConnections {
  private static _availableConnections: any;
  static _instance: any;

  private constructor() {}

  public static getInstance() {
    if (!WebSocketConnections._instance) {
      this._availableConnections = {};
      WebSocketConnections._instance = this;
    }
    return WebSocketConnections._instance;
  }

  public static connect(websocketUrl) {
    if (
      !this._availableConnections[websocketUrl] ||
      this._availableConnections[websocketUrl].readyState === WebSocket.CLOSED
    ) {
      this._availableConnections[websocketUrl] = new WebSocket(websocketUrl);
    }
    return this._availableConnections[websocketUrl];
  }
}

export const getFilesWebSocketConnection = () => {
  return WebSocketConnections.getInstance().connect(
    `${WEBSOCKETS_URL}ws-files`
  );
};

export const getBatchWebSocketConnection = () => {
  return WebSocketConnections.getInstance().connect(
    `${WEBSOCKETS_URL}ws-batches`
  );
};

export const getSuggestedChangesWebSocketConnection = (batchId: number) => {
  return WebSocketConnections.getInstance().connect(
    `${WEBSOCKETS_URL}ws-suggested-changes/${batchId}`
  );
};

export const getRuleEvaluationWebSocketConnection = (
  batchId: number,
  ruleId: number
) => {
  return new WebSocket(
    `${WEBSOCKETS_URL}ws-rule-evaluation/${batchId}/${ruleId}`
  );
};
