import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

import GetAppIcon from "@mui/icons-material/GetApp";
import BlurOnOutlinedIcon from "@mui/icons-material/BlurOnOutlined";
import React, { useEffect, useState } from "react";
import {
  addContactIdsToList,
  getFileForSystemId,
  getProcessedFile,
} from "../../api/useractions";
import {
  rollbackSuggestedChange,
  transitionSuggestedChange,
} from "../../api/suggestedchanges";

import Button from "@mui/material/Button";

import { ChickenOutModal } from "../utils/ChickenOutModal";
import { red, teal } from "@mui/material/colors";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import CardActionArea from "@mui/material/CardActionArea";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import { IntegratedSystem } from "../../api/integratedsystems";
import { isEmpty } from "lodash";
import DownloadIcon from "@mui/icons-material/Download";
import { DataProject } from "../../api/dataprojects";

/**
 * callFunctionForSelectedNode Function
 *
 * This function executes a specified function for each selected node in the grid.
 *
 * @param {any} gridApi - The grid API for interacting with the grid.
 * @param {function} functionToCall - The function to call for each selected node.
 * @param {Array} functionArgs - Arguments to pass to the functionToCall.
 * @param {function} [onGridActionComplete] - Callback function to be executed after grid action.
 */
const callFunctionForSelectedNode = async (
  gridApi,
  functionToCall,
  functionArgs,
  onGridActionComplete?
) => {
  if (gridApi.getSelectedNodes().length > 0) {
    gridApi.showLoadingOverlay();
    const nodeIds = [];
    // Build an array of selected node IDs

    gridApi.getSelectedNodes().map(async (node) => {
      nodeIds.push(node.data.id);
    });
    // Call the specified function for selected nodes

    await functionToCall(nodeIds, ...functionArgs);
    // Deselect all nodes and hide the overlay

    gridApi.deselectAll();
    gridApi.hideOverlay();
    // Execute onGridActionComplete callback if provided

    if (onGridActionComplete !== undefined) {
      onGridActionComplete(nodeIds);
    }
  }
};

/**
 * AddToMarketingCampaignButton Component
 *
 * This component provides a button that opens a modal to add selected items to a marketing list.
 *
 * @param {object} props - Component props.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @param {function} [props.onGridActionComplete] - Callback triggered when the grid action is complete.
 * @param {IntegratedSystem|DataProject} props.integratedSystem - Integrated system or data project information.
 * @returns {JSX.Element} - Rendered component.
 */
export const AddToMarketingCampaignButton = (
  props: Readonly<{
    gridApi: any;
    rowsSelected: number;
    onGridActionComplete?(): void;
    integratedSystem: IntegratedSystem | DataProject;
  }>
) => {
  const { rowsSelected, gridApi, integratedSystem } = props;
  // State for controlling modal visibility and marketing list name
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [marketingListName, setMarketingListName] = useState<string>("");

  return (
    <>
      {/* Modal for adding to marketing list */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm" style={{ padding: 0 }}>
          <Card style={{ padding: "16px" }}>
            <CardHeader
              title={"Add to marketing list"}
              titleTypographyProps={{ variant: "h3" }}
              action={
                <IconButton onClick={() => setModalOpen(false)}>
                  <CloseOutlinedIcon />
                </IconButton>
              }
            />
            <Divider />
            <CardContent>
              {/* Input for new marketing list name */}
              <TextField
                data-testid={"new-marketing-list"}
                required
                fullWidth
                variant="outlined"
                size="small"
                margin="dense"
                label={"New marketing list name"}
                value={marketingListName}
                onChange={(event) => {
                  setMarketingListName(event.target.value);
                }}
              />
              <Divider />
            </CardContent>
            <CardActionArea
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* Button to add selected items to marketing list */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "2px", padding: "0em 0.5em" }}
                disabled={!marketingListName}
                onClick={() => {
                  const record_ids = [];
                  // Collect CRM contact IDs of selected nodes
                  gridApi.getSelectedNodes().forEach((node) => {
                    if (node.data.crm_contact_id) {
                      record_ids.push(node.data.crm_contact_id);
                    }
                  });
                  // Call API to add contact IDs to the marketing list
                  addContactIdsToList(
                    integratedSystem.hasOwnProperty("integrated_system_id")
                      ? //@ts-ignore
                        integratedSystem.integrated_system_id
                      : integratedSystem.id,
                    record_ids,
                    marketingListName
                  )
                    .then((response) => setModalOpen(false))
                    .catch((err) => console.log(err));
                }}
              >
                Add to list
              </Button>
            </CardActionArea>
          </Card>
        </Container>
      </Modal>
      {/* Button to open the modal */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<PlaylistAddCheckOutlinedIcon />}
        size="small"
        style={{ margin: "2px", padding: "0em 0.5em" }}
        onClick={() => setModalOpen(true)}
        disabled={rowsSelected === 0}
      >
        Add to list
      </Button>
    </>
  );
};

/**
 * ApproveChangesButton Component
 *
 * This component provides a button to approve selected suggested changes in the grid.
 *
 * @param {object} props - Component props.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @param {function} [props.onGridActionComplete] - Callback triggered when the grid action is complete.
 * @returns {JSX.Element} - Rendered component.
 */
const ApproveChangesButton = (
  props: Readonly<{
    gridApi: any;
    rowsSelected: number;
    onGridActionComplete?(nodeIds: number[]): void;
  }>
) => {
  const { gridApi, rowsSelected, onGridActionComplete } = props;

  /**
   * Approve suggested changes for selected nodes.
   */
  const approveSuggestedChanges = () => {
    callFunctionForSelectedNode(
      gridApi,
      transitionSuggestedChange,
      [],
      onGridActionComplete
    )
      .then(() => {})
      .catch((err) => console.log(err));
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={
        <DoneAllOutlinedIcon
          style={{ color: rowsSelected === 0 ? "" : teal["600"] }}
        />
      }
      size="small"
      style={{ margin: "2px", padding: "0em 0.5em" }}
      onClick={approveSuggestedChanges}
      disabled={rowsSelected === 0}
    >
      {`Approve(${rowsSelected})`}
    </Button>
  );
};

/**
 * RevertChangesButton Component
 *
 * This component provides a button to revert selected suggested changes in the grid.
 *
 * @param {object} props - Component props.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @param {function} [props.onGridActionComplete] - Callback triggered when the grid action is complete.
 * @returns {JSX.Element} - Rendered component.
 */
const RevertChangesButton = (
  props: Readonly<{
    gridApi: any;
    rowsSelected: number;
    onGridActionComplete?(nodeIds: number[]): void;
  }>
) => {
  const { gridApi, rowsSelected, onGridActionComplete } = props;

  /**
   * Revert suggested changes for selected nodes.
   */

  const revertSuggestedChanges = async () => {
    const nodeIds = [];
    gridApi.getSelectedNodes().map(async (node) => {
      nodeIds.push(node.data.id);
    });
    gridApi.showLoadingOverlay();
    Promise.all(nodeIds.map((node) => rollbackSuggestedChange(node))).then(
      (responses) => {
        gridApi.deselectAll();
        gridApi.hideOverlay();
        onGridActionComplete(nodeIds);
      }
    );
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={
        <ReplayOutlinedIcon
          style={{ color: rowsSelected === 0 ? "" : teal["600"] }}
        />
      }
      size="small"
      style={{ margin: "2px", padding: "0em 0.5em" }}
      onClick={revertSuggestedChanges}
      disabled={rowsSelected === 0}
    >
      Revert
    </Button>
  );
};

/**
 * RejectChangesButton Component
 *
 * This component provides a button to reject selected suggested changes in the grid.
 *
 * @param {object} props - Component props.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @param {function} [props.onGridActionComplete] - Callback triggered when the grid action is complete.
 * @returns {JSX.Element} - Rendered component.
 */
const RejectChangesButton = (
  props: Readonly<{
    gridApi: any;
    rowsSelected: number;
    onGridActionComplete?(nodeIds): void;
  }>
) => {
  const { gridApi, rowsSelected, onGridActionComplete } = props;
  /**
   * Reject suggested changes for selected nodes.
   */
  const rejectSuggestedChanges = async () => {
    callFunctionForSelectedNode(
      gridApi,
      transitionSuggestedChange,
      [true],
      onGridActionComplete
    )
      .then(() => {})
      .catch((err) => console.log(err));
  };

  const [chickenOutOpen, setChickenOutOpen] = useState<boolean>(false);
  return (
    <>
      {/* Reject button */}
      <Button
        variant="contained"
        color="primary"
        startIcon={
          <ClearOutlinedIcon
            style={{ color: rowsSelected === 0 ? "" : red["A400"] }}
          />
        }
        size="small"
        style={{ margin: "2px", padding: "0em 0.5em" }}
        onClick={() => setChickenOutOpen(true)}
        disabled={rowsSelected === 0}
      >
        {`Reject(${rowsSelected})`}
      </Button>
      {/* Chicken Out Modal */}

      <ChickenOutModal
        modalOpen={chickenOutOpen}
        title={"Are you sure you want to reject the change(s)?"}
        message={"Cirrom will not suggest this change anymore."}
        onModalOpenChange={setChickenOutOpen}
        onSuccess={() => {
          rejectSuggestedChanges()
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        }}
      />
    </>
  );
};

/**
 * DetailsButton Component
 *
 * This component provides a button to view details of a selected row in the grid.
 *
 * @param {object} props - Component props.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @returns {JSX.Element} - Rendered component.
 */
export const DetailsButton = (
  props: Readonly<{ gridApi: any; rowsSelected: number }>
) => {
  const { gridApi, rowsSelected } = props;

  /**
   * Handle button click to view details of a selected row.
   */
  const handleButtonClick = () => {
    if (gridApi.getSelectedNodes().length === 1) {
      gridApi.dispatchEvent({
        type: "rowDoubleClicked",
        node: gridApi.getSelectedNodes()[0],
      });
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<BlurOnOutlinedIcon />}
      size="small"
      style={{ margin: "2px", padding: "0em 0.5em" }}
      disabled={rowsSelected !== 1}
      onClick={handleButtonClick}
    >
      Details
    </Button>
  );
};

/**
 * ExportButton Component
 *
 * This component provides a button to initiate the export of a processed file.
 *
 * @param {object} props - Component props.
 * @param {number} props.fileId - The ID of the file to be exported.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @returns {JSX.Element} - Rendered component.
 */
const ExportButton = (props: Readonly<{ fileId: number; gridApi: any }>) => {
  const { fileId, gridApi } = props;

  /**
   * Initiates the export of the processed file.
   * Shows loading overlay while exporting.
   */
  const exportFile = () => {
    gridApi.showLoadingOverlay();
    getProcessedFile(fileId)
      .then(
        () => gridApi.hideOverlay(),
        () => gridApi.hideOverlay()
      )
      .catch((err) => console.log(err));
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<GetAppIcon />}
      size="small"
      style={{ margin: "2px", padding: "0em 0.5em" }}
      onClick={exportFile}
    >
      Export
    </Button>
  );
};

/**
 * SuggestedChangesRevertToolbar Component
 *
 * This component represents a toolbar for reverting suggested changes in a grid.
 *
 * @param {object} props - Component props.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {function} [props.onGridActionComplete] - Callback function to be executed after grid action.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @returns {JSX.Element} - Rendered component.
 */
export const SuggestedChangesRevertToolbar = (
  props: Readonly<{
    gridApi: any;
    onGridActionComplete?(): void;
    rowsSelected: number;
  }>
) => {
  const { gridApi, rowsSelected, onGridActionComplete } = props;
  return (
    <div style={{ display: "inline-flex" }}>
      {/* DetailsButton component */}
      <DetailsButton rowsSelected={rowsSelected} gridApi={gridApi} />

      {/* RevertChangesButton component */}
      <RevertChangesButton
        gridApi={gridApi}
        rowsSelected={rowsSelected}
        onGridActionComplete={onGridActionComplete}
      />
    </div>
  );
};

/**
 * SuggestedChangesButtonsToolbar Component
 *
 * This component represents a toolbar for various actions related to suggested changes in a grid.
 *
 * @param {object} props - Component props.
 * @param {number} [props.fileId] - The ID of the file associated with the grid.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {function} [props.onGridActionComplete] - Callback function to be executed after grid action.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @param {IntegratedSystem | DataProject} [props.integratedSystem] - Integrated system or data project information.
 * @returns {JSX.Element} - Rendered component.
 */
export const SuggestedChangesButtonsToolbar = (
  props: Readonly<{
    fileId?: number;
    gridApi: any;
    onGridActionComplete?(nodeIds: number[]): void;
    rowsSelected: number;
    integratedSystem?: IntegratedSystem | DataProject;
  }>
) => {
  const {
    fileId,
    gridApi,
    rowsSelected,
    onGridActionComplete,
    integratedSystem,
  } = props;

  const [localFileId, setLocalFileId] = useState<number>(undefined);

  useEffect(() => {
    // Fetch the file associated with the integrated system if not provided

    if (
      !fileId &&
      integratedSystem &&
      "type" in integratedSystem &&
      integratedSystem?.type?.indexOf("CRM") === -1
    ) {
      getFileForSystemId(integratedSystem.id)
        .then((fileResponse) => {
          if (fileResponse) {
            setLocalFileId(fileResponse.id);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [fileId, integratedSystem]);

  return (
    <div style={{ display: "inline-flex" }}>
      {/* DetailsButton component */}
      <DetailsButton rowsSelected={rowsSelected} gridApi={gridApi} />

      {/* ApproveChangesButton component */}
      <ApproveChangesButton
        gridApi={gridApi}
        rowsSelected={rowsSelected}
        onGridActionComplete={onGridActionComplete}
      />
      {/* RejectChangesButton component */}
      <RejectChangesButton
        gridApi={gridApi}
        rowsSelected={rowsSelected}
        onGridActionComplete={onGridActionComplete}
      />
      {/* Conditionally render ExportButton based on file and integrated system */}
      {fileId ||
      (integratedSystem && integratedSystem.system_type === "EXCEL") ? (
        <ExportButton fileId={fileId || localFileId} gridApi={gridApi} />
      ) : null}
      {integratedSystem && integratedSystem.system_type === "INTERACTION" && (
        <AddToMarketingCampaignButton
          gridApi={gridApi}
          rowsSelected={rowsSelected}
          integratedSystem={integratedSystem}
        />
      )}
    </div>
  );
};

/**
 * RuleEvaluationButtonsToolbar Component
 *
 * This component represents a toolbar for various actions related to rule evaluation in a grid.
 *
 * @param {object} props - Component props.
 * @param {any} props.gridApi - The grid API for interacting with the grid.
 * @param {function} [props.onGridActionComplete] - Callback function to be executed after grid action.
 * @param {number} props.rowsSelected - The number of selected rows in the grid.
 * @param {IntegratedSystem} [props.integratedSystem] - Integrated system information.
 * @returns {JSX.Element} - Rendered component.
 */
export const RuleEvaluationButtonsToolbar = (
  props: Readonly<{
    gridApi: any;
    onGridActionComplete?(nodeIds): void;
    rowsSelected: number;
    integratedSystem?: IntegratedSystem;
  }>
) => {
  const { gridApi, rowsSelected, onGridActionComplete, integratedSystem } =
    props;

  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      {/* DetailsButton component */}
      <DetailsButton rowsSelected={rowsSelected} gridApi={gridApi} />
      {/* RejectChangesButton component */}
      <RejectChangesButton
        gridApi={gridApi}
        rowsSelected={rowsSelected}
        onGridActionComplete={onGridActionComplete}
      />

      {integratedSystem && integratedSystem.system_type === "INTERACTION" && (
        <AddToMarketingCampaignButton
          gridApi={gridApi}
          rowsSelected={rowsSelected}
          integratedSystem={integratedSystem}
        />
      )}
      {/* Conditionally render AddToMarketingCampaignButton for INTERACTION system */}
      {!isEmpty(gridApi) && (
        <IconButton
          color={"info"}
          component={"span"}
          onClick={() => {
            gridApi.exportDataAsCsv();
          }}
          style={{ height: "20px", width: "20px" }}
        >
          <DownloadIcon />
        </IconButton>
      )}
    </div>
  );
};
