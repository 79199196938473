import React, { useEffect, useState } from "react";
import { FileUploadModal } from "../components/uploaded-files/FileUploadModal";
import { PageTitle } from "../components/utils/PageTitle";
import { ShareFileModal } from "../components/uploaded-files/ShareFileModal";
import {
  categorizeSuggestedChanges,
  getSuggestedChangesColumnData,
  getSuggestedChangesStats,
} from "../components/suggested-changes/utils";
import { toReadableText } from "../components/utils/utils";

import { UploadedFileChartSection } from "../components/uploaded-files/UploadedFileChartSection";
import { UploadedFilesTable } from "../components/uploaded-files/UploadedFilesTable";
import { useNavigate, useParams } from "react-router-dom";

import { getSuggestedChangesForSystem } from "../api/suggestedchanges";
import {
  getBatchWebSocketConnection,
  getFilesWebSocketConnection,
  getSuggestedChangesWebSocketConnection,
} from "../api/websockets";

import {
  getFilesUploadedByCurrentUser,
  getUsersFileUploadAccess,
  UploadedFileConfirmation,
} from "../api/useractions";
import Alert from "@mui/material/Alert";
import { SuggestedChangesGrid } from "../components/suggested-changes/SuggestedChangesGrid";
import { SuggestedChangesButtonsToolbar } from "../components/suggested-changes/GridButtonsToolbar";
import { SuggestedChangesGridTabs } from "../components/suggested-changes/SuggestedChangesGridTabs";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

export const UploadedFiles = () => {
  const [uploadedFiles, setUploadedFiles] = useState<
    UploadedFileConfirmation[]
  >([]);
  const [canUploadFiles, setCanUploadFiles] = useState<boolean>(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);

  let { id } = useParams();
  const [showShareFileModal, setShowShareFileModal] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState(null);

  const [fileWsConnection, setFileWsConnection] = useState(undefined);
  const [batchWsConnection, setBatchWsConnection] = useState(undefined);
  const [suggestedChangesWsConnection, setSuggestedChangesWsConnection] =
    useState(undefined);

  const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);
  const navigate = useNavigate();

  const [streamingChartDatas, setStreamingChartDatas] = useState<
    {
      chartType: string;
      changes: any[];
    }[]
  >([]);
  const [changesChartData, setChangesChartData] = useState({
    labels: [],
    data: [],
  });
  const [blanksChartData, setBlanksChartData] = useState({
    labels: [],
    data: [],
  });
  const [suggestedFixesChartData, setSuggestedFixesChartData] = useState({
    labels: [],
    data: [],
  });
  const [selectedFile, setSelectedFile] =
    useState<UploadedFileConfirmation>(undefined);
  const [selectedFileTrigger, setSelectedFileTrigger] = useState<number>(0);
  const [suggestedChanges, setSuggestedChanges] = useState({
    rows: [],
    columns: [],
  });
  const [allPendingChanges, setAllPendingChanges] = useState<number>(0);
  const [allActionedChanges, setAllActionedChanges] = useState<number>(0);
  const [fillInBlankChanges, setFillInBlankChanges] = useState<number>(0);
  const [correctionChanges, setCorrectionChanges] = useState<number>(0);
  const [invalidDataChanges, setInvalidDataChanges] = useState<number>(0);
  const [gridFilterModel, setGridFilterModel] = useState<any>(undefined);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      let currentFilters = gridApi.getFilterModel();
      delete currentFilters["change_type"];
      delete currentFilters["change_status"];
      gridApi.setFilterModel({ ...currentFilters, ...gridFilterModel });
    }
  }, [gridFilterModel]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getUsersFileUploadAccess()
        .then((response) => {
          setCanUploadFiles(response);
        })
        .catch((err) => console.log(err));
      getFilesUploadedByCurrentUser()
        .then((response) => {
          setUploadedFiles(response);
          response.forEach((item) => {
            if (item.file.id === parseInt(id) && isMounted) {
              setSelectedFile(item);
            }
          });
        })
        .catch((err) => console.log(err));
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    setFileWsConnection(getFilesWebSocketConnection());
    setBatchWsConnection(getBatchWebSocketConnection());
    return () => {
      if (fileWsConnection && batchWsConnection) {
        fileWsConnection.close();
        batchWsConnection.close();
      }
    };
  }, [fileWsConnection, batchWsConnection]);

  useEffect(() => {
    if (fileWsConnection && batchWsConnection) {
      fileWsConnection.onmessage = handleFileWebSocket;
      batchWsConnection.onmessage = handleBatchWebSocket;
    }
  }, [uploadedFiles, selectedFile]); // hidden dependency - although not clear, the underlying on message functions use these variables in their calculations

  const handleFileWebSocket = (wsEvent) => {
    let incomingFiles = JSON.parse(wsEvent.data);
    if (incomingFiles && incomingFiles.length > 0) {
      let newUploadedFiles = [...uploadedFiles];
      incomingFiles.forEach((incomingFile) => {
        let found = false;
        let deletedItems = [];
        newUploadedFiles.forEach((uploadedFile, index) => {
          if (uploadedFile && uploadedFile.file.id === incomingFile.id) {
            found = true;
            uploadedFile.file = incomingFile;
            if (incomingFile.__deleted__) {
              deletedItems.push(index);
            }
          }
        });
        if (!found) {
          newUploadedFiles = [
            {
              file: incomingFile,
              batch: {
                id: null,
                batch_group_id: null,
                status: "NEW",
                data_loader_config_revision_id: null,
              },
            },
            ...newUploadedFiles,
          ];
        }
        if (deletedItems.length > 0) {
          deletedItems.forEach((itemIndex, index) => {
            newUploadedFiles.splice(itemIndex - index, 1);
          });
        }
      });
      setUploadedFiles(newUploadedFiles);
    }
  };

  const handleBatchWebSocket = (wsEvent) => {
    let incomingBatches = JSON.parse(wsEvent.data);
    if (incomingBatches && incomingBatches.length > 0) {
      let newUploadedFiles = [...uploadedFiles];

      incomingBatches.forEach((incomingBatch) => {
        newUploadedFiles.forEach((uploadedFile) => {
          if (
            uploadedFile &&
            uploadedFile.file.batch_group_id === incomingBatch.batch_group_id
          ) {
            uploadedFile.batch = incomingBatch;
            if (selectedFile && uploadedFile.file.id === selectedFile.file.id) {
              setSelectedFile((prevSelected) => {
                return { ...prevSelected, batch: incomingBatch };
              });
            }
          }
        });
      });

      setUploadedFiles(newUploadedFiles);
    }
  };

  const handleSuggestedChangeWebSocket = (wsEvent) => {
    let incomingSuggestedChanges = JSON.parse(wsEvent.data);

    if (
      gridApi &&
      incomingSuggestedChanges &&
      incomingSuggestedChanges.length > 0
    ) {
      if (gridApi.getColumnDefs().length === 0) {
        setSuggestedChanges({
          rows: [],
          columns: getSuggestedChangesColumnData(incomingSuggestedChanges[0]),
        });
      }
      let add = [];
      let update = [];
      incomingSuggestedChanges.forEach((change) => {
        if (gridApi) {
          if (gridApi.getRowNode(change.id)) {
            update.push(change);
          } else {
            add.push(change);
          }
        }
      });
      gridApi.applyTransaction({ add: add, update: update });
      let chartDatas = [];
      incomingSuggestedChanges.forEach((change) => {
        chartDatas.push({
          chartType: change.change_type,
          field: change.field,
        });
      });
      setStreamingChartDatas(chartDatas);
    }
  };

  const mergeExistingArray = (existingArray, incomingData) => {
    const label = toReadableText(incomingData.field);
    const existingItemIndex = existingArray.labels.indexOf(label);
    if (existingItemIndex > -1) {
      existingArray.data[existingItemIndex] =
        existingArray.data[existingItemIndex] + 1;
    } else {
      existingArray.data.push(1);
      existingArray.labels.push(label);
    }

    return existingArray;
  };

  useEffect(() => {
    if (streamingChartDatas && streamingChartDatas.length > 0) {
      let changesData = { ...changesChartData };
      let blanksData = { ...blanksChartData };
      let suggestedFixesData = { ...suggestedFixesChartData };
      streamingChartDatas.forEach((streamingChartData) => {
        if (streamingChartData.chartType === "FILL_IN_BLANK") {
          blanksData = mergeExistingArray(blanksData, streamingChartData);
        }
        if (streamingChartData.chartType === "CORRECTION") {
          suggestedFixesData = mergeExistingArray(
            suggestedFixesChartData,
            streamingChartData
          );
        }
        changesData = mergeExistingArray(changesData, {
          field: streamingChartData.chartType,
        });
      });

      setStreamingChartDatas([]);
      setSuggestedFixesChartData(suggestedFixesData);
      setBlanksChartData(blanksData);
      setChangesChartData(changesData);
    }
  }, [
    streamingChartDatas,
    changesChartData,
    suggestedFixesChartData,
    blanksChartData,
  ]);

  useEffect(() => {
    if (suggestedChangesWsConnection) {
      suggestedChangesWsConnection.onmessage = handleSuggestedChangeWebSocket;
    }
    return () => {
      if (suggestedChangesWsConnection && gridApi) {
        suggestedChangesWsConnection.close();
      }
    };
  }, [suggestedChangesWsConnection, gridApi]);

  useEffect(() => {
    if (selectedFile?.batch?.id) {
      setSuggestedChangesWsConnection(
        getSuggestedChangesWebSocketConnection(selectedFile.batch.id)
      );
      getSuggestedChangesForSystem(selectedFile.integrated_system_id)
        .then((response) => {
          if (response !== undefined && response.length > 0) {
            let computedColumns: any[] = getSuggestedChangesColumnData(
              response[0]
            );
            const changesStats = getSuggestedChangesStats(response);
            setBlanksChartData(changesStats[0]);

            setSuggestedFixesChartData(changesStats[1]);

            setChangesChartData(changesStats[2]);
            let categorizedChanges = categorizeSuggestedChanges({
              rows: response,
            });
            setAllPendingChanges(categorizedChanges[0]);
            setFillInBlankChanges(categorizedChanges[1]);
            setCorrectionChanges(categorizedChanges[2]);
            setInvalidDataChanges(categorizedChanges[3]);
            setAllActionedChanges(categorizedChanges[4]);
            setSuggestedChanges({ rows: response, columns: computedColumns });
          }
          if (gridApi) {
            gridApi.hideOverlay();
          }
        })
        .catch((err) => console.log(err));
    } else {
      setSuggestedChanges({ rows: [], columns: [] });
      setBlanksChartData({ labels: [], data: [] });
      setChangesChartData({ labels: [], data: [] });
      setSuggestedFixesChartData({ labels: [], data: [] });
    }
  }, [selectedFile, selectedFileTrigger, gridApi]);

  return (
    <PageTitle pageTitle="My Data | CIRROM" pageHeader="My Data">
      <Container maxWidth={false}>
        <Grid container spacing={1}>
          <Grid item lg={9} sm={12} xl={9} xs={12}>
            <Card style={{ height: "100%" }}>
              <CardHeader
                title="My Uploads"
                titleTypographyProps={{ color: "textSecondary" }}
              />
              <Divider />
              <CardContent style={{ display: "flex", height: "100%" }}>
                <UploadedFilesTable
                  uploadedFiles={uploadedFiles}
                  setShareFileModal={setShowShareFileModal}
                  showGridToolbar={true}
                  enableCheckBoxSelection={true}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} sm={12} xl={3} xs={12}>
            <Card style={{ height: "100%" }}>
              <CardHeader
                title="Upload a new file"
                titleTypographyProps={{ color: "textSecondary" }}
              />
              <Divider />
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "95%",
                  justifyContent: "center",
                }}
              >
                {canUploadFiles ? (
                  <FileUploadModal
                    modalOpen={false}
                    onUploadInitiated={() => setIsUploadInProgress(true)}
                    onUploadConfirmed={(fileId) => {
                      setIsUploadInProgress(false);
                      navigate(`/app/my-data/${fileId}`);
                    }}
                    onUploadCancelled={() => setIsUploadInProgress(false)}
                  />
                ) : (
                  <Typography variant="h6">
                    You are not able to upload files.
                    <br /> please contact your Cirrom admin to get access
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          {isUploadInProgress && (
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div>
                <Alert
                  severity="warning"
                  style={{ padding: 0 }}
                >{`New Upload In Progress...`}</Alert>
                <LinearProgress />
              </div>
            </Grid>
          )}
          {selectedFile ? (
            <>
              {selectedFile?.batch?.status === "BATCH_RUNNING" && (
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <div>
                    <Alert
                      severity="warning"
                      style={{ padding: 0 }}
                    >{`In Progress: ${selectedFile.file.file_name}`}</Alert>
                    <LinearProgress />
                  </div>
                </Grid>
              )}

              <ShareFileModal
                file={selectedFile.file}
                isOpen={showShareFileModal}
                setIsOpen={setShowShareFileModal}
              />
              <UploadedFileChartSection
                blanksChartData={blanksChartData}
                changesChartData={changesChartData}
                suggestedFixesChartData={suggestedFixesChartData}
              />
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Card>
                  <CardHeader
                    title="Suggested Changes"
                    titleTypographyProps={{ color: "textSecondary" }}
                  />
                  <Divider />
                  <CardContent>
                    <SuggestedChangesGridTabs
                      allPending={allPendingChanges}
                      allActioned={allActionedChanges}
                      correction={correctionChanges}
                      invalidData={invalidDataChanges}
                      fillInBlank={fillInBlankChanges}
                      onSelectionChanged={setGridFilterModel}
                    />
                    <SuggestedChangesButtonsToolbar
                      fileId={selectedFile.file.id}
                      gridApi={gridApi}
                      rowsSelected={numberOfSelectedRows}
                      onGridActionComplete={() =>
                        setSelectedFileTrigger(Math.random())
                      }
                    />
                    <div
                      style={{
                        height: (window.outerHeight * 0.8).toString() + "px",
                      }}
                      className="ag-theme-material"
                    >
                      <SuggestedChangesGrid
                        onGridReady={onGridReady}
                        suggestedChanges={suggestedChanges}
                        onSelectionChanged={(event) => {
                          setNumberOfSelectedRows(
                            event.api.getSelectedNodes().length
                          );
                        }}
                        gridFilterModel={gridFilterModel}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Container>
    </PageTitle>
  );
};
