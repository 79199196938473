import React, { useEffect, useRef, useState } from "react";
import { completeFileSetup, uploadFile } from "../../api/useractions";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";

import {
  getGenericFileUploadRuleGroup,
  Rule,
  RuleType,
} from "../../api/ruleengine";
import { RuleBuilder } from "../rules/RuleBuilder";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { toReadableText } from "../utils/utils";

export interface FileUploadModalProps {
  modalOpen: boolean;
  onUploadInitiated(): void;
  onUploadConfirmed(fileId: number): void;
  onUploadCancelled(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: "10px",
    },
    clickableDiv: {
      height: "90%",
      cursor: "pointer",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#efefef",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px dashed rgba(0, 0, 0, 0.12)",
    },
  })
);

/**
 * RulesStepContent Component
 *
 * A component responsible for displaying the content of the rules step in a multi-step form.
 * It renders a list of RuleBuilder components, allowing users to define and modify rules for file uploads.
 * The list of rules is fetched from the server and displayed in a scrollable container.
 *
 * @param {object} props - The component's props.
 * @param {Function} props.onRulesChanged - A callback function invoked when a rule is modified or added.
 * @returns {JSX.Element} - The rendered RulesStepContent component.
 */
function RulesStepContent(
  props: Readonly<{ onRulesChanged(ruleDetails: Rule): void }>
) {
  // State to manage the list of rules for file uploads
  const [rules, setRules] = useState<Rule[]>([]);

  // Fetch the list of rules from the server when the component mounts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getGenericFileUploadRuleGroup()
        .then((response) => {
          setRules(response.rules);
        })
        .catch((err) => console.log(err));
    }
    return () => {
      // Clean up to prevent setting state on an unmounted component
      isMounted = false;
    };
  }, []);

  return (
    // Container to limit the height and provide scrolling for rules
    <Container
      style={{ maxHeight: "300px", overflow: "auto", padding: "16px" }}
    >
      {/* Map through the rules and render RuleBuilder components */}
      {rules.map((rule) => {
        return (
          <RuleBuilder
            key={rule.id}
            rule={rule}
            onRuleUpdate={(ruleDetails) => {
              // Remove the rule's ID to indicate it's a new rule on update
              ruleDetails.id = null;
              // Invoke the callback to notify the parent component of the rule change
              props.onRulesChanged(ruleDetails);
            }}
            disableSaveButton={true}
            ruleType={RuleType.FILE_UPLOAD}
          />
        );
      })}
    </Container>
  );
}

/**
 * ColumnChooserStep Component
 *
 * A component that represents a step in the column chooser process. It allows users to map columns from the uploaded sheet to Cirrom fields.
 * Users can select a sheet, view detected column mappings, and choose mappings for each column. Additionally, it displays sample values for the mapped columns.
 *
 * @param {function} props.onChange - A callback function invoked when the selected sheet is changed.
 * @param {any} props.currentUploadedFile - The object representing the currently uploaded file.
 * @param {string} props.selectedSheet - The name of the currently selected sheet.
 * @param {any} props.selectedSheetCols - An array containing the selected sheet's columns.
 * @param {any} props.selectedSheetColsVals - An object that holds the selected sheet columns' values.
 * @param {any} props.sampleValuesByColumn - An object that maps column names to sample values.
 * @param {function} props.setSelectedSheetColsVals - A callback function to set the selected sheet columns' values.
 * @returns {JSX.Element} - The rendered ColumnChooserStep component.
 */
function ColumnChooserStep(
  props: Readonly<{
    onChange: (event) => void;
    currentUploadedFile: any;
    selectedSheet: string;
    selectedSheetCols: any;
    selectedSheetColsVals: any;
    sampleValuesByColumn: any;
    setSelectedSheetColsVals: (newValue) => void;
  }>
) {
  /**
   * Handles the change of a column mapping for a given Cirrom field.
   *
   * @param {object} columnObject - The object representing the Cirrom field.
   * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event - The event triggered when the field value changes.
   */
  const onColumnChange = (
    columnObject,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    // Update the selected sheet columns' values with the new value for the given Cirrom field
    props.selectedSheetColsVals[columnObject.cirromName] = event.target.value;

    // Update the state with the updated selected sheet columns' values
    props.setSelectedSheetColsVals({
      ...props.selectedSheetColsVals,
      [columnObject.cirromName]: event.target.value,
    });
  };

  return (
    <>
      {/* Dropdown to select the sheet */}
      <TextField
        data-testid={"selected_sheet_btn"}
        select
        required
        fullWidth
        label="Sheet"
        variant="outlined"
        size="small"
        margin="dense"
        value={props.selectedSheet}
        onChange={props.onChange}
      >
        {/* Populate the dropdown with sheet names */}
        {props.currentUploadedFile.SheetInfo.map((sheetInfo) => {
          return (
            <MenuItem
              key={`radio-sheet-${sheetInfo.name}`}
              value={sheetInfo.name}
            >
              {sheetInfo.name}
            </MenuItem>
          );
        })}
      </TextField>

      {/* Show column mappings and sample values if a sheet is selected */}
      {props.selectedSheet && (
        <>
          {/* Information about detected column mappings */}
          <Typography
            variant="caption"
            align="left"
            style={{ marginTop: "1em" }}
          >
            Cirrom has detected and mapped the following columns based on your
            uploaded sheet.
          </Typography>
          {/* Heading for column mappings */}
          <Typography variant="h5" align="left" style={{ marginTop: "1em" }}>
            Column Mappings
          </Typography>
          <Divider />

          {/* Container to display the column mappings table */}
          <Container
            style={{
              maxHeight: "300px",
              overflow: "auto",
              padding: "16px",
            }}
          >
            <TableContainer>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    {/* Column header for Cirrom fields */}
                    <TableCell>
                      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
                        Cirrom Field
                      </Typography>
                    </TableCell>
                    {/* Column header for selected sheet fields */}
                    <TableCell>
                      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
                        {`${props.selectedSheet} Sheet Field`}
                      </Typography>
                    </TableCell>
                    {/* Column header for sample values */}
                    <TableCell>
                      <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
                        Sample Values
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Render rows for each column mapping */}
                  {props.currentUploadedFile.DataSourceColumns.map(
                    (columnObject) => {
                      return (
                        <TableRow
                          key={`listItemUpload-${columnObject.cirromName}`}
                        >
                          {/* Display Cirrom field name */}
                          <TableCell
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Typography variant={"h6"}>
                              {`${toReadableText(
                                columnObject.cirromName
                              )}`.concat(columnObject.mandatory ? ` *` : ``)}
                            </Typography>
                          </TableCell>
                          {/* Display dropdown for selecting sheet fields */}
                          <TableCell
                            style={{
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {props.selectedSheet === "" ? (
                              // Show disabled field if no sheet is selected
                              <TextField
                                variant={"standard"}
                                size="small"
                                margin="dense"
                                disabled={true}
                              />
                            ) : (
                              // Show dropdown for selecting sheet field
                              <TextField
                                select
                                variant={"outlined"}
                                required={columnObject.mandatory}
                                size="small"
                                fullWidth
                                margin="dense"
                                style={{ padding: 0 }}
                                defaultValue={columnObject.uploadName}
                                onChange={(event) =>
                                  onColumnChange(columnObject, event)
                                }
                              >
                                {/* Populate the dropdown with sheet fields */}
                                {props.selectedSheetCols.map(
                                  (colName, _index) => (
                                    <MenuItem
                                      key={`${colName}.${columnObject.cirromName}`}
                                      value={colName}
                                    >
                                      {colName}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            )}
                          </TableCell>
                          {/* Display sample value for the selected sheet field */}
                          <TableCell>
                            <Typography
                              variant={"caption"}
                              style={{ fontStyle: "italic" }}
                            >
                              {props.sampleValuesByColumn[
                                props.selectedSheetColsVals[
                                  columnObject.cirromName
                                ]
                              ]
                                ? props.sampleValuesByColumn[
                                    props.selectedSheetColsVals[
                                      columnObject.cirromName
                                    ]
                                  ][0]
                                : ""}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </>
      )}
    </>
  );
}

export function FileUploadModal(props: Readonly<FileUploadModalProps>) {
  const inputWidget = useRef(undefined);
  const { modalOpen, onUploadConfirmed, onUploadCancelled, onUploadInitiated } =
    props;
  const [isOpen, setIsOpen] = useState<boolean>(modalOpen);
  const [selectedSheet, setSelectedSheet] = useState("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedSheetCols, setSelectedSheetCols] = useState<string[]>([]);
  const [missingSheetCols, setMissingSheetCols] = useState<string[]>([]);
  const [selectedSheetColsVals, setSelectedSheetColsVals] = useState({});
  const [sampleValuesByColumn, setSampleValuesByColumn] = useState({});
  const [ruleDetails, setRuleDetails] = useState<Rule>(undefined);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [currentUploadedFile, setCurrentUploadedFile] =
    useState<any>(undefined);

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0);
      setSelectedSheet("");
      setMissingSheetCols([]);
    }
  }, [isOpen]);

  const handleFileUpload = (event) => {
    const element = event.target as HTMLInputElement;
    if (element.files) {
      onUploadInitiated();
      uploadFile(element.files[0])
        .then((response) => {
          setIsOpen(true);
          setCurrentUploadedFile(response);
        })
        .catch((err) => console.log(err));
      element.value = "";
    }
  };

  const onUploadConfirm = () => {
    let missingCols = [];
    currentUploadedFile.DataSourceColumns.forEach((columnObject) => {
      if (
        columnObject.mandatory &&
        !(columnObject.cirromName in selectedSheetColsVals)
      ) {
        missingCols.push(columnObject.cirromName);
      }
    });
    setMissingSheetCols(missingCols);
    if (missingCols.length === 0) {
      completeFileSetup(
        currentUploadedFile.FileID,
        selectedSheet,
        selectedSheetColsVals,
        ruleDetails
          ? {
              rules: [ruleDetails],
              name: "Rule group for file upload",
              rule_type: "FILE_UPLOAD",
            }
          : null
      )
        .then((_response) => {
          if (onUploadConfirmed) {
            onUploadConfirmed(currentUploadedFile.FileID);
            enqueueSnackbar("File successfully uploaded!", {
              variant: "success",
            });
          }
        })
        .catch((err) => console.log(err));
      setSelectedSheet("");
      setMissingSheetCols([]);
      setIsOpen(false);
    }
  };

  const onSheetChange = (event) => {
    setSelectedSheet(event.target.value);
    let columns = [];
    let localSampleValuesByColumn = {};
    currentUploadedFile.SheetInfo.forEach((sheetInfo) => {
      if (sheetInfo.name === event.target.value) {
        sheetInfo.columnInfo.forEach((colDetails) => {
          localSampleValuesByColumn[colDetails.columnName] =
            colDetails.exampleData;

          columns.push(colDetails.columnName);
          currentUploadedFile.DataSourceColumns.forEach((dataSourceCol) => {
            if (dataSourceCol.uploadName === colDetails.columnName) {
              const localSelectedSheet = selectedSheetColsVals;
              localSelectedSheet[dataSourceCol.cirromName] =
                colDetails.columnName;
              setSelectedSheetColsVals(localSelectedSheet);
            }
          });
        });
      }
    });
    setSampleValuesByColumn(localSampleValuesByColumn);
    setSelectedSheetCols(columns);
  };

  return (
    <>
      <div
        tabIndex={0}
        className={classes.clickableDiv}
        onClick={() => inputWidget.current.click()}
        onKeyDown={() => inputWidget.current.click()}
        onDragOver={(event) => event.preventDefault()}
        onDrop={(event) => {
          inputWidget.current.click();
          event.preventDefault();
        }}
      >
        <input
          data-testid={"file_upload_input"}
          ref={inputWidget}
          multiple
          type="file"
          autoComplete="off"
          tabIndex={-1}
          style={{ display: "none" }}
          onInputCapture={handleFileUpload}
        />
        <div style={{ textAlign: "center" }}>
          <img
            alt="AddFiles"
            src="/undraw_Add_files_re_v09g.svg"
            style={{ height: "128px" }}
          />
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          onUploadCancelled();
        }}
      >
        <Container maxWidth="md">
          <Card className={classes.paper}>
            <CardHeader title="Confirm File Upload" />
            <Divider />
            {currentUploadedFile !== undefined ? (
              <CardContent>
                <Stepper
                  activeStep={activeStep}
                  orientation={"vertical"}
                  style={{ padding: "10px" }}
                >
                  <Step>
                    <StepLabel>{"Column Setup"}</StepLabel>
                    <StepContent>
                      <ColumnChooserStep
                        sampleValuesByColumn={sampleValuesByColumn}
                        selectedSheet={selectedSheet}
                        onChange={onSheetChange}
                        currentUploadedFile={currentUploadedFile}
                        selectedSheetCols={selectedSheetCols}
                        selectedSheetColsVals={selectedSheetColsVals}
                        setSelectedSheetColsVals={setSelectedSheetColsVals}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          data-testid={"data_loader_complete_btn"}
                          size="small"
                          color={"primary"}
                          variant={"contained"}
                          disabled={!selectedSheet}
                          onClick={() => {
                            setActiveStep(1);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>{"Upload Rules Setup"}</StepLabel>
                    <StepContent>
                      <RulesStepContent
                        onRulesChanged={(changedRule) =>
                          setRuleDetails(changedRule)
                        }
                      />
                    </StepContent>
                  </Step>
                </Stepper>
              </CardContent>
            ) : null}
            <Divider />
            {missingSheetCols.length > 0 ? (
              <Container>
                <Typography
                  variant="h5"
                  align="left"
                  style={{ marginTop: "1em" }}
                >
                  Missing Column Mappings
                </Typography>
                <Divider />
                <ul>
                  {missingSheetCols.map((missingCol) => (
                    <li key={missingCol}>{missingCol}</li>
                  ))}
                </ul>
              </Container>
            ) : null}
            <CardActions
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={() => {
                  setIsOpen(false);
                  onUploadCancelled();
                }}
              >
                Cancel
              </Button>

              <Button
                data-testid={"file_confirm_btn"}
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  onUploadConfirm();
                }}
                disabled={activeStep === 0}
              >
                Confirm
              </Button>
            </CardActions>
          </Card>
        </Container>
      </Modal>
    </>
  );
}
