import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ExecutionComponent, ExecutionComponentContent } from "./Execution";
import { useSnackbar } from "notistack";
import { toReadableText } from "../utils/utils";
import {
  DataLoaderConfigComponent,
  DataLoaderConfigComponentContent,
} from "./DataLoaderConfig";

import {
  addBatchGroup,
  addBatchWindow,
  deleteBatchGroup,
  getBatchWindowsForBatchGroup,
  BatchWindow,
  BatchWindowConfig,
} from "../../api/batch";

import {
  addDataLoaderSystem,
  addDataLoaderConfig,
  addDataLoaderConfigRevision,
  addDataLoaderAdaptorProperties,
  BundledDataLoader,
  addIntegratedDataLoaderSystem,
  addDataLoaderSystemLists,
  DataLoaderOption,
  DataLoaderConfig,
} from "../../api/datasystems";
import { RuleGroupBuilder } from "../rules/RuleBuilder";
import {
  addRuleGroup,
  getGenericFileUploadRuleGroup,
  RuleGroup,
  saveRule,
} from "../../api/ruleengine";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { AdditionalDataIntegrationComponent } from "./AdditionalDataIntegration";
import { DataLoaderListConfig } from "./DataLoaderListConfig";
import { NavLink } from "react-router-dom";
import { DataLoaderSystemComponentContent } from "./DataLoaderSystem";
import { DataLoaderAdditionalAttributesConfig } from "./DataLoaderAdditionalAttributesConfig";

/**
 * Attributes associated with a batch
 */
export interface BatchComponentProps {
  id?: number;
  existing?: boolean;
  status: string;
  configRevisionId?: number;
  subheader?: string;
  ruleGroupId?: number;
  batchWindowConfig: BatchWindowConfig;
  dataLoaderOptions: DataLoaderOption[];
  availableDataLoaders: BundledDataLoader[];
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: "500px",
      width: "500px",
      margin: "0px 10px 10px 0px",
      boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    },
  })
);

/**
 * Renders an existing batch view.
 *
 * @param {Object} props - The properties passed to the method.
 * @param {number} props.selectedDataLoaderSystemId - The ID of the selected data loader system.
 * @param {number} props.selectedConfigRevisionId - The ID of the selected configuration revision.
 * @param {Function} props.onDataLoaderConfigChange - A function to handle changes in the data loader config.
 * @param {Function} props.onAdditionalDataIntegrationChange - A function to handle changes in additional data integration.
 * @param {Function} props.onDataLoaderListSelectionChange - A function to handle changes in data loader list selection.
 * @param {boolean} props.existing - Indicates if it is an existing batch view.
 * @param {Array} props.existingBatchWindows - An array of existing batch windows.
 * @param {Function} props.onExecutionComponentChange - A function to handle changes in the execution component.
 * @param {Object} props.userDefinedDataLoaderContent - The user-defined data loader content.
 * @param {Object} props.batchWindowConfig - The batch window configuration.
 * @param {Array} props.dataLoaderOptions - The data loader options.
 */
function ExistingBatchView(
  props: Readonly<{
    selectedDataLoaderSystemId: number;
    selectedConfigRevisionId: number;
    onDataLoaderConfigChange: (
      content: DataLoaderConfigComponentContent
    ) => void;
    onAdditionalDataIntegrationChange: (
      idx: number,
      datatype: string,
      value: string
    ) => void;
    onDataLoaderListSelectionChange: (idx: number, list_ids: string[]) => void;
    existing: boolean;
    existingBatchWindows: BatchWindow[];
    onExecutionComponentChange: (content: ExecutionComponentContent[]) => void;
    userDefinedDataLoaderContent: DataLoaderConfigComponentContent;
    batchWindowConfig: BatchWindowConfig;
    dataLoaderOptions: DataLoaderOption[];
  }>
) {
  return (
    <>
      <Typography variant={"h4"} style={{ marginTop: "10px" }}>
        CRM
      </Typography>
      <Divider />
      <DataLoaderConfigComponent
        name="DataLoaderConfig"
        onDataLoaderConfigChange={props.onDataLoaderConfigChange}
        dataLoaderSystemIds={[props.selectedDataLoaderSystemId]}
        existing={props.existing}
        configRevisionId={props.selectedConfigRevisionId}
        dataLoaderOptions={props.dataLoaderOptions}
      />
      <Divider />
      <Typography variant={"h4"} style={{ marginTop: "10px" }}>
        Additional Integrations
      </Typography>
      <Divider />
      <AdditionalDataIntegrationComponent
        dataLoaderSystems={
          props?.userDefinedDataLoaderContent?.dataLoaderSystems || undefined
        }
        existing={props.existing}
        dataLoaderOptions={props.dataLoaderOptions}
        onAdditionalDataIntegrationChange={
          props.onAdditionalDataIntegrationChange
        }
      />
      <Divider />
      <Typography variant={"h4"} style={{ marginTop: "10px" }}>
        CRM List Configuration
      </Typography>
      <Divider />
      <DataLoaderListConfig
        dataLoaderSystems={
          props?.userDefinedDataLoaderContent?.dataLoaderSystems || undefined
        }
        dataLoaderSystemIds={props.selectedDataLoaderSystemId}
        existing={props.existing}
        onDataLoaderListSelectionChange={props.onDataLoaderListSelectionChange}
      />
      <Divider />
      <Typography variant={"h4"} style={{ marginTop: "10px" }}>
        CRM Additional Attribute Configuration
      </Typography>
      <Divider />
      <DataLoaderAdditionalAttributesConfig
        dataLoaderSystems={
          props?.userDefinedDataLoaderContent?.dataLoaderSystems || undefined
        }
        dataLoaderSystemIds={props.selectedDataLoaderSystemId}
        existing={props.existing}
        onDataLoaderListSelectionChange={props.onDataLoaderListSelectionChange}
      />
      <Divider />
      <Typography variant={"h4"} style={{ marginTop: "10px" }}>
        Process Execution
      </Typography>
      <Divider />
      <ExecutionComponent
        existingBatchWindows={props.existingBatchWindows}
        existing={props.existing}
        onExecutionComponentChange={props.onExecutionComponentChange}
        batchWindowConfig={props.batchWindowConfig}
      />
    </>
  );
}

/**
 * Rendering a new batch view - it allows users to define a data loader and associate run windows
 *
 * @param {Object} props - The props object containing the necessary data and event handlers
 * @param {Array} props.availableDataLoaders - An array of available data loaders
 * @param {number} props.selectedDataLoaderSystemId - The ID of the selected data loader system
 * @param {number} props.selectedConfigRevisionId - The ID of the selected config revision
 * @param {function} props.onDataLoaderSelectionChange - The event handler for data loader selection change
 * @param {function} props.onNewDataLoaderClick - The event handler for new data loader click
 * @param {function} props.onDataLoaderConfigChange - The event handler for data loader config change
 * @param {function} props.onAdditionalDataIntegrationChange - The event handler for additional data integration change
 * @param {function} props.onDataLoaderListSelectionChange - The event handler for data loader list selection change
 * @param {boolean} props.existing - A flag indicating whether it is an existing view or not
 * @param {Array} props.existingBatchWindows - An array of existing batch windows
 * @param {function} props.onExecutionComponentChange - The event handler for execution component change
 * @param {function} props.onRuleGroupChange - The event handler for rule group change
 * @param {Object} props.userDefinedDataLoaderContent - The content of the user-defined data loader
 * @param {Object} props.batchWindowConfig - The batch window config
 * @param {Array} props.dataLoaderOptions - An array of data loader options
 *
 * @return {JSX.Element} - The rendered NewBatchView component
 */
function NewBatchView(
  props: Readonly<{
    availableDataLoaders: BundledDataLoader[];
    selectedDataLoaderSystemId: number;
    selectedConfigRevisionId: number;
    onDataLoaderSelectionChange: (event) => void;
    onNewDataLoaderClick: () => void;
    onDataLoaderConfigChange: (
      content: DataLoaderConfigComponentContent
    ) => void;
    onAdditionalDataIntegrationChange: (
      idx: number,
      datatype: string,
      value: string
    ) => void;
    onDataLoaderListSelectionChange: (idx: number, list_ids: string[]) => void;
    existing: boolean;
    existingBatchWindows: BatchWindow[];
    onExecutionComponentChange: (content: ExecutionComponentContent[]) => void;
    onRuleGroupChange: (content: RuleGroup) => void;
    userDefinedDataLoaderContent: DataLoaderConfigComponentContent;
    batchWindowConfig: BatchWindowConfig;
    dataLoaderOptions: DataLoaderOption[];
  }>
) {
  const [activeStep, setActiveStep] = React.useState(0);

  const [ruleGroup, setRuleGroup] = React.useState<RuleGroup>(undefined);
  useEffect(() => {
    let isMounted = true;
    getGenericFileUploadRuleGroup()
      .then((response) => {
        if (isMounted) {
          setRuleGroup(response);
        }
      })
      .catch((err) => console.log(err));
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <Stepper
      activeStep={activeStep}
      orientation={"vertical"}
      style={{ padding: "10px" }}
    >
      <Step>
        <StepLabel>CRM</StepLabel>
        <StepContent>
          {props.availableDataLoaders.length > 0 &&
          props.selectedDataLoaderSystemId === undefined ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant={"h5"} style={{ textAlign: "center" }}>
                Select from an existing config
              </Typography>
              <Select
                variant={"standard"}
                value={props.selectedDataLoaderSystemId || ""}
                onChange={props.onDataLoaderSelectionChange}
              >
                {props.availableDataLoaders.map((dataLoader) => {
                  return (
                    <MenuItem
                      key={dataLoader.DataLoaderConfig.id}
                      value={dataLoader.DataLoaderSystem.id}
                    >
                      {dataLoader.DataLoaderSystem.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography variant={"h5"} style={{ textAlign: "center" }}>
                or
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={props.onNewDataLoaderClick}
              >
                Create a new one
              </Button>
            </div>
          ) : null}

          {props.availableDataLoaders.length === 0 &&
          props.selectedDataLoaderSystemId === undefined ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth={true}
              onClick={props.onNewDataLoaderClick}
              data-testid={"new_data_loader_scratch_btn"}
            >
              New
            </Button>
          ) : null}
          {props.selectedDataLoaderSystemId !== undefined ? (
            <>
              <DataLoaderConfigComponent
                name="DataLoaderConfig"
                onDataLoaderConfigChange={props.onDataLoaderConfigChange}
                dataLoaderSystemIds={[props.selectedDataLoaderSystemId]}
                configRevisionId={props.selectedConfigRevisionId}
                existing={props.existing}
                dataLoaderOptions={props.dataLoaderOptions}
              />
              <Button
                data-testid={"data_loader_continue_btn"}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setActiveStep(activeStep + 1)}
                fullWidth={true}
              >
                Continue
              </Button>
            </>
          ) : null}
        </StepContent>
      </Step>
      <Step>
        <StepLabel>Additional Integrations</StepLabel>
        <StepContent>
          {props?.userDefinedDataLoaderContent?.dataLoaderSystems !==
          undefined ? (
            <AdditionalDataIntegrationComponent
              dataLoaderSystems={
                props.userDefinedDataLoaderContent.dataLoaderSystems
              }
              existing={props.existing}
              onAdditionalDataIntegrationChange={
                props.onAdditionalDataIntegrationChange
              }
              dataLoaderOptions={props.dataLoaderOptions}
            />
          ) : null}
          <Button
            data-testid={"data_loader_integrations_continue_btn"}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setActiveStep(activeStep + 1)}
            fullWidth={true}
          >
            Continue
          </Button>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>CRM List Configuration</StepLabel>
        <StepContent>
          {props?.userDefinedDataLoaderContent?.dataLoaderSystems !==
          undefined ? (
            <DataLoaderListConfig
              dataLoaderSystems={
                props.userDefinedDataLoaderContent.dataLoaderSystems
              }
              existing={props.existing}
              onDataLoaderListSelectionChange={
                props.onDataLoaderListSelectionChange
              }
            />
          ) : null}
          <Button
            data-testid={"data_loader_lists_continue_btn"}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setActiveStep(activeStep + 1)}
            fullWidth={true}
          >
            Continue
          </Button>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>CRM Additional Attributes Configuration</StepLabel>
        <StepContent>
          {props?.userDefinedDataLoaderContent?.dataLoaderSystems !==
          undefined ? (
            <Typography variant={"body2"}>
              Additional Attributes Configuration available for systems already
              setup.
            </Typography>
          ) : null}
          <Button
            data-testid={"data_loader_additional_attributes_continue_btn"}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setActiveStep(activeStep + 1)}
            fullWidth={true}
          >
            Continue
          </Button>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>Update Rules</StepLabel>
        <StepContent>
          <RuleGroupBuilder
            integratedSystemId={props.selectedDataLoaderSystemId}
            ruleGroup={ruleGroup}
            onRuleGroupChange={props.onRuleGroupChange}
          />
          <Button
            data-testid={"rules_continue_btn"}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setActiveStep(activeStep + 1)}
            fullWidth={true}
          >
            Continue
          </Button>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>Execution Times</StepLabel>
        <StepContent>
          <ExecutionComponent
            existingBatchWindows={props.existingBatchWindows}
            existing={props.existing}
            onExecutionComponentChange={props.onExecutionComponentChange}
            batchWindowConfig={props.batchWindowConfig}
          />
        </StepContent>
      </Step>
    </Stepper>
  );
}

/**
 * A batch component - switch between new batch view and existing batch view as per use case
 * Handle all batch save / update operations
 * @param {Readonly<BatchComponentProps>} props - The component props
 * @returns {JSX.Element} The rendered component
 */
export function BatchComponent(props: Readonly<BatchComponentProps>) {
  const classes = useStyles();
  const { configRevisionId, status, id, existing, subheader } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [batchSaveInProgress, setBatchSaveInProgress] =
    useState<boolean>(false);

  const [selectedDataLoaderSystemId, setSelectedDataLoaderSystemId] =
    useState<number>(undefined);
  const [selectedConfigRevisionId, setSelectedConfigRevisionId] =
    useState<number>(undefined);
  const [retrievedWindows, setRetrievedWindows] =
    useState<BatchWindow[]>(undefined);
  const [userDefinedDataLoaderContent, setUserDefinedDataLoaderContent] =
    useState<DataLoaderConfigComponentContent>(undefined);

  const [userDefinedExecutionContent, setUserDefinedExecutionContent] =
    useState<ExecutionComponentContent[]>(undefined);

  const [userDefinedRuleGroup, setUserDefinedRuleGroup] =
    useState<RuleGroup>(undefined);

  const onDataLoaderConfigChanged = (
    content: DataLoaderConfigComponentContent
  ) => {
    setUserDefinedDataLoaderContent(content);
  };
  const onExecutionComponentChanged = (
    content: ExecutionComponentContent[]
  ) => {
    setUserDefinedExecutionContent(content);
  };
  const onRuleGroupChanged = (content: RuleGroup) => {
    setUserDefinedRuleGroup(content);
  };

  useEffect(() => {
    if (props.availableDataLoaders) {
      const dataLoaderIdx = props.availableDataLoaders.findIndex(
        (dataLoader) =>
          dataLoader.DataLoaderSystem.id === selectedDataLoaderSystemId
      );
      if (dataLoaderIdx > -1) {
        setSelectedConfigRevisionId(
          props.availableDataLoaders[dataLoaderIdx].DataLoaderConfigRevision.id
        );
        setSelectedConfigRevisionId(
          props.availableDataLoaders[dataLoaderIdx].DataLoaderConfigRevision.id
        );
      }
    }
  }, [props.availableDataLoaders, selectedDataLoaderSystemId]);

  useEffect(() => {
    let isMounted = true;
    if (configRevisionId !== undefined) {
      if (props.availableDataLoaders) {
        const dataLoaderIdx = props.availableDataLoaders.findIndex(
          (dataLoader) =>
            dataLoader.DataLoaderConfigRevision.id === configRevisionId
        );
        if (dataLoaderIdx > -1) {
          if (isMounted) {
            setSelectedDataLoaderSystemId(
              props.availableDataLoaders[dataLoaderIdx].DataLoaderSystem.id
            );
          }
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [configRevisionId, props.availableDataLoaders]);

  useEffect(() => {
    let isMounted = true;

    if (id) {
      getBatchWindowsForBatchGroup(id)
        .then((response) => {
          if (isMounted) {
            setRetrievedWindows(response);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => {
      isMounted = false;
    };
  }, [id]);

  async function saveDataLoaders(
    dataLoaderSystemIds: number[],
    dataLoaderNames: any[]
  ) {
    await Promise.all(
      userDefinedDataLoaderContent.dataLoaderSystems.map(async (item) => {
        await addDataLoaderSystem({
          name: item.name,
          system_type: item.type,
        })
          .then((response) => {
            dataLoaderSystemIds.push(response.id);
            dataLoaderNames.push(item.name);
          })
          .catch((err) => console.log(err));
      })
    );
  }

  const saveDataLoaderOptions = (
    system: DataLoaderSystemComponentContent,
    dataLoaderSystemIds: number[],
    response: DataLoaderConfig
  ) => {
    for (const [key, value] of Object.entries(system.options)) {
      addDataLoaderAdaptorProperties(
        dataLoaderSystemIds[0],
        response.id,
        key,
        value
      )
        .then(() => {})
        .catch((error) => console.log(error));
    }
  };

  async function saveConfigRevisionAndOptions(
    config,
    dataLoaderSystemIds: number[],
    integratedDataLoaderSystemIds: number[]
  ) {
    await addDataLoaderConfigRevision(
      config.id,
      dataLoaderSystemIds.concat(integratedDataLoaderSystemIds),
      userDefinedDataLoaderContent.readEnabled,
      userDefinedDataLoaderContent.writeEnabled
    )
      .then((response) => {
        userDefinedDataLoaderContent.dataLoaderSystems.forEach((system) => {
          saveDataLoaderOptions(system, dataLoaderSystemIds, response);
        });
      })
      .catch((err) => console.log(err));
  }

  async function saveListOptions(dataLoaderSystemIds: number[]) {
    for (const item of userDefinedDataLoaderContent.dataLoaderSystems) {
      if (item.list_ids && item.list_ids.length > 0) {
        await addDataLoaderSystemLists({
          data_loader_system_id: dataLoaderSystemIds[0],
          list_ids: item.list_ids,
        });
      }
    }
  }

  const handleBatchSave = async () => {
    setBatchSaveInProgress(true);
    let dataLoaderSystemIds: number[] = [];
    let integratedDataLoaderSystemIds: number[] = [];
    let config = undefined;
    let batchGroup = undefined;

    let dataLoaderNames = [];
    //save the data loaders + options
    await saveDataLoaders(dataLoaderSystemIds, dataLoaderNames);

    //save the integrated data loader systems
    await Promise.all(
      userDefinedDataLoaderContent.dataLoaderSystems.map(async (item) => {
        if (item.integrations) {
          for (let [key, value] of Object.entries(item.integrations)) {
            if (value !== "") {
              await addIntegratedDataLoaderSystem({
                id: dataLoaderSystemIds[0],
                name: value,
                system_type: key,
              }).then((response) => {
                integratedDataLoaderSystemIds.push(response.id);
              });
            }
          }
        }
      })
    );

    //save the data loader config
    await addDataLoaderConfig({
      name: userDefinedDataLoaderContent.name,
    }).then((response) => {
      config = response;
    });

    //save the data loader config revision
    await saveConfigRevisionAndOptions(
      config,
      dataLoaderSystemIds,
      integratedDataLoaderSystemIds
    );

    //save the list ids for the data loader system
    await saveListOptions(dataLoaderSystemIds);
    await addBatchGroup(dataLoaderNames.join("-"), config.id).then(
      (response) => {
        batchGroup = response;
      }
    );

    await addRuleGroup({
      ...userDefinedRuleGroup,
      batch_group_id: batchGroup.id,
    }).then((ruleGroupResponse) => {
      userDefinedRuleGroup.id = ruleGroupResponse.id;
    });

    await Promise.all(
      userDefinedRuleGroup.rules.map(async (rule) => {
        rule.rule_group_id = userDefinedRuleGroup.id;
        rule.id = null;
        await saveRule(rule);
      })
    );

    await Promise.all(
      userDefinedExecutionContent.map(async (item) => {
        await addBatchWindow(
          {
            window_type: item.windowType,
            start_time:
              item.startTime.toString().length < 4
                ? "0" + item.startTime.toString()[0] + ":00"
                : item.startTime.toString().slice(0, 2) + ":00",
            end_time:
              item.endTime.toString().length < 4
                ? "0" + item.endTime.toString()[0] + ":00"
                : item.endTime.toString().slice(0, 2) + ":00",
            time_zone: item.timeZone,
            days: item.days,
            run_strategy: item.runStrategy,
            enabled: true,
            min_gap_in_minutes: 0,
          },
          batchGroup
        );
      })
    ).then(
      () =>
        enqueueSnackbar("Batch successfully saved!", { variant: "success" }),
      async () => {
        await deleteBatchGroup(batchGroup.id);
        enqueueSnackbar("Unable to save batch!", { variant: "error" });
      }
    );
    setBatchSaveInProgress(false);
  };

  const handleBatchDelete = () => {
    deleteBatchGroup(props.id)
      .then((_response) => {
        enqueueSnackbar("Batch successfully deleted!", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdditionalDataIntegrationSelection = (
    idx: number,
    datatype: string,
    value: string
  ) => {
    let dataLoaderSystems = userDefinedDataLoaderContent.dataLoaderSystems;
    let dataLoaderSystem = dataLoaderSystems[idx];
    dataLoaderSystem.integrations[datatype] = value;
    dataLoaderSystems[idx] = dataLoaderSystem;
    setUserDefinedDataLoaderContent({
      ...userDefinedDataLoaderContent,
      dataLoaderSystems: dataLoaderSystems,
    });
  };

  const handleDataLoaderListSelection = (idx: number, list_ids: string[]) => {
    let dataLoaderSystems = userDefinedDataLoaderContent.dataLoaderSystems;
    let dataLoaderSystem = dataLoaderSystems[idx];
    dataLoaderSystem.list_ids = list_ids;
    dataLoaderSystems[idx] = dataLoaderSystem;
    setUserDefinedDataLoaderContent({
      ...userDefinedDataLoaderContent,
      dataLoaderSystems: dataLoaderSystems,
    });
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={subheader || "Set up your CIRROM"}
        action={
          <>
            {existing ? (
              <IconButton
                aria-label="settings"
                size="large"
                component={NavLink}
                to={`/app/configure/support`}
              >
                {toReadableText(status)}
              </IconButton>
            ) : (
              <IconButton aria-label="settings" size="large">
                {toReadableText(status)}
              </IconButton>
            )}
          </>
        }
      />
      <CardContent>
        {existing ? (
          <ExistingBatchView
            selectedDataLoaderSystemId={selectedDataLoaderSystemId}
            selectedConfigRevisionId={selectedConfigRevisionId}
            onDataLoaderConfigChange={onDataLoaderConfigChanged}
            onAdditionalDataIntegrationChange={
              handleAdditionalDataIntegrationSelection
            }
            onDataLoaderListSelectionChange={handleDataLoaderListSelection}
            existing={existing}
            existingBatchWindows={retrievedWindows}
            onExecutionComponentChange={onExecutionComponentChanged}
            userDefinedDataLoaderContent={userDefinedDataLoaderContent}
            batchWindowConfig={props.batchWindowConfig}
            dataLoaderOptions={props.dataLoaderOptions}
          />
        ) : (
          <NewBatchView
            availableDataLoaders={props.availableDataLoaders}
            selectedDataLoaderSystemId={selectedDataLoaderSystemId}
            selectedConfigRevisionId={selectedConfigRevisionId}
            onDataLoaderSelectionChange={(event) =>
              setSelectedDataLoaderSystemId(event.target.value as number)
            }
            onNewDataLoaderClick={() => setSelectedDataLoaderSystemId(null)}
            onDataLoaderConfigChange={onDataLoaderConfigChanged}
            onAdditionalDataIntegrationChange={
              handleAdditionalDataIntegrationSelection
            }
            onDataLoaderListSelectionChange={handleDataLoaderListSelection}
            existing={existing}
            existingBatchWindows={retrievedWindows}
            onExecutionComponentChange={onExecutionComponentChanged}
            onRuleGroupChange={onRuleGroupChanged}
            userDefinedDataLoaderContent={userDefinedDataLoaderContent}
            batchWindowConfig={props.batchWindowConfig}
            dataLoaderOptions={props.dataLoaderOptions}
          />
        )}
      </CardContent>
      <CardActions
        style={{ display: "flex", justifyContent: "flex-end", padding: "0" }}
      >
        {existing === true ? (
          <div>
            <Button
              data-testid={"batch_delete_btn"}
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<DeleteIcon />}
              onClick={() => {
                handleBatchDelete();
              }}
              style={{ margin: "10px" }}
            >
              Delete
            </Button>
          </div>
        ) : (
          <Button
            data-testid={"batch_save_btn"}
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: "10px" }}
            startIcon={<SaveIcon />}
            onClick={() => {
              handleBatchSave()
                .then(() => {})
                .catch((err) => console.log(err));
            }}
            disabled={
              !userDefinedDataLoaderContent?.dataLoaderSystems ||
              !userDefinedExecutionContent ||
              batchSaveInProgress
            }
          >
            Save
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
