import { API } from "./api";

/**
 * Representation of a batch object
 */
export interface Batch {
  batch_group_id: number;
  id: number;
  status: string;
  data_loader_config_revision_id: number;
  last_update_time?: string;
  start_time?: string;
  data_loader_id?: number;
}

/**
 * Representation of a batch group object
 */
export interface BatchGroup {
  name: string;
  id: number;
  data_loader_config_id: number;
}

/**
 * Details belonging to a batch group
 */
export interface BatchGroupDetails {
  name: string;
  id: number;
  data_loader_config_revision_id: number;
  last_batch_status: string;
  rule_group_id: number;
  data_loader_id: number;
}

/**
 * Representation of a batch window
 */
export interface BatchWindow {
  id?: number;
  window_type: string;
  start_time: string;
  end_time: string;
  time_zone: string;
  days: string;
  run_strategy: string;
  enabled: boolean;
  min_gap_in_minutes?: number;
}

/**
 * Representation of a batch window config
 */
export interface BatchWindowConfig {
  windowTypes: string[];
  windowTimes: string[];
  timeZones: string[];
  runStrategies: string[];
}

/**
 * Details relating to the progress of a batch
 */
export interface BatchProgress {
  batch_id: number;
  process_name: string;
  status: string;
  failure_count: number;
  last_update_time: Date;
}

export const getAllBatchGroupDetails = async (): Promise<
  BatchGroupDetails[]
> => {
  //Get a list of all currently configured batches
  const response = await API.get("/get-all-batch-group-details");
  return response.data;
};

export const getBatchWindowConfig = async (): Promise<BatchWindowConfig> => {
  const runStrategiesResponse = await API.get("/get-available-run-strategies");
  const timeZonesResponse = await API.get("/get-available-time-zones");
  const windowTimesResponse = await API.get("/get-available-window-times");
  const windowTypesResponse = await API.get("/get-available-windows");

  return {
    windowTypes: windowTypesResponse.data,
    windowTimes: windowTimesResponse.data,
    timeZones: timeZonesResponse.data,
    runStrategies: runStrategiesResponse.data,
  };
};

export const addBatchGroup = async (
  name: string,
  dataLoaderConfigId: number
): Promise<BatchGroup> => {
  //Create a new batch group in the CIRROM db
  const response = await API.put("/add-batch-group", {
    name: name,
    data_loader_config_id: dataLoaderConfigId,
  });
  return response.data;
};

export const addBatchWindow = async (
  batchWindow: BatchWindow,
  batchGroup: BatchGroup
) => {
  //Create a new batch window and tag it to an existing batch group
  const response = await API.put("/add-batch-window", {
    batch_group_id: batchGroup.id,
    ...batchWindow,
  });
  return response.data;
};

export const updateBatchWindow = async (batchWindow) => {
  const response = await API.put("update-batch-window", batchWindow);
  return response.data;
};

export const deleteBatchGroup = async (batchGroupId: number) => {
  //Create a new batch window and tag it to an existing batch group
  const response = await API.delete("/delete-batch-group", {
    params: {
      batch_group_id: batchGroupId,
    },
  });
  return response.data;
};

export const getBatchWindowsForBatchGroup = async (
  batchGroupId: number
): Promise<BatchWindow[]> => {
  const response = await API.get("/get-all-batch-windows-for-batch-group", {
    params: { batch_group_id: batchGroupId },
  });
  return response.data;
};

export const getBatchesForSystemId = async (
  integratedSystemId: number
): Promise<Batch[]> => {
  const response = await API.get("/get-batches-for-system", {
    params: { system_id: integratedSystemId },
  });
  return response.data;
};

export const getBatchGroupSummary = async (
  batchGroupId: number
): Promise<BatchProgress[]> => {
  const response = await API.get("/get-batch-group-summary", {
    params: { batch_group_id: batchGroupId },
  });
  return response.data;
};

export const downloadBatchGroupLogFile = async (
  batchGroupId: number
): Promise<any> => {
  const response = await API.get("/get-log-file-for-batch-group", {
    params: { batch_group_id: batchGroupId },
    responseType: "blob",
  });

  return response.data;
};
