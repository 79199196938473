import React, { useCallback, useEffect, useState } from "react";
import {
  Action,
  Condition,
  getDataRulesMeta,
  getRule,
  Rule,
  RuleGroup,
  RuleType,
  saveRule,
} from "../../api/ruleengine";

import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { RuleCondition } from "./RuleCondition";
import { RuleAction } from "./RuleAction";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import classes from "./RuleBuilder.module.css";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

export function RuleGroupBuilder(
  props: Readonly<{
    ruleGroup: RuleGroup;
    onRuleGroupChange?(ruleGroupDetails): void;
    integratedSystemId: number;
  }>
) {
  const { ruleGroup, onRuleGroupChange } = props;
  const [selectedRuleGroup, setSelectedRuleGroup] =
    useState<RuleGroup>(ruleGroup);

  const updateRuleGroup = (newRule: Rule, index: number) => {
    let newRules = selectedRuleGroup.rules;
    newRules[index] = newRule;
    setSelectedRuleGroup({ ...selectedRuleGroup, rules: newRules });
  };

  useEffect(() => {
    if (onRuleGroupChange) {
      onRuleGroupChange(selectedRuleGroup);
    }
  }, [selectedRuleGroup]);

  return (
    <>
      {selectedRuleGroup?.rules?.map((ruleDetails, index) => {
        return (
          <Accordion
            key={`${ruleDetails.id}`}
            defaultExpanded={index === selectedRuleGroup.rules.length - 1}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{ruleDetails.description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <RuleBuilder
                  integratedSystemId={props.integratedSystemId}
                  rule={ruleDetails}
                  disableSaveButton={true}
                  onRuleUpdate={(newRule) => updateRuleGroup(newRule, index)}
                  ruleType={RuleType.CHANGES}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ margin: "2px", padding: "0em 0.5em" }}
          onClick={() =>
            setSelectedRuleGroup({
              ...selectedRuleGroup,
              rules: [
                ...selectedRuleGroup.rules,
                selectedRuleGroup.rules.slice(-1)[0],
              ],
            })
          }
        >
          Add rule
        </Button>
      </div>
    </>
  );
}

export function RuleBuilder(
  props: Readonly<{
    ruleId?: number;
    rule?: Rule;
    ruleGroupId?: number;
    onRuleUpdate?(ruleDetails): void;
    onRuleSaved?(ruleDetails): void;
    disableSaveButton?: boolean;
    disableRuleEdits?: boolean;
    ruleType: RuleType;
    integratedSystemId: number;
  }>
) {
  const {
    ruleId,
    rule,
    ruleGroupId,
    onRuleUpdate,
    onRuleSaved,
    disableSaveButton,
    disableRuleEdits,
    ruleType,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [ruleDetails, setRuleDetails] = useState(rule);
  const [conditionStatementsBySubject, setConditionStatementsBySubject] =
    useState({});

  const [availableActions, setAvailableActions] = useState<Action[]>([]);
  const [ruleSaving, setRuleSaving] = useState<Boolean>(false);

  useEffect(() => {
    if (onRuleUpdate) {
      onRuleUpdate(ruleDetails);
    }
  }, [ruleDetails]);

  useEffect(() => {
    let fabricatedRuleDetails = {
      pre_conditions: undefined,
      active: true,
      description: "Enter a rule description",
      verb: "ALL",
      conditions: [],
      actions: [],
      rule_group_id: ruleGroupId,
    };

    getDataRulesMeta(ruleGroupId, ruleType)
      .then((response) => {
        let statementsBySubject = {};
        response.conditions.forEach((item) => {
          statementsBySubject[item.name] = item;
        });
        setConditionStatementsBySubject(statementsBySubject);

        setAvailableActions(response.actions);
        fabricatedRuleDetails.conditions = [
          getDefaultRuleCondition(statementsBySubject),
        ];

        for (let action of response.actions) {
          if (action.action_type === "SEND_TO_MANUAL_REVIEW") {
            fabricatedRuleDetails.actions = [action];
            break;
          }
        }
        if (fabricatedRuleDetails.actions.length === 0) {
          fabricatedRuleDetails.actions = [response.actions[0]];
        }

        if (!ruleId && !rule) {
          setRuleDetails(fabricatedRuleDetails as Rule);
        }
      })
      .catch((err) => console.log(err));
    if (ruleId) {
      getRule(ruleId)
        .then((ruleResponse) => {
          setRuleDetails(ruleResponse);
        })
        .catch((err) => console.log(err));
    }
  }, [ruleId, rule, ruleType, ruleGroupId]);

  const getDefaultRuleCondition = (statementsBySubject) => {
    let firstEntry = Object.entries(statementsBySubject)[0];
    return {
      subject: firstEntry[0],
      statement: (firstEntry[1] as any).statements[0].name,
      expectation: "",
    };
  };

  const onAddNewCondition = useCallback(
    (_condition: Condition, currentIndex: number) => {
      return (suppliedCondition) => {
        setRuleDetails({
          ...ruleDetails,
          conditions: [
            ...ruleDetails.conditions.slice(0, currentIndex),
            suppliedCondition,
            ...ruleDetails.conditions.slice(currentIndex),
          ],
        });
      };
    },
    [ruleDetails]
  );

  const onAddNewAction = (_action: Action, _currentIndex: number) => {
    return (suppliedAction) => {
      setRuleDetails({
        ...ruleDetails,
        actions: [
          ...ruleDetails.actions.slice(0, -1),
          suppliedAction,
          suppliedAction,
        ],
      });
    };
  };

  const onRemoveCondition = (_condition: Condition, conditionIndex: number) => {
    if (ruleDetails.conditions.length > 1) {
      return (_suppliedCondition) => {
        let updatedConditions = [
          ...ruleDetails.conditions.slice(0, conditionIndex),
          ...ruleDetails.conditions.slice(conditionIndex + 1),
        ];
        setRuleDetails({
          ...ruleDetails,
          conditions: updatedConditions,
        });
      };
    }
  };

  const onRemoveAction = (_action: Action, currentIndex: number) => {
    if (ruleDetails.actions.length > 1) {
      return (_suppliedAction) => {
        setRuleDetails({
          ...ruleDetails,
          actions: [
            ...ruleDetails.actions.slice(0, currentIndex),
            ...ruleDetails.actions.slice(currentIndex + 1),
          ],
        });
      };
    }
  };

  const onRuleSave = () => {
    if (onRuleUpdate) {
      onRuleUpdate(ruleDetails);
    } else {
      setRuleSaving(true);
      saveRule(ruleDetails)
        .then((ruleResponse) => {
          enqueueSnackbar("Rule successfully saved!", { variant: "success" });
          setRuleSaving(false);
          if (onRuleSaved) {
            onRuleSaved(ruleResponse);
          }
        })
        .catch((err) => {
          console.log(err);
          setRuleSaving(false);
        });
    }
  };
  return (
    <>
      <TextField
        label="Description"
        margin="normal"
        variant={"standard"}
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={ruleDetails?.description || ""}
        style={{ margin: "0px", paddingTop: "0px" }}
        disabled={disableRuleEdits}
        onChange={(event) => {
          setRuleDetails({ ...ruleDetails, description: event.target.value });
        }}
      />
      <div className={classes.conditionsRow}>
        <Typography variant="h6" style={{ marginRight: "0.5em" }}>
          If
        </Typography>
        <Select
          disabled={disableRuleEdits}
          variant={"standard"}
          style={{ marginRight: "0.5em" }}
          value={ruleDetails ? ruleDetails.verb : ""}
          onChange={(event) =>
            setRuleDetails({ ...ruleDetails, verb: event.target.value as any })
          }
        >
          <MenuItem value={"ANY"}>ANY</MenuItem>
          <MenuItem value={"ALL"}>ALL</MenuItem>
        </Select>
        <Typography variant="h6">of the conditions are met</Typography>
        <Tooltip
          title={
            <Typography variant={"caption"}>
              ANY - at least one of the conditions specified below must be
              evaluated successfully for the actions to be applied.
              <br />
              <br />
              ALL - every single one of the conditions specified below must be
              evaluated successfully for the actions to be applied.
            </Typography>
          }
        >
          <InfoIcon style={{ width: "15px", marginLeft: "5px" }} />
        </Tooltip>
      </div>
      <div className={classes.ruleArea}>
        {ruleDetails?.conditions?.map(
          (condition: Condition, conditionIndex: number) => {
            return (
              <RuleCondition
                disableRuleEdits={disableRuleEdits}
                key={`${condition.subject}-${condition.statement}-${
                  condition.id || conditionIndex
                }`}
                onAdd={onAddNewCondition(condition, conditionIndex)}
                onConditionChange={(changedCondition) => {
                  let newConditions = ruleDetails.conditions;
                  newConditions[conditionIndex] = changedCondition;
                  setRuleDetails({
                    ...ruleDetails,
                    conditions: newConditions,
                  });
                }}
                onRemove={onRemoveCondition(condition, conditionIndex)}
                conditionStatementsBySubject={conditionStatementsBySubject}
                selectedSubject={condition.subject}
                selectedStatement={condition.statement}
                userInput={condition.expectation}
                ruleType={ruleType}
              />
            );
          }
        )}
      </div>
      <div className={classes.actionsRow}>
        <Typography variant="h6" style={{ marginRight: "0.5em" }}>
          Perform the following actions
        </Typography>
      </div>
      <div className={classes.ruleArea}>
        {ruleDetails?.actions?.map((action: Action, index: number) => {
          return (
            <RuleAction
              integratedSystemId={props.integratedSystemId}
              disableRuleEdits={disableRuleEdits}
              key={`${action.action_type}-${action.subjects}-${
                action.id || index
              }`}
              availableActions={availableActions}
              selectedAction={action.action_type}
              selectedActionValue={action.action_value}
              onAdd={onAddNewAction(action, index)}
              onRemove={onRemoveAction(action, index)}
              onActionChange={(changedAction) => {
                let newActions = ruleDetails.actions;
                newActions[index] = changedAction;
                setRuleDetails({ ...ruleDetails, actions: newActions });
              }}
            />
          );
        })}
      </div>
      {!disableSaveButton ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: "2px", padding: "0em 0.5em" }}
            onClick={onRuleSave}
            disabled={ruleSaving === true}
          >
            Save
          </Button>
        </div>
      ) : null}
    </>
  );
}
