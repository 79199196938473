import { useEffect, useState } from "react";
import { IntegratedSystem } from "../../api/integratedsystems";
import { DataProject } from "../../api/dataprojects";

export function useSystemOrProject(
  systemsOrProjects: IntegratedSystem[] | DataProject[],
  systemId: string
) {
  const [system, setSystem] = useState(null);

  useEffect(() => {
    systemsOrProjects?.forEach((integratedSystem) => {
      if (systemId && integratedSystem.id === parseInt(systemId)) {
        setSystem(integratedSystem);
      }
    });
  }, [systemsOrProjects, systemId]);

  return system;
}
