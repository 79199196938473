import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import createStyles from "@mui/styles/createStyles";
import { DataLoaderSystemComponentContent } from "./DataLoaderSystem";
import {
  DataLoaderAdditionalAttribute,
  getAvailableAdditionalAttributeOptions,
  getDataSystemAdditionalAttributes,
  updateDataLoaderAdditionalAttributes,
} from "../../api/datasystems";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { FormControl, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { isEmpty } from "lodash";

/**
 * List Config Props
 */
export interface DataLoaderAdditionalAttributesConfigProps {
  dataLoaderSystems: DataLoaderSystemComponentContent[];
  dataLoaderSystemIds?: number;
  existing?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 5px 5px 5px",
      marginBottom: "20px",
    },
  })
);

/**
 * A list selector
 * Establish a connection to the CRM and pull all available lists
 */
export const AdditionalAttributeSelection = (
  props: Readonly<{
    selectedAdditionalAttribute?: DataLoaderAdditionalAttribute;
    onAdd?(index: number): void;
    onRemove?(index: number): void;
    onChange(
      entity: string,
      name: string,
      configuration: string,
      index: number
    ): void;
    contactAdditionalAttribute: DataLoaderAdditionalAttribute[];
    accountAdditionalAttribute: DataLoaderAdditionalAttribute[];
    index: number;
    existing: boolean;
  }>
) => {
  const {
    selectedAdditionalAttribute,
    onAdd,
    onRemove,
    onChange,
    contactAdditionalAttribute,
    accountAdditionalAttribute,
    index,
    existing,
  } = props;

  const [selectedEntity, setSelectedEntity] = useState<string>("");
  const [selectedConfiguration, setSelectedConfiguration] =
    useState<string>("");
  const [additionalAttributes, setAdditionalAttributes] = useState<
    DataLoaderAdditionalAttribute[]
  >([]);

  useEffect(() => {
    if (
      selectedEntity == "" &&
      selectedAdditionalAttribute.entity == "CONTACT"
    ) {
      setAdditionalAttributes(contactAdditionalAttribute);
    } else if (
      selectedEntity == "" &&
      selectedAdditionalAttribute.entity == "ACCOUNT"
    ) {
      setAdditionalAttributes(accountAdditionalAttribute);
    }
  }, [selectedAdditionalAttribute]);

  useEffect(() => {
    if (selectedEntity == "CONTACT") {
      setAdditionalAttributes(contactAdditionalAttribute);
    } else if (selectedEntity == "ACCOUNT") {
      setAdditionalAttributes(accountAdditionalAttribute);
    }
  }, [selectedEntity]);

  useEffect(() => {
    onChange(selectedEntity, "", "", index);
  }, [selectedEntity]);

  useEffect(() => {
    let selectedNames = additionalAttributes.filter((item) => {
      return item.configuration == selectedConfiguration;
    });
    if (selectedNames.length == 1) {
      let selectedName = selectedNames[0].name;
      onChange(selectedEntity, selectedName, selectedConfiguration, index);
    }
  }, [selectedConfiguration]);

  return (
    <FormControl
      disabled={existing}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <Select
        variant={"standard"}
        data-testid={"selectentity"}
        value={selectedAdditionalAttribute.entity}
        onChange={(event) => {
          setSelectedEntity(event.target.value);
        }}
      >
        <MenuItem key="CONTACT" value="CONTACT">
          Contact
        </MenuItem>
        <MenuItem key="ACCOUNT" value="ACCOUNT">
          Account
        </MenuItem>
      </Select>

      <Select
        variant={"standard"}
        data-testid={"select"}
        style={{ flex: 1, maxWidth: "80%" }}
        value={selectedAdditionalAttribute.configuration}
        onChange={(event) => {
          setSelectedConfiguration(event.target.value);
        }}
      >
        {additionalAttributes.map((additionalAttribute) => {
          return (
            <MenuItem
              key={additionalAttribute.configuration}
              value={additionalAttribute.configuration}
            >
              {additionalAttribute.name}
            </MenuItem>
          );
        })}
      </Select>
      {!existing ? (
        <div style={{ display: "flex" }}>
          <IconButton
            data-testid={"remove_condition_btn"}
            aria-label="remove_condition"
            color="primary"
            disabled={!onRemove && !existing}
            onClick={(_event) => onRemove(index)}
            size="small"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          <IconButton
            data-testid={"add_condition_btn"}
            aria-label="add_condition"
            color="primary"
            size="small"
            disabled={!onAdd && !existing}
            onClick={(_event) => onAdd(index)}
          >
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </div>
      ) : null}
    </FormControl>
  );
};

/**
 * Renders the additional attribute selector and handles all save/ update operations
 *
 */
export function DataLoaderAdditionalAttributesConfig(
  props: Readonly<DataLoaderAdditionalAttributesConfigProps>
) {
  const { dataLoaderSystems, dataLoaderSystemIds, existing } = props;
  const classes = useStyles();

  const [selectedAdditionalAttributes, setSelectedAdditionalAttributes] =
    useState<DataLoaderAdditionalAttribute[]>([]);

  const [dataLoaderSystem, setDataLoaderSystem] =
    useState<DataLoaderSystemComponentContent>({
      integrations: undefined,
      list_ids: [],
      name: "",
      options: undefined,
      type: "",
    });
  const [contactAdditionalAttributeOptions, setContactAdditionalAttribute] =
    useState<DataLoaderAdditionalAttribute[]>([]);
  const [accountAdditionalAttributeOptions, setAccountAdditionalAttribute] =
    useState<DataLoaderAdditionalAttribute[]>([]);

  useEffect(() => {
    if (dataLoaderSystems && dataLoaderSystems.length > 0) {
      setDataLoaderSystem(dataLoaderSystems[0]);
    }
  }, [dataLoaderSystems]);

  useEffect(() => {
    if (dataLoaderSystem.type && !isEmpty(dataLoaderSystem.options)) {
      getAvailableAdditionalAttributeOptions(
        dataLoaderSystem.type,
        dataLoaderSystem.options
      )
        .then((response) => {
          setContactAdditionalAttribute(response.CONTACTS);
          setAccountAdditionalAttribute(response.ACCOUNTS);
        })
        .catch((err) => console.log(err));
    }
  }, [dataLoaderSystem.type, dataLoaderSystem.options]);

  useEffect(() => {
    if (dataLoaderSystem.type) {
      if (
        existing &&
        dataLoaderSystemIds &&
        !isEmpty(contactAdditionalAttributeOptions) &&
        !isEmpty(accountAdditionalAttributeOptions)
      ) {
        getDataSystemAdditionalAttributes(dataLoaderSystemIds)
          .then((response) => {
            if (response.length) {
              setSelectedAdditionalAttributes(response);
            } else {
              setSelectedAdditionalAttributes([
                { entity: "", name: "", configuration: "" },
              ]);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setSelectedAdditionalAttributes([
          { entity: "", name: "", configuration: "" },
        ]);
      }
    }
  }, [contactAdditionalAttributeOptions, accountAdditionalAttributeOptions]);

  const onAdd = (index: number) => {
    setSelectedAdditionalAttributes([
      ...selectedAdditionalAttributes.slice(0, index + 1),
      { entity: "", name: "", configuration: "" },
      ...selectedAdditionalAttributes.slice(index + 1),
    ]);
  };

  const onRemove = (index: number) => {
    if (selectedAdditionalAttributes.length === 1) {
      setSelectedAdditionalAttributes([]);
    } else {
      setSelectedAdditionalAttributes([
        ...selectedAdditionalAttributes.slice(0, index),
        ...selectedAdditionalAttributes.slice(index + 1),
      ]);
    }
  };

  const onChange = (
    entity: string,
    name: string,
    configuration: string,
    index: number
  ) => {
    setSelectedAdditionalAttributes([
      ...selectedAdditionalAttributes.slice(0, index),
      { entity: entity, name: name, configuration: configuration },
      ...selectedAdditionalAttributes.slice(index + 1),
    ]);
  };
  return (
    <>
      <Container className={classes.container}>
        {selectedAdditionalAttributes.length > 0 ? (
          selectedAdditionalAttributes.map(
            (
              selectedAdditionalAttribute: DataLoaderAdditionalAttribute,
              index
            ) => {
              return (
                <AdditionalAttributeSelection
                  selectedAdditionalAttribute={selectedAdditionalAttribute}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  onChange={onChange}
                  contactAdditionalAttribute={contactAdditionalAttributeOptions}
                  accountAdditionalAttribute={accountAdditionalAttributeOptions}
                  index={index}
                  existing={false}
                />
              );
            }
          )
        ) : (
          <Typography variant={"body2"}>
            No Additional Attributes Configuration available for this CRM.
          </Typography>
        )}
      </Container>
      {existing && contactAdditionalAttributeOptions.length > 0 && (
        <Button
          onClick={() => {
            updateDataLoaderAdditionalAttributes({
              data_loader_system_id: dataLoaderSystemIds,
              additional_attributes: selectedAdditionalAttributes,
            })
              .then(() => {})
              .catch((err) => {});
          }}
        >
          Apply
        </Button>
      )}
    </>
  );
}
