import React, { useEffect, useState } from "react";
import {
  BatchGroupDetails,
  BatchWindowConfig,
  getAllBatchGroupDetails,
  getBatchWindowConfig,
} from "../api/batch";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import { BatchComponent } from "../components/batch/Batch";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { PageTitle } from "../components/utils/PageTitle";
import {
  BundledDataLoader,
  DataLoaderOption,
  getAllDataLoaderSystems,
  getAvailableDataTypesAndOptions,
} from "../api/datasystems";

export function Configuration() {
  const [batchWindowConfig, setBatchWindowConfig] =
    useState<BatchWindowConfig>();

  const [configuredBatchGroups, setConfiguredBatchGroups] = useState<
    BatchGroupDetails[]
  >([]);
  const [addNewItem, setAddNewItem] = useState<boolean>(false);

  const [dataLoaderOptions, setDataLoaderOptions] = useState<
    DataLoaderOption[]
  >([]);

  const [availableDataLoaders, setAvailableDataLoaders] = useState<
    BundledDataLoader[]
  >([]);

  useEffect(() => {
    let isMounted = true;
    getAllBatchGroupDetails()
      .then(async (batchGroupDetail) => {
        setConfiguredBatchGroups(batchGroupDetail);
      })
      .catch((err) => console.log(err));
    getBatchWindowConfig()
      .then((response) => {
        if (isMounted) {
          setBatchWindowConfig(response);
        }
      })
      .catch((err) => console.log(err));
    getAvailableDataTypesAndOptions()
      .then((dataOptionsResponse) => {
        if (isMounted) {
          setDataLoaderOptions(dataOptionsResponse);
        }
      })
      .catch((err) => console.log(err));
    getAllDataLoaderSystems()
      .then((dataLoaderResponse) => {
        setAvailableDataLoaders(dataLoaderResponse);
      })
      .catch((err) => console.log(err));

    return () => {
      isMounted = false;
    };
  }, []);

  const canAddNewItem = () => {
    if (addNewItem) {
      return (
        <BatchComponent
          batchWindowConfig={batchWindowConfig}
          status="New"
          dataLoaderOptions={dataLoaderOptions}
          availableDataLoaders={availableDataLoaders}
        />
      );
    }
  };

  return (
    <div>
      <PageTitle
        pageTitle="Configuration | CIRROM"
        pageHeader={
          configuredBatchGroups.length > 0
            ? "Configured Data Sources"
            : "Set up a new Data Source"
        }
      >
        <Container maxWidth="xl" style={{ padding: "0px" }}>
          {configuredBatchGroups.length > 0 ? (
            <>
              <IconButton
                aria-label="new"
                color="info"
                onClick={() => setAddNewItem(!addNewItem)}
                size="large"
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
              <Container
                maxWidth="xl"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {canAddNewItem()}
                {configuredBatchGroups.map((configuredBatchGroup) => {
                  return (
                    batchWindowConfig && (
                      <BatchComponent
                        key={`Batch-${configuredBatchGroup.id}`}
                        subheader={configuredBatchGroup.name}
                        existing={true}
                        status={configuredBatchGroup.last_batch_status}
                        id={configuredBatchGroup.id}
                        configRevisionId={
                          configuredBatchGroup.data_loader_config_revision_id
                        }
                        ruleGroupId={configuredBatchGroup.rule_group_id}
                        batchWindowConfig={batchWindowConfig}
                        dataLoaderOptions={dataLoaderOptions}
                        availableDataLoaders={availableDataLoaders}
                      />
                    )
                  );
                })}
              </Container>
            </>
          ) : (
            <Container
              maxWidth="xl"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {batchWindowConfig && (
                <BatchComponent
                  batchWindowConfig={batchWindowConfig}
                  dataLoaderOptions={dataLoaderOptions}
                  status="New"
                  availableDataLoaders={availableDataLoaders}
                />
              )}
            </Container>
          )}
        </Container>
      </PageTitle>
    </div>
  );
}
