import { API } from "./api";

/**
 * Data Loader system props
 */
export interface DataLoaderSystem {
  id?: number;
  name: string;
  system_type: string;
}

/**
 * Data Loader Config
 */
export interface DataLoaderConfig {
  name: string;
  id?: number;
  active_revision_id?: number;
}

export interface DataLoaderConfigRevision {
  id?: number;
  data_loader_config_id: number;
}

export interface DataLoaderConfigRevisionSystems {
  data_loader_config_revision_id: number;
  data_loader_system_id: number;
  write_enabled: boolean;
  read_enabled: boolean;
}

export interface DataOption {
  path?: string;
  name: string;
  description?: string;
  value?: string;
  type?: string;
}

export interface Integration {
  type: string;
  integrations: string[];
}

export interface DataLoaderOption {
  type: string;
  options: DataOption[];
  integrations: Integration[];
}

export interface DataLoaderListOption {
  list_id: string;
  list_name: string;
}

export interface DataLoaderList {
  data_loader_system_id: number;
  list_ids: string[];
}

export interface DataLoaderAdditionalAttribute {
  entity?: string;
  name: string;
  configuration: string;
}

export interface DataLoaderAdditionalAttributeOption {
  CONTACTS: DataLoaderAdditionalAttribute[];
  ACCOUNTS: DataLoaderAdditionalAttribute[];
}

export interface DataLoaderAdditionalAttributes {
  data_loader_system_id: number;
  additional_attributes: DataLoaderAdditionalAttribute[];
}

export interface IntegrationOptions {
  EmailMarketing: string;
  TimeAndBilling: string;
}

export interface BundledDataLoader {
  DataLoaderConfig: DataLoaderConfig;
  DataLoaderConfigRevision: DataLoaderConfigRevision;
  DataLoaderConfigRevisionSystems: DataLoaderConfigRevisionSystems;
  DataLoaderSystem: DataLoaderSystem;
}

export const getDataLoaderSystems = async (): Promise<DataLoaderSystem[]> => {
  //Get a list of all currently configured data loader systems
  const response = await API.get("get-data-loader-systems");
  return response.data;
};

export const getDataLoaderSystem = async (
  systemId: number
): Promise<DataLoaderSystem> => {
  //Get a list of all currently configured data loader systems
  const response = await API.get("/get-data-loader-system", {
    params: { system_id: systemId },
  });
  return response.data;
};

export const addDataLoaderSystem = async (
  dataLoaderSystem: DataLoaderSystem
): Promise<DataLoaderSystem> => {
  //Create a new data loader system in the CIRROM db
  const response = await API.put("/add-data-loader-system", {
    name: dataLoaderSystem.name,
    system_type: dataLoaderSystem.system_type,
  });
  return response.data;
};

export const addIntegratedDataLoaderSystem = async (
  dataLoaderSystem: DataLoaderSystem
): Promise<DataLoaderSystem> => {
  //Create a new integrated data loader system in the CIRROM db
  const response = await API.put("/add-integrated-data-loader-system", {
    id: dataLoaderSystem.id,
    name: dataLoaderSystem.name,
    system_type: dataLoaderSystem.system_type,
  });
  return response.data;
};

export const addDataLoaderSystemLists = async (
  dataLoaderList: DataLoaderList
) => {
  //Add Data Loader System List Ids
  await API.put("/add-data-system-list-options", {
    data_loader_system_id: dataLoaderList.data_loader_system_id,
    list_ids: dataLoaderList.list_ids,
  });
};

export const updateDataLoaderSystemLists = async (
  dataLoaderList: DataLoaderList
) => {
  //Add Data Loader System List Ids
  await API.put("/update-data-system-list-options", {
    data_loader_system_id: dataLoaderList.data_loader_system_id,
    list_ids: dataLoaderList.list_ids,
  });
};

export const addDataLoaderConfig = async (
  dataLoaderConfig: DataLoaderConfig
): Promise<DataLoaderConfig> => {
  //Create a new data loader config in the CIRROM db
  const response = await API.put("/add-data-loader-config", {
    name: dataLoaderConfig.name,
  });
  return response.data;
};

export const getDataLoaderConfig = async (
  configId: number
): Promise<DataLoaderConfig> => {
  //Create a new data loader config in the CIRROM db
  const response = await API.get("/get-data-loader-config", {
    params: { config_id: configId },
  });
  return response.data;
};

export const getAllDataLoaderConfigs = async (): Promise<
  DataLoaderConfig[]
> => {
  const response = await API.get("/get-all-data-loader-configs");
  return response.data;
};

export const addDataLoaderConfigRevision = async (
  dataLoaderConfigId: number,
  dataLoaderSystemIds: number[],
  readEnabled: boolean,
  writeEnabled: boolean
): Promise<DataLoaderConfig> => {
  //Create a new data loader config revision in the CIRROM db
  const response = await API.put("/add-data-loader-config-revision", {
    data_loader_config_id: dataLoaderConfigId,
    data_loader_system_ids: dataLoaderSystemIds,
    read_enabled: readEnabled,
    write_enabled: writeEnabled,
  });
  return response.data;
};

export const addDataLoaderAdaptorProperties = async (
  dataLoaderSystemId: number,
  configRevisionId: number,
  attributePath: string,
  attributeValue
) => {
  const response = await API.put("/add-data-loader-adaptor-property", {
    data_system_id: dataLoaderSystemId,
    config_revision_id: configRevisionId,
    attribute_path: attributePath,
    attribute_value: attributeValue,
  });
  return response.data;
};

export const updateDataLoaderAdaptorProperties = async (
  dataLoaderSystemId: number,
  configRevisionId: number,
  attributes: DataOption[],
  systemType?: string
) => {
  const response = await API.put("/update-data-loader-adaptor-property", {
    data_system_id: dataLoaderSystemId,
    config_revision_id: configRevisionId,
    attributes: attributes,
    system_type: systemType,
  });
  return response.data;
};

export const getDataLoaderConfigRevision = async (
  configId: number
): Promise<DataLoaderConfigRevision> => {
  //Create a new data loader config revision in the CIRROM db
  const response = await API.get(
    "/get-data-loader-config-revision-for-config",
    {
      params: { config_id: configId },
    }
  );
  return response.data;
};

export const getAllDataLoaderSystems = async (): Promise<
  BundledDataLoader[]
> => {
  const response = await API.get("/get-all-data-loader-systems");
  return response.data;
};

export const getDataLoaderConfigRevisionSystems = async (
  configRevisionId: number
): Promise<DataLoaderConfigRevisionSystems[]> => {
  const response = await API.get("/get-data-loader-config-revisions-systems", {
    params: { config_revision_id: configRevisionId },
  });
  return response.data;
};

export const getDataLoaderOptions = async (
  dataLoaderId: number,
  configRevisionId: number
): Promise<DataOption[]> => {
  const response = await API.get("/get-options-for-data-loader-and-revision", {
    params: {
      data_loader_id: dataLoaderId,
      config_revision_id: configRevisionId,
    },
  });
  return response.data;
};

export const getIntegratedDataLoader = async (
  dataLoaderId: number,
  configRevisionId: number
): Promise<IntegrationOptions> => {
  const response = await API.get(
    "/get-integrated-system-for-data-loader-and-revision",
    {
      params: {
        data_loader_id: dataLoaderId,
        config_revision_id: configRevisionId,
      },
    }
  );
  return response.data;
};

export const getAvailableDataTypesAndOptions = async (): Promise<
  DataLoaderOption[]
> => {
  const response = await API.get("/get-data-loader-types-and-options");
  return response.data;
};

export const getAllSuggestedChanges = async () => {
  const response = await API.get("/get-all-suggested-changes");
  return response.data;
};

export const getAvailableAdditionalAttributeOptions = async (
  type: string,
  options: {},
  integratedSystemId?: number
): Promise<DataLoaderAdditionalAttributeOption> => {
  const response = await API.post(
    "/get-data-system-additional-attribute-options",
    {
      system_type: type,
      options: options,
      system_id: integratedSystemId,
    }
  );
  return response.data;
};

export const getDataSystemAdditionalAttributes = async (
  data_system_id: number
): Promise<DataLoaderAdditionalAttribute[]> => {
  const response = await API.get("/get-data-system-additional-attributes", {
    params: {
      data_system_id: data_system_id,
    },
  });
  return response.data;
};

export const updateDataLoaderAdditionalAttributes = async (
  dataLoaderAdditionalAttributes: DataLoaderAdditionalAttributes
) => {
  //Add Data Loader System List Ids
  console.log(dataLoaderAdditionalAttributes);
  await API.put(
    "/update-data-system-additional-attributes",
    dataLoaderAdditionalAttributes
  );
};

export const getAvailableListOptions = async (
  type: string,
  options: {},
  integratedSystemId?: number
): Promise<DataLoaderListOption[]> => {
  const response = await API.post("/get-data-system-list-options", {
    system_type: type,
    options: options,
    system_id: integratedSystemId,
  });
  return response.data;
};

export const getDataSystemLists = async (
  data_system_id: number
): Promise<DataLoaderList> => {
  const response = await API.get("/get-data-system-lists", {
    params: {
      data_system_id: data_system_id,
    },
  });
  return response.data;
};

export const getDataSystemEventTypes = async (
  data_system_id: number,
  config_revision_id: number
): Promise<any> => {
  const response = await API.get("/get-data-system-event-types", {
    params: {
      data_system_id: data_system_id,
      config_revision_id: config_revision_id,
    },
  });
  return response.data;
};
