/**
 * Variable representing a typography style configuration.
 *
 * @type {object}
 * @property {string} fontFamily - The font family for the typography.
 * @property {object} h1 - The heading level 1 style configuration.
 * @property {number} h1.fontWeight - The font weight for heading level 1.
 * @property {number} h1.fontSize - The font size for heading level 1.
 * @property {string} h1.letterSpacing - The letter spacing for heading level 1.
 * @property {string} h1.fontFamily - The font family for heading level 1.
 * @property {object} h2 - The heading level 2 style configuration.
 * @property {number} h2.fontWeight - The font weight for heading level 2.
 * @property {number} h2.fontSize - The font size for heading level 2.
 * @property {string} h2.letterSpacing - The letter spacing for heading level 2.
 * @property {string} h2.fontFamily - The font family for heading level 2.
 * @property {object} h3 - The heading level 3 style configuration.
 * @property {number} h3.fontWeight - The font weight for heading level 3.
 * @property {number} h3.fontSize - The font size for heading level 3.
 * @property {string} h3.letterSpacing - The letter spacing for heading level 3.
 * @property {string} h3.fontFamily - The font family for heading level 3.
 * @property {object} h4 - The heading level 4 style configuration.
 * @property {number} h4.fontWeight - The font weight for heading level 4.
 * @property {number} h4.fontSize - The font size for heading level 4.
 * @property {string} h4.letterSpacing - The letter spacing for heading level 4.
 * @property {string} h4.fontFamily - The font family for heading level 4.
 * @property {object} h5 - The heading level 5 style configuration.
 * @property {number} h5.fontWeight - The font weight for heading level 5.
 * @property {number} h5.fontSize - The font size for heading level 5.
 * @property {string} h5.letterSpacing - The letter spacing for heading level 5.
 * @property {string} h5.fontFamily - The font family for heading level 5.
 * @property {object} h6 - The heading level 6 style configuration.
 * @property {number} h6.fontWeight - The font weight for heading level 6.
 * @property {number} h6.fontSize - The font size for heading level 6.
 * @property {string} h6.letterSpacing - The letter spacing for heading level 6.
 * @property {string} h6.fontFamily - The font family for heading level 6.
 * @property {object} overline - The overline style configuration.
 * @property {number} overline.fontWeight - The font weight for the overline style.
 */
export const typography = {
  fontFamily: ["proxima-nova", "space grotesk", "montserrat"].join(","),
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: "-0.24px",
    fontFamily: "space grotesk",
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: "-0.24px",
    fontFamily: "space grotesk",
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: "-0.06px",
    fontFamily: "proxima-nova",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.06px",
    fontFamily: "proxima-nova",
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: "-0.05px",
    fontFamily: "proxima-nova",
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
    fontFamily: "proxima-nova",
  },
  overline: {
    fontWeight: 500,
  },
};
