import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import FormControl from "@mui/material/FormControl";
import Container from "@mui/material/Container";
import { DataLoaderOption } from "../../api/datasystems";
import { DataLoaderSystemComponentContent } from "./DataLoaderSystem";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

/**
 * Represents the properties for the AdditionalDataIntegrationComponent.
 */
export interface AdditionalDataIntegrationComponentProps {
  dataLoaderSystems: DataLoaderSystemComponentContent[];
  existing?: boolean;
  configRevisionId?: number;
  id?: number;
  onAdditionalDataIntegrationChange: (
    idx: number,
    datatype: string,
    value: string
  ) => void;
  dataLoaderOptions: DataLoaderOption[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "10px 5px 5px 5px",
      borderLeft: "1px solid #c4c4c4",
    },
  })
);

/**
 * A component for additional data integration.
 *
 * @param {object} props - The component props.
 * @param {Array} props.dataLoaderSystems - The data loader systems.
 * @param {boolean} props.existing - Flag indicating if it is an existing integration.
 * @param {function} props.onAdditionalDataIntegrationChange - The callback function to handle additional data integration changes.
 *
 * @returns {JSX.Element} - The rendered component.
 */
export function AdditionalDataIntegrationComponent(
  props: Readonly<AdditionalDataIntegrationComponentProps>
) {
  const { dataLoaderSystems, existing, onAdditionalDataIntegrationChange } =
    props;
  const classes = useStyles();

  const [dataLoaderSystem, setDataLoaderSystem] =
    useState<DataLoaderSystemComponentContent>(undefined);

  // `useEffect` Initializes dataLoaderSystem with the first item from dataLoaderSystems array, once the dataLoaderSystems is populated
  useEffect(() => {
    // Check if dataLoaderSystems is indeed populated
    if (dataLoaderSystems) {
      // Set DataLoaderSystem as the first item in array
      setDataLoaderSystem(dataLoaderSystems[0]);
    }
  }, [dataLoaderSystems]); // The effect depends on the state of dataLoaderSystems

  // handleIntegratedSystemChange function: It triggers the onAdditionalDataIntegrationChange function
  // with specific inputs whenever there is a change in the integrated system
  const handleIntegratedSystemChange = (datatype: string, value: string) => {
    // Call onAdditionalDataIntegrationChange function with the required arguments
    onAdditionalDataIntegrationChange(0, datatype, value);
  };

  return (
    <Container className={classes.container}>
      <FormControl className={classes.formControl}>
        {dataLoaderSystem && props.dataLoaderOptions
          ? props.dataLoaderOptions
              .filter((item) => {
                return item.type === dataLoaderSystem.type;
              })
              .map((dataLoaderOption) => {
                return dataLoaderOption.integrations.map(
                  (integrationConfig) => {
                    return (
                      <TextField
                        key={integrationConfig.type}
                        select
                        required
                        fullWidth
                        label={integrationConfig.type.replace(
                          /([A-Z])/g,
                          " $1"
                        )}
                        variant="outlined"
                        size="small"
                        margin="dense"
                        onChange={(event) => {
                          handleIntegratedSystemChange(
                            integrationConfig.type,
                            event.target.value
                          );
                        }}
                        disabled={existing === true}
                        value={
                          dataLoaderSystem?.integrations?.[
                            integrationConfig.type
                          ] || ""
                        }
                      >
                        {integrationConfig.integrations.map((integration) => {
                          return (
                            <MenuItem key={integration} value={integration}>
                              {integration}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    );
                  }
                );
              })
          : null}
      </FormControl>
    </Container>
  );
}
