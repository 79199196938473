import React from "react";
import { PageTitle } from "../components/utils/PageTitle";
import Container from "@mui/material/Container";
import { RuleViewer } from "../components/rules/RuleViewer";
import { RuleType } from "../api/ruleengine";

export function UploadSettings() {
  return (
    <PageTitle
      pageTitle="Upload Settings | CIRROM"
      pageHeader="Upload Settings"
    >
      <Container maxWidth={false}>
        <RuleViewer ruleGroupId={1} ruleType={RuleType.FILE_UPLOAD} />
      </Container>
    </PageTitle>
  );
}
