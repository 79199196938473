import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import { authenticateViaAAD } from "../api/api";
import Typography from "@mui/material/Typography/Typography";

export function ActiveDirectory(props: Readonly<{ setLoggedIn?: any }>) {
  const [error, setError] = useState<string>("");
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    authenticateViaAAD(Object.fromEntries(urlSearchParams.entries()))
      .then((response) => {
        if (typeof response == "string") {
          window.open(window.location.origin, "_self");
        } else {
          setError(
            "Unable to Authenticate User. Your Azure AD application entitlements might have expired, please reach out to your IT team to renew the application credentials."
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box
      style={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        height: "100px",
        justifyContent: "center",
        marginTop: "50px",
      }}
    >
      <Container maxWidth="sm" style={{ textAlign: "center" }}>
        {!error && <CircularProgress />}
        {error && <Typography variant={"h4"}>{error}</Typography>}
      </Container>
    </Box>
  );
}
